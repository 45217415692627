import React from 'react';
import './CloudNetwork.css';
// import CloudNetworkCard from 'components/CloudNetworkCard/CloudNetworkCard';
import CardCountryCloud from 'components/CardCountryCloud/CardCountryCloud';
import SDWAN from '../../img/sdwan.png';
// import dirConnect from '../../img/direct-connect.png';
import serverImg from '../../img/data-center.png';

type Props = {
  CloudData: any[];
  ServerData: any[];
  statusCloud: any;
};

const CloudNetwork: React.FC<Props> = ({ CloudData, ServerData, statusCloud }) => {
  // const NetworkCards = CloudData.map((info: any) =>
  //     <CloudNetworkCard status={info.status} image={info.image} ip={info.ip} location={info.location} position={info.position}></CloudNetworkCard>
  // );

  const ServerCards = ServerData.map((server: any, index: any) => {
    // console.log(server);
    return (
      <CardCountryCloud
        title={server.location}
        label={server.ip}
        country={server.location}
        status={statusCloud[server.ip]}
        key={`${index}-card-country-cloud`}
        image={serverImg}
        position={server.position}
      />
    );
  });

  return (
    <>
      <div className="lines">
        <div className="line" style={{ width: '3px', height: '710px', top: '108px', right: '15%' }}></div>
        <div className="line" style={{ width: '15%', top: '108px', right: '0px' }}></div>
        <div className="line" style={{ width: '15%', top: '250px', right: '0px' }}></div>
        <div className="line" style={{ width: '15%', top: '390px', right: '0px' }}></div>
        <div className="line" style={{ width: '15%', top: '530px', right: '0px' }}></div>
        <div className="line" style={{ width: '15%', top: '670px', right: '0px' }}></div>
        <div className="line" style={{ width: '15%', top: '814px', right: '0px' }}></div>

        <div className="line" style={{ width: '3px', height: '710px', top: '60px', right: '25%' }}></div>
        <div className="line" style={{ width: '25%', top: '60px', right: '0px' }}></div>
        <div className="line" style={{ width: '25%', top: '200px', right: '0px' }}></div>
        <div className="line" style={{ width: '25%', top: '340px', right: '0px' }}></div>
        <div className="line" style={{ width: '25%', top: '490px', right: '0px' }}></div>
        <div className="line" style={{ width: '25%', top: '630px', right: '0px' }}></div>
        <div className="line" style={{ width: '25%', top: '770px', right: '0px' }}></div>

        <div className="line" style={{ width: '3px', height: '740px', top: '87px', left: '15%' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '87px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '140px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '287px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '335px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '382px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '554px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '726px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '774px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(15% + 15px)', top: '824px', left: '-15px' }}></div>

        <div className="line" style={{ width: '3px', height: '740px', top: '67px', left: '25%' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '67px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '120px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '267px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '315px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '362px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '534px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '706px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '754px', left: '-15px' }}></div>
        <div className="line" style={{ width: 'calc(25% + 15px)', top: '804px', left: '-15px' }}></div>

        <img className="sdwan-image" src={SDWAN} />
        <div className="line" style={{ width: '20%', top: '440px', left: '15%' }}></div>
        <div className="line" style={{ width: '10%', top: '410px', left: '25%' }}></div>
        <div className="line" style={{ width: '20%', top: '410px', right: '15%' }}></div>
        <div className="line" style={{ width: '10%', top: '440px', right: '25%' }}></div>
      </div>
      <div className="details-sdwan" id="details-sdwan">
        <div className="server-cards">{ServerCards}</div>
      </div>
    </>
  );
};

export default CloudNetwork;
