import React from 'react';
import { colorStatusDictionary } from '../../utils/color';
import { useThemeContext } from '../ThemeContext';

import './TableStatus.css';

function TableStatus({ dataTable, level, idSubTableArg }) {
  const { isDarkMode } = useThemeContext();

  function getTable(dataTable, level = 0) {
    if (dataTable && dataTable['name']) {
      const [idSubTable] = dataTable.name.split(' ');
      let items;
      if (level !== -1) {
        items = [
          <div
            className={isDarkMode ? "sensors border-b-grey" :"sensors"}
            key={`${level}-${dataTable.name}-main-table`}
            id={idSubTableArg ? idSubTableArg : ''}
          >
            <div
              className="flag"
              style={{
                backgroundColor: colorStatusDictionary?.[dataTable.status] || 'gray',
                marginLeft: `${(level + 1) * 30}px`,
              }}
            ></div>
            <div className={isDarkMode ? "selected-group-table color-b7" :"selected-group-table"}>{dataTable.name}</div>
            <div
              className="arrow"
              id={`${idSubTable}-arrow`}
              onClick={() => {
                document.querySelectorAll(`#${idSubTable}`).forEach((elemento) => {
                  if (elemento.style.display === 'none' || window.getComputedStyle(elemento).display === 'none') {
                    elemento.style.display = 'flex';
                  } else {
                    elemento.style.display = 'none';
                  }
                });
                document.querySelectorAll(`#${idSubTable}-arrow`).forEach((elm) => {
                  const contenidoHTML = elm.innerHTML;
                  const contenidoTexto = elm.textContent;
                  if (contenidoTexto.trim() === '▲') {
                    elm.innerHTML = '&#9660;';
                  } else if (contenidoTexto.trim() === '▼') {
                    elm.innerHTML = '&#9650;';
                  }
                });
              }}
            >
              &#9650;{' '}
            </div>
          </div>,
        ];
      } else {
        items = [];
      }

      if (dataTable.children?.length) {
        dataTable.children.forEach((child) => {
          items = [...items, ...getTable(child, level + 1, idSubTable)];
        });
      }

      if (dataTable.sensors?.length) {
        dataTable.sensors.forEach((sensor, sensorIndex) => {
          items.push(
            <div className={isDarkMode ? "sensors border-b-grey" :"sensors"} key={`${dataTable.name}-${level}-${sensorIndex}-sensor-table`} id={idSubTable}>
              <div
                className="flag"
                style={{
                  backgroundColor: colorStatusDictionary?.[sensor.status] || 'gray',
                  marginLeft: `${(level + 2) * 30}px`,
                }}
              ></div>
              <div className={isDarkMode ? "selected-group-table color-b7" :"selected-group-table"}>{sensor.name}</div>
            </div>
          );
        });
      }
      return items;
    } else {
      return [];
    }
  }

  return <section className={ isDarkMode ? "table-status-cmpt bg-dark" : "table-status-cmpt"}>{getTable(dataTable, level)}</section>;
}
export default TableStatus;
