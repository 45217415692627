import React from 'react';
import './RowAlert.css';
import { getStatusColorByType } from '../../utils/color';
function RowAlert({ alert }) {
    return (
        <div className='row-alert'>
            <div className='alert-data'>
                <div style={{width: "5%"}}>
                    <div className='alert-status' style={{backgroundColor: getStatusColorByType(alert.status)}}/>
                </div>
                <div className='alert-branch' style={{width: "25%"}}>{alert.branch}</div>
                <div className='alert-team' style={{width: "20%"}}>{alert.team}</div>
                <div className='alert-date' style={{width: "25%"}}>{alert.date}</div>
                <div className='alert-time' style={{width: "15%"}}>{alert.time}</div>
                <i class="fa-solid fa-eye" style={{width: "10%"}}></i>
            </div>
            <div className='alert-detail'>
                <div style={{fontSize: "8px", fontWeight: "700"}}>DETALLE</div>
                <div style={{fontSize: "12px"}}>{alert.detail}</div>
            </div>
        </div>
    );
}

export default RowAlert;