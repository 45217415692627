import AxiosFactory from './AxiosNagios'

export async function getHosts(baseURL, site) {
    let response
    const checkmkApi = AxiosFactory(baseURL)
    response = await checkmkApi.post(`scripts/nagios/cns_get_hosts`, {})
    return response.data
}

export async function getHostsServices(baseURL, hostnames) {
    let response
    const checkmkApi = AxiosFactory(baseURL)
    response = await checkmkApi.post(`scripts/nagios/cns_get_list_hosts_services`, { host_name: hostnames })
    return response.data
}
