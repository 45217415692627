import React from 'react';

//@ts-ignore
import Toolbar from 'components/Toolbar/Toolbar';
//@ts-ignore
import LocationMainCard from 'components/LocationMainCard/LocationMainCard';

//@ts-ignore
import { colorStatusDictionary } from '../../utils/color';
//@ts-ignore
import { icons } from '../../utils/dictionaryIcons';
import './Main.css';
import GeoMap from 'img/SQM Geomap Min.jpg';
import GeoMapDark from 'img/SQM - Dark mode map.jpg';
import { useThemeContext } from '../../components/ThemeContext';

function Main({ changeDashboard }: { changeDashboard: any }) {
  const { isDarkMode } = useThemeContext();
  const locations: any = [
    { type: 'Faena', name: 'Nueva Victoria', iconName: 'FaenaNV', position: { top: '111px', left: '184px' } },
    { type: 'Planta', name: 'Coya Sur', iconName: 'PlantaCDS', position: { top: '381px', left: '198px' } },
    { type: 'Planta', name: 'Pedro de Valdivia', iconName: 'PlantaPDV', position: { top: '418px', left: '174px' } },
    { type: 'Planta', name: 'Salar de Atacama', iconName: 'PlantaSDA', position: { top: '470px', left: '323px' } },
    { type: 'Planta', name: 'Salar del Carmen', iconName: 'PlantaSDC', position: { top: '523px', left: '162px' } },
  ];

  function getLocations() {
    return locations.map((location: any, index: any) => (
      <LocationMainCard
        key={`${index}-location-main-card`}
        type={location.type}
        name={location.name}
        status={location.status}
        logo={icons[location.iconName]}
        changeDashboard={changeDashboard}
      />
    ));
  }

  function getLocationsMap() {
    return locations.map((location: any, index: any) => (
      <div className="status-map" key={`${index}-lc-map`} style={location.position} />
    ));
  }

  return (
    <section className="main-container">
      <Toolbar title="Dashboard Geolocalización" changeDashboard={undefined} />
      <div className="main-content">
        <div className="map">
          {isDarkMode ? <img src={GeoMapDark} alt="GeoMapDark" /> : <img src={GeoMap} alt="GeoMap" />}
          {getLocationsMap()}

          {/* Lines */}
          {/* Lines Nueva Victoria */}
          <div className="line-map" style={{ top: '94px', right: '0px', width: '50px' }} />
          <div
            className="line-map"
            style={{ top: '109px', right: '50px', width: '46px', transform: 'rotate(-35deg)' }}
          />
          <div className="line-map" style={{ top: '122px', right: '96px', width: '246px' }} />
          {/* Lines Coya Sur */}
          <div className="line-map" style={{ top: '270px', right: '0px', width: '213px' }} />
          <div
            className="line-map"
            style={{ top: '328px', right: '195px', width: '158px', transform: 'rotate(-45deg)' }}
          />
          {/* Lines Pedro de Valdivia */}
          <div className="line-map" style={{ top: '445px', right: '0px', width: '150px' }} />
          <div
            className="line-map"
            style={{ top: '437px', right: '152px', width: '37px', transform: 'rotate(25deg)' }}
          />
          <div className="line-map" style={{ top: '429px', right: '194px', width: '157px' }} />
          {/* Lines Salar de Atacama */}
          <div className="line-map" style={{ top: '620px', right: '0px', width: '158px' }} />
          <div
            className="line-map"
            style={{ top: '591px', right: '152px', width: '74px', transform: 'rotate(45deg)' }}
          />
          <div className="line-map" style={{ top: '495px', right: '214px', height: '71px' }} />
          {/* Lines Salar del Carmen */}
          <div className="line-map" style={{ top: '795px', right: '0px', width: '209px' }} />
          <div
            className="line-map"
            style={{ top: '711px', right: '180px', width: '230px', transform: 'rotate(45deg)' }}
          />
          <div className="line-map" style={{ top: '548px', right: '375px', height: '76px' }} />
        </div>
        <div className={isDarkMode ? 'locations bg-darker' : 'locations'}>{getLocations()}</div>
      </div>
    </section>
  );
}

export default Main;
