// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-redes-container {
  width: 100%;
  height: 100%;
  font-family: 'Poppins';
  min-height: 1000px;
  min-width: 1920px;
}

.dashboard-redes-container .toolbar-wrap {
  width: 100%;
  height: 67px;
}

.dashboard-redes-container .content {
  width: 100%;
  height: calc(100% - 67px);
}

.dashboard-redes-container .content .network-dashboard {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./components/SimplePanel.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins');\n\n.dashboard-redes-container {\n  width: 100%;\n  height: 100%;\n  font-family: 'Poppins';\n  min-height: 1000px;\n  min-width: 1920px;\n}\n\n.dashboard-redes-container .toolbar-wrap {\n  width: 100%;\n  height: 67px;\n}\n\n.dashboard-redes-container .content {\n  width: 100%;\n  height: calc(100% - 67px);\n}\n\n.dashboard-redes-container .content .network-dashboard {\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
