// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container{
    width: 100%;
    height: 100%;
}

.main-container .main-content{
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
}

.main-container .main-content .map{
    width: 550px;
    height: 100%;
    position: relative;
}

.main-container .main-content .map img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-container .main-content .map .status-map{
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 30px;
    background: #c4c4c4;
    border: 2px white solid;
}

.main-container .main-content .map .line-map{
    position: absolute;
    border-top: 3px dashed white;
    border-left: 3px dashed white;
}

.main-container .main-content .locations{
    width: calc(100% - 550px);
    height: 100%;
    padding: 5px 50px;
    background-color: #f2f6fe;
}


`, "",{"version":3,"sources":["webpack://./views/Main/Main.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".main-container{\n    width: 100%;\n    height: 100%;\n}\n\n.main-container .main-content{\n    width: 100%;\n    height: calc(100% - 100px);\n    display: flex;\n}\n\n.main-container .main-content .map{\n    width: 550px;\n    height: 100%;\n    position: relative;\n}\n\n.main-container .main-content .map img{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.main-container .main-content .map .status-map{\n    position: absolute;\n    height: 25px;\n    width: 25px;\n    border-radius: 30px;\n    background: #c4c4c4;\n    border: 2px white solid;\n}\n\n.main-container .main-content .map .line-map{\n    position: absolute;\n    border-top: 3px dashed white;\n    border-left: 3px dashed white;\n}\n\n.main-container .main-content .locations{\n    width: calc(100% - 550px);\n    height: 100%;\n    padding: 5px 50px;\n    background-color: #f2f6fe;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
