import React from 'react';
import DeviceIcon from 'utils/DeviceIcon';
import { getStatusColorByNumber } from '../../utils/color';

import './CardInfo.css';

type Props = {
  type: any;
  name?: any;
  label: any;
  info: any;
  status: any;
  problems: any;
  device: any;
  deviceType: any;
  ok: any;
  total: any;
  warnServ: any;
  critServ: any;
  unknServ: any;
  okServ: any;
  showAll: any;
};

const CardInfo: React.FC<Props> = ({
  status,
  type,
  name = '',
  label = '',
  info = '',
  problems,
  device,
  deviceType = '',
  ok,
  total,
  okServ = 0,
  warnServ = 0,
  critServ = 0,
  unknServ = 0,
  showAll
}) => {
  function getCard() {
    //@ts-ignore
    const percentageUp = parseInt((ok * 100) / total, 10);
    // const whitePixels = 100 - percentageUp;
    if (type === 'title') {
      return <div className="title-card">{name}</div>;
    } else if (type === 'info') {
      return (
        <>
          <div className='wrap-title-card'>
            <div className="problems-dt">
              <DeviceIcon type={device} color={'#81848E'} />
            </div>
            <div className='device-type-card'>{deviceType}</div>
            <div className="title-card">{`${ok}/${total}`}</div>
          </div>
          <div className="services-info" style={{ flex: 1 }}>
            <div
              className="services-info-item"
              style={{
                background: getStatusColorByNumber('0'),
                borderBottomLeftRadius: '8px',
                opacity: okServ ? 1 : '0.2',
              }}
            >
              {okServ}
            </div>
            <div
              className="services-info-item"
              style={{ background: getStatusColorByNumber('1'), opacity: warnServ ? 1 : '0.2' }}
            >
              {warnServ}
            </div>
            <div
              className="services-info-item"
              style={{ background: getStatusColorByNumber('2'), opacity: critServ ? 1 : '0.2' }}
            >
              {critServ}
            </div>
            <div
              className="services-info-item"
              style={{
                background: getStatusColorByNumber('3'),
                borderBottomRightRadius: '8px',
                opacity: unknServ ? 1 : '0.2',
              }}
            >
              {unknServ}
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <section
      className="card-info-basic"
      style={{
        border: `2px solid ${getStatusColorByNumber(status)}`,
        backgroundColor: '#FFFFFF',
        color: '#81848E',
        // alignItems: type === 'title' ? 'flex-start' : 'flex-end',
        opacity: status === 0 && showAll === false ? '0.2' : '1',
      }}
    >
      {getCard()}
    </section>
  );
};

export default CardInfo;
