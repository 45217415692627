import React from 'react';
import './RowBranch.css';
import { getStatusColorByType } from '../../utils/color';
function RowBranch({ branchInfo }) {

    return (
        <div className='row-branch'>
            <div style={{width: "50%", display: "flex", alignItems: "center"}}>
                <div className='branch-status' style={{backgroundColor: getStatusColorByType(branchInfo.status), marginRight: "5px"}}/>
                {branchInfo.name}
            </div>
            <div className='branch-status-container' style={{width: "10%"}}>
                {branchInfo.amount}
            </div>
            <div className='branch-status-container' style={{width: "10%"}}>
                <div className='branch-status' style={{backgroundColor: getStatusColorByType(branchInfo.GTDstatus)}}/>
            </div>
            <div className='branch-status-container' style={{width: "10%"}}>
                <div className='branch-status' style={{backgroundColor: getStatusColorByType(branchInfo.MOVstatus)}}/>
            </div>
            <div className='branch-status-container' style={{width: "10%"}}>
                <div className='branch-status' style={{backgroundColor: getStatusColorByType(branchInfo.FORTstatus)}}/>
            </div>
            <div className='branch-status-container' style={{width: "10%"}}>
                <div className='branch-status' style={{backgroundColor: getStatusColorByType(branchInfo.TPACKstatus)}}/>
            </div>
        </div>
    );
}

export default RowBranch;