import React from 'react';
import './ProvidersInfo.css';
import { getStatusColorByType } from '../../utils/color';
import PercentageBar from '../PercentageBar/PercentageBar'
function ProvidersInfo({ down, total, slaPercentage}) {
    return (
        <div className="providers-body">
            <div className='providers-info'>
                <div>DISPOSITIVOS DOWN</div>
                <PercentageBar total={total} subTotal={down}/>
            </div>
            <div className='providers-info'>
                <div>SLA TOTAL</div>
                <div className='percentage'>{slaPercentage}</div>
            </div>
        </div>
    );
}

export default ProvidersInfo;