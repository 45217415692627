import React, { useState, useEffect } from 'react';
import WhiteContainer from '../../components/WhiteContainer/WhiteContainer';
import DeviceStatus from '../../components/DeviceStatus/DeviceStatus';
import FiberLink from '../../components/FiberLink/FiberLink';
import SimpleDropDown from '../../components/SimpleDropDown/SimpleDropDown';
import ProvidersInfo from '../../components/ProvidersInfo/ProvidersInfo';
import AlertsTable from '../../components/AlertsTable/AlertsTable';
import RowAlert from '../../components/RowAlert/RowAlert';
import RowBranch from '../../components/RowBranch/RowBranch';
import CriticalServices from '../../components/CriticalServices/CriticalServices';

import Logo from '../../img/logo.png';
import "./Main.css"

function Main({ }) {

    //Fiber link data

    const fiber_data = [
        { from: "NEXUS1", to: "NEXUS2", status: "OK" },
        { from: "NEXUS3", to: "NEXUS4", status: "OK" },
        { from: "BBDD1", to: "BBDD2", status: "OK" },
        { from: "BBDD1", to: "BBDD2", status: "OK" },
    ]
    const fiber_body = fiber_data.map((link) => {
        const body = <FiberLink status={link.status} from={link.from} to={link.to} />
        return <WhiteContainer content={body} />
    });

    //Datacenter data


    const datacenter_types = ["Switch", "Router", "Firewall", "Sdwan"];
    const datacenter_elems = datacenter_types.map(datacenter_type =>
        <DeviceStatus deviceType={datacenter_type} status={"OK"} upHost={"0/6"} cpu={"60%"} ram={"44%"} ></DeviceStatus>
    )
    const datacenter_body = (
        <div className='flex-wrap'>
            {datacenter_elems}
        </div>
    )

    //Fortinet data
    const fortinet_elems = [];
    fortinet_elems.push(<SimpleDropDown title={"Down"} status={"CRITICAL"} statusInfo={"36"} />);
    fortinet_elems.push(<SimpleDropDown title={"Up"} status={"OK"} statusInfo={"55"} />);
    const fortinet_body = <div className='flex-wrap'>{fortinet_elems}</div>

    //Proveedores data
    const proveedores_data = { down: 100, total: 350, sla_percentage: "60%" };
    const proveedores_body = <ProvidersInfo down={proveedores_data.down} total={proveedores_data.total} slaPercentage={proveedores_data.sla_percentage} />

    //Alerts data 
    const alert_headers = [
        { "width": 5, "title": "EST" },
        { "width": 25, "title": "SUCURSAL" },
        { "width": 20, "title": "EQUIPO" },
        { "width": 25, "title": "FECHA / HORA" },
        { "width": 15, "title": "TIEMPO" },
        { "width": 10, "title": "REVISAR" }
    ];

    const alerts_data = [
        { status: "CRITICAL", branch: "Clínica Bicentenario", team: "RMKWJ4782", date: "09/12/2024  14:44", time: "3:07 MIN", detail: "Router Código RMKWJ4782 desconectado..." },
        { status: "CRITICAL", branch: "Clínica Bicentenario", team: "RMKWJ4782", date: "09/12/2024  14:44", time: "3:07 MIN", detail: "Router Código RMKWJ4782 desconectado..." },
        { status: "CRITICAL", branch: "Clínica Bicentenario", team: "RMKWJ4782", date: "09/12/2024  14:44", time: "3:07 MIN", detail: "Router Código RMKWJ4782 desconectado..." },
        { status: "CRITICAL", branch: "Clínica Bicentenario", team: "RMKWJ4782", date: "09/12/2024  14:44", time: "3:07 MIN", detail: "Router Código RMKWJ4782 desconectado..." },
        { status: "CRITICAL", branch: "Clínica Bicentenario", team: "RMKWJ4782", date: "09/12/2024  14:44", time: "3:07 MIN", detail: "Router Código RMKWJ4782 desconectado..." },
    ];
    const alert_rows = alerts_data.map((alert) => <RowAlert alert={alert} />)

    //Branch data
    const branch_headers = [
        { width: 50, "title": "NOMBRE SUCURSAL"},
        { width: 10, "title": "ALERT"},
        { width: 10, "title": "GTD"},
        { width: 10, "title": "MOV"},
        { width: 10, "title": "FORT"},
        { width: 10, "title": "TPACK"},
    ]

    const branch_data = [
        { "status": "CRITICAL", "name": "Clínica Bicentenario", "amount": 6, "GTDstatus": "CRITICAL", "MOVstatus": "CRITICAL", "FORTstatus": "CRITICAL", "TPACKstatus": "CRITICAL" },
        { "status": "CRITICAL", "name": "Clínica Bupa", "amount": 4, "GTDstatus": "CRITICAL", "MOVstatus": "WARNING", "FORTstatus": "WARNING", "TPACKstatus": "WARNING" },
        { "status": "CRITICAL", "name": "Clínica Indisa", "amount": 2, "GTDstatus": "CRITICAL", "MOVstatus": "CRITICAL", "FORTstatus": "WARNING", "TPACKstatus": "WARNING" },
        { "status": "CRITICAL", "name": "Clínica Tabancura", "amount": 2, "GTDstatus": "CRITICAL", "MOVstatus": "CRITICAL", "FORTstatus": "WARNING", "TPACKstatus": "WARNING" },
        { "status": "CRITICAL", "name": "Clínica Vespucio", "amount": 1, "GTDstatus": "WARNING", "MOVstatus": "WARNING", "FORTstatus": "WARNING", "TPACKstatus": "WARNING" },
        { "status": "WARNING", "name": "Agustinas", "amount": 2, "GTDstatus": "WARNING", "MOVstatus": "WARNING", "FORTstatus": "WARNING", "TPACKstatus": "WARNING" },
        { "status": "WARNING", "name": "Antofagasta", "amount": 3, "GTDstatus": "WARNING", "MOVstatus": "WARNING", "FORTstatus": "WARNING", "TPACKstatus": "WARNING" },
        { "status": "WARNING", "name": "Arica", "amount": 2, "GTDstatus": "WARNING", "MOVstatus": "WARNING", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "WARNING", "name": "Calama", "amount": 1, "GTDstatus": "WARNING", "MOVstatus": "WARNING", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Castro", "amount": 1, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Chillán", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Concepción", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Constitución", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Copiapó", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Coyhaique", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Curicó", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Huérfanos", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Iquique", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "La Serena", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Los Andes", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Los Ángeles", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" },
        { "status": "OK", "name": "Maipú", "amount": 0, "GTDstatus": "OK", "MOVstatus": "OK", "FORTstatus": "OK", "TPACKstatus": "OK" }
    ]

    const branch_rows = branch_data.map((branch) => <RowBranch branchInfo={branch} />)
    
    //critical services data
    const critical_services_status = {router: "OK", saturation: "OK", interface:"OK", conection: "OK"};
    const critical_service_body = <CriticalServices status={critical_services_status} />

    return (
        <section className="panel-view">
            <div className='column1'>
                <img src={Logo}></img>
                <div className='title'>Fibra Oscura</div>
                {fiber_body}
            </div>
            <div className='column2'>
                <div className="datacenter">
                    <div className='title'>Datacenter</div>
                    <WhiteContainer title={"Teatinos"} subtitle={"ESTADO DATACENTER"} status={"WARNING"} statusContent={"1"} content={datacenter_body} style={{ height: "240px" }} />
                    <WhiteContainer title={"Quilicura"} subtitle={"ESTADO DATACENTER"} status={"OK"} statusContent={"0"} content={datacenter_body} style={{ height: "240px" }} />
                </div>
                <div className="fortinet">
                    <div className='title'>Fortinet</div>
                    <WhiteContainer title={"Forntinet 600F"} subtitle={"CONTEO DE VPN"} content={fortinet_body} style={{ height: "100px" }} />
                    <WhiteContainer title={"Forntinet 200F"} subtitle={"CONTEO DE VPN"} content={fortinet_body} style={{ height: "100px" }} />
                </div>
            </div>
            <div className='column3'>
                <div className='row1'>
                    <div className='column1'>
                        <div className='title'>Proveedores</div>
                        <WhiteContainer title={"Todos"} subtitle={"SUCURSALES ALERTADAS"} status={"CRITICAL"} statusContent={"4/36"} content={proveedores_body} style={{ height: "110px" }} />
                        <WhiteContainer title={"GTD"} subtitle={"SUCURSALES ALERTADAS"} status={"WARNING"} statusContent={"1/30"} content={proveedores_body} style={{ height: "110px" }} />
                        <WhiteContainer title={"Movistar"} subtitle={"SUCURSALES ALERTADAS"} status={"CRITICAL"} statusContent={"3/30"} content={proveedores_body} style={{ height: "110px" }} />
                    </div>
                    <div className='column2'>
                        <div className='title'>Servicios críticos</div>
                        <WhiteContainer title={"GTD"} subtitle={"INTERNET"} horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title={"Movistar"} subtitle={"INTERNET"}  horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title={"Wifi"} subtitle={"INTERNET"} horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title={"I-Med1"} subtitle={"INTERNET"} horizontal={true} content={critical_service_body}/>
                        <WhiteContainer title={"I-Med2"} subtitle={"INTERNET"} horizontal={true} content={critical_service_body}/>
                    </div>
                </div>
                <div className='row2'>
                    <div className='title'>Últimas alertas</div>
                    <WhiteContainer 
                        content={
                            (
                                <AlertsTable header_elements={alert_headers} height={310}>
                                    {alert_rows}
                                </AlertsTable>
                            )
                        }
                        style={{ height: "320px" }} 
                    />
                </div>

            </div>
            <div className='column4'>
                <div className='title'>Sucursales - Detalle de estado</div>
                <div className='flex-wrap'>
                    <SimpleDropDown title="Todas" subTitle={"Región"}/>
                    <SimpleDropDown title="Todos" subTitle={"Estado"}/>
                </div>
                <WhiteContainer 
                    style={{ height: "857px" }}
                    content={(
                        <AlertsTable header_elements={branch_headers} height={847}>
                            {branch_rows}
                        </AlertsTable>
                    )}
                >
                </WhiteContainer>
            </div>
            {/* <Toolbar title={`Panel Ejecutivo`} /> */}
        </section>
    )
}

export default Main;