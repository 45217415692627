// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-card-container{
    width: 140px;
    background-color: #fafcff;
    box-shadow: #d9e6fa 0px 3px 8px;
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 10px;
    cursor: pointer;
}


.simple-card-container .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

.simple-card-container .header .title{
    font-size: 12px;
    font-weight: 600;
    color: #63666e;
    width: 105px;
}


.simple-card-container .header .status{
    height: 15px;
    width: 15px;
    border-radius: 30px;
}

.simple-card-container .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 20px;

}

.simple-card-container .content-item{
    display: flex;
    justify-content: center;
    padding: 5px 5px;
}`, "",{"version":3,"sources":["webpack://./components/SimpleStatusCard/SimpleStatusCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,+BAA+B;IAC/B,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;;AAGA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":[".simple-card-container{\n    width: 140px;\n    background-color: #fafcff;\n    box-shadow: #d9e6fa 0px 3px 8px;\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n\n.simple-card-container .header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 5px 10px;\n}\n\n.simple-card-container .header .title{\n    font-size: 12px;\n    font-weight: 600;\n    color: #63666e;\n    width: 105px;\n}\n\n\n.simple-card-container .header .status{\n    height: 15px;\n    width: 15px;\n    border-radius: 30px;\n}\n\n.simple-card-container .content{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    min-height: 20px;\n\n}\n\n.simple-card-container .content-item{\n    display: flex;\n    justify-content: center;\n    padding: 5px 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
