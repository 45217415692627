// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-problems-cmpt {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eef1f7;
  border-radius: 8px;
  /* position: absolute; */
}

.tooltip-problems-cmpt .tp-item-cmpt {
  border: 1px solid #a0a1a5;
  color: #63666e;
  letter-spacing: 4%;
  font-weight: 300;
  border-radius: 8px;
  width: 100%;
  display: flex;
  margin-bottom: 4px;
}

.tooltip-problems-cmpt .tp-item-cmpt .status {
  width: 20% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 8px;
}

.tooltip-problems-cmpt .tp-item-cmpt .status .flag {
  width: 20px;
  height: 20px;
  background-color: #ed4c5c;
  border-radius: 4px;
}

.tooltip-problems-cmpt .tp-item-cmpt .content {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tooltip-problems-cmpt .tp-item-cmpt .content .device {
  color: #a0a1a5;
  width: 9px;
  font-weight: bold;
  width: 100%;
}

.tooltip-problems-cmpt .tp-item-cmpt .content .store {
  color: #a0a1a5;
  width: 9px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/TooltipProblems/TooltipProblems.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,wBAAwB;EACxB,kCAAkC;EAClC,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,UAAU;EACV,WAAW;AACb","sourcesContent":[".tooltip-problems-cmpt {\n  width: 100%;\n  padding: 8px;\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #eef1f7;\n  border-radius: 8px;\n  /* position: absolute; */\n}\n\n.tooltip-problems-cmpt .tp-item-cmpt {\n  border: 1px solid #a0a1a5;\n  color: #63666e;\n  letter-spacing: 4%;\n  font-weight: 300;\n  border-radius: 8px;\n  width: 100%;\n  display: flex;\n  margin-bottom: 4px;\n}\n\n.tooltip-problems-cmpt .tp-item-cmpt .status {\n  width: 20% !important;\n  height: 100% !important;\n  display: flex !important;\n  justify-content: center !important;\n  align-items: center !important;\n  margin-top: 8px;\n}\n\n.tooltip-problems-cmpt .tp-item-cmpt .status .flag {\n  width: 20px;\n  height: 20px;\n  background-color: #ed4c5c;\n  border-radius: 4px;\n}\n\n.tooltip-problems-cmpt .tp-item-cmpt .content {\n  width: 80%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.tooltip-problems-cmpt .tp-item-cmpt .content .device {\n  color: #a0a1a5;\n  width: 9px;\n  font-weight: bold;\n  width: 100%;\n}\n\n.tooltip-problems-cmpt .tp-item-cmpt .content .store {\n  color: #a0a1a5;\n  width: 9px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
