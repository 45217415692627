// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    border: 1px solid #d6e0f5;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 46px;
}

.custom-label{
    font-family: Sen;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.44px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #8D9CBC;
    margin-bottom: 5px;
}

.card-info .title{
    display: flex;
    font-family: Sen;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.84px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #8D9CBC;
}

.card-info:hover{
    background-color: #f4f9fc;
    border-color: #649aec;
}

.card-info .title .status{
    height: 15px;
    width: 15px;
    border-radius: 5px;
    background-color: grey;
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./components/CardInfo/CardInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;IAC9B,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":[".card-info{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 4px 6px;\n    border: 1px solid #d6e0f5;\n    border-radius: 5px;\n    margin-bottom: 10px;\n    height: 46px;\n}\n\n.custom-label{\n    font-family: Sen;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 14.44px;\n    letter-spacing: 0.04em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    color: #8D9CBC;\n    margin-bottom: 5px;\n}\n\n.card-info .title{\n    display: flex;\n    font-family: Sen;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16.84px;\n    letter-spacing: 0.04em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    color: #8D9CBC;\n}\n\n.card-info:hover{\n    background-color: #f4f9fc;\n    border-color: #649aec;\n}\n\n.card-info .title .status{\n    height: 15px;\n    width: 15px;\n    border-radius: 5px;\n    background-color: grey;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
