import React from 'react';
import './DeviceStatus.css';
import { getStatusColorByType } from '../../utils/color';
function DeviceStatus({ deviceType, status, upHost, cpu, ram}) {
    return (
        <div className="device-container">
            <div className='header'>
                <div className='header-title'>
                    <div className='status' style={{backgroundColor: getStatusColorByType(status)}}></div>
                    {deviceType}
                </div>
                <div className='header-info'>{upHost}</div>
            </div>
            <div className='content'>
                <div className='service'>
                    <div>CPU</div>
                    <div style={{color: getStatusColorByType("OK")}}>{cpu}</div>
                </div>
                <div className='service'>
                    <div>RAM</div>
                    <div style={{color: getStatusColorByType("OK")}}>{ram}</div>
                </div>
            </div>
        </div>
    );
}

export default DeviceStatus;