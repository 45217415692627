import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import ServerContainer from '../../components/ServerContainer/ServerContainer';
import DockerContainer from '../../components/DockerContainer/DockerContainer';
import DockerContainerChild from '../../components/DockerContainerChild/DockerContainerChild';
import CircleContainer from '../../components/CircleContainer/CircleContainer';
import DVUContainer from '../../components/DVUContainer/DVUContainer';
import NginxContainer from '../../components/NginxContainer/NginxContainer';
import K8s from '../../components/K8s/K8s';
import K8Pod from '../../components/K8sPod/K8sPod';
import "./Main.css"
import {main_components} from '../../utils/mainItems'
import {global_states} from '../../utils/dictionaryStates'

function Main({hostsNagios, servicesNagios}) {
    const [components, setComponents] = useState(main_components);
    function draw_components(component){
        let content = undefined;
        if(component.content){
            content = component.content.map(child =>
                draw_components(child)
            )
        }
        if(component.type === "server"){
            return <ServerContainer status={component.status} title={component.name} content={content} position={component.position} style={component.style} ip={component.ip} cpu={component.cpu} mem={component.mem} disk={component.disk}/>;
        }
        else if(component.type === "docker"){
            return <DockerContainer status={component.status} content={content} style={component.style}/>;
        }
        else if(component.type === "container"){
            return <DockerContainerChild status={component.status} title={component.title} icon={component.icon} content={content}/>
        }
        else if(component.type === "circle"){
            return <CircleContainer style={component.style} mainText={component.mainText} smallText={component.smallText}/>
        }
        else if(component.type === "dvu"){
            return <DVUContainer status={component.status} title={component.title} style={component.style} content={content}/>
        }
        else if(component.type === "nginx"){
            return <NginxContainer status={component.status} style={component.style} content={content}/>
        }else if(component.type === "k8s"){
            return <K8s status={component.status} style={component.style} content={content} />
        }else if(component.type === "k8spod"){
            return <K8Pod status={component.status} icon={component.icon} title={component.title} style={component.style} content={content} />
        }
    }

    const body = components.map((component) =>
        draw_components(component)
    )

    //function that adds cpu
    function addPercentages(dataArray) {
        return dataArray
            .filter(item => !item.includes("cpu_idle")) // Ignore 'cpu_idle'
            .map(item => item.split(";")[0])  // Step 1: Get only the first part before ';'
            .map(item => item.split("=")[1])  // Step 2: Get value after '='
            .map(value => parseFloat(value))  // Step 3: Convert to number (remove '%')
            .filter(num => !isNaN(num))       // Step 4: Remove NaN values (non-numeric cases)
            .reduce((sum, num) => sum + num, 0) // Step 5: Sum all numbers
            .toFixed(2) + "%";  // Step 6: Format result and add "%"
    }

    useEffect(() => {
        if(hostsNagios){
             const copy_component = components.map(component => {
                const host_name = component.hostName ? component.hostName : component.name;
                const host_temp = hostsNagios.find((host) => host.name === host_name );
                if(host_temp){
                    const status = parseInt(host_temp.state);
                    component.status = global_states[status];   
                    component.ip = host_temp.address;
                }
                return component;
            });
            setComponents(copy_component);
        }
        if(servicesNagios){
            components.map(component => {
                const host_name = component.hostName ? component.hostName : component.name;
                const services_temp = servicesNagios.find((host_services) => host_services.host_name === host_name );
                if(services_temp){
                    const services = JSON.parse(services_temp.services);
                    services.forEach(service => {
                        const perfData = service.perf_data;
                        if(perfData){
                            if(service.description.startsWith("Uso de CPU")){
                                const arrayPerfData = perfData.split(" ");
                                component.cpu = {data: addPercentages(arrayPerfData), state: global_states[parseInt(service.state)]}
                            }
                            if(service.description.startsWith("Uso de Memoria Fisica")){
                                const regex = /'[^']*'(?:=[^ ]+)?|[^ ]+/g;
                                const arrayPerfData = perfData.match(regex);
                                const memoryUtilizationData = arrayPerfData[2];
                                const arrayMemoryPercentage = memoryUtilizationData.split(";");
                                const memoryPercentage = arrayMemoryPercentage[0].split("=");
                                component.mem = {data: memoryPercentage[1], state: global_states[parseInt(service.state)]}

                            }
                            else if(service.description.startsWith("Disco: /")){
                                const regex = /'[^']*'|[^\s]+/g;
                                const arrayDiskPercentage = perfData.match(regex);
                                const diskPercentage = arrayDiskPercentage.at(-1).split("=")[1].split(";");
                                component.disk = {data: diskPercentage[0], state: global_states[parseInt(service.state)]}
                            }
                        }
                    });
                }
                return component;
            });
        }
    }, [hostsNagios, servicesNagios]);

    return (
        <section className="panel-view">
            {/* <ServerContainer status={"OK"} title={"Krusty"} content={content}/> */}
            {body}
        </section>
    )
}

export default Main;