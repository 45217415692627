import React from 'react';
import './CardInfo.css';
import StatusCard from '../StatusCard/StatusCard';

import  {color_status} from "../../utils/dictionaryStates"

function CardInfo({ title , status, status_card= undefined, status_card_title= "", label=undefined, style = {}}) {
  return (
    <>
        {label && <div className='custom-label'>{label}</div>}
        <div className="card-info" style={style}>
            <div className='title'>
                {status && <div className='status' style={{background: color_status[status]}}></div>}
                {title}
            </div>
            {status_card && <StatusCard title={status_card_title} status={status_card}></StatusCard>}
        </div>
    </>
  );
}

export default CardInfo;
