// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}
  
.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
}

.circle-text {
    font-size: 11px;
    font-weight: bold;
}

.circle-small-text {
    font-size: 5px;
    position: absolute;
    bottom: 10px;
}
  `, "",{"version":3,"sources":["webpack://./components/CircleContainer/CircleContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".circle-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n}\n  \n.circle {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    background-color: black;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    position: relative;\n}\n\n.circle-text {\n    font-size: 11px;\n    font-weight: bold;\n}\n\n.circle-small-text {\n    font-size: 5px;\n    position: absolute;\n    bottom: 10px;\n}\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
