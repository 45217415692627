import React from 'react'; //, { useEffect, useState } 
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import './SimplePanel.css'

// @ts-ignore
import Main from "../views/Main/Main"

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data }) => {

  return (
    <div
      className="panel-wrapper"
      style={{ width: '1800px', height: '1020px', backgroundColor: '#d8e1f5', padding: '15px' }}
    >
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"></link>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet"></link>
      <Main></Main>
    </div>
  );
};
