const robotIds = [
    18977573,
    18738450,
    18783703,
    18817211,
    18849650,
    18810233,
    18958307,
    19394974,
    19411197,
];

export {robotIds};