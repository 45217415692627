// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-view{
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px 10px;
    background: #eff5ff;
    border-radius: 20px;
    position: relative;
}

.panel-view .body{
    height: calc(100% - 76px);
    padding: 16px;
    display: flex;
}
`, "",{"version":3,"sources":["webpack://./views/Main/Main.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;AACjB","sourcesContent":[".panel-view{\n    display: flex;\n    height: 100%;\n    width: 100%;\n    padding: 20px 10px;\n    background: #eff5ff;\n    border-radius: 20px;\n    position: relative;\n}\n\n.panel-view .body{\n    height: calc(100% - 76px);\n    padding: 16px;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
