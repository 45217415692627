// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nginx-container{
    display: flex;
    background: white;
    margin: 10px 0px;
    border: solid 2px #019538;
    border-radius: 5px;
    align-items: center;
    padding: 10px;
    position: relative;
}

.nginx-container img{
    height: 40px;
}

.nginx-container .nginx-title{
    font-size: 18px;
    font-weight: 600;
    color: #019538;
}`, "",{"version":3,"sources":["webpack://./components/NginxContainer/NginxContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".nginx-container{\n    display: flex;\n    background: white;\n    margin: 10px 0px;\n    border: solid 2px #019538;\n    border-radius: 5px;\n    align-items: center;\n    padding: 10px;\n    position: relative;\n}\n\n.nginx-container img{\n    height: 40px;\n}\n\n.nginx-container .nginx-title{\n    font-size: 18px;\n    font-weight: 600;\n    color: #019538;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
