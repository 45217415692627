const dictHypervisors = {
  '10.62.14.110': 'altamar',
  'amazonas.alemana.cl': '',
  'apure01.alemana.cl': '',
  'apure02.alemana.cl': '',
  'carora01.alemana.cl': 'lascar01',
  'carora02.alemana.cl': 'lascar01',
  'carora03.alemana.cl': 'lascar01',
  'carora04.alemana.cl': 'lascar01',
  'carora05.alemana.cl': 'lascar01',
  'cedro01.alemana.cl': 'altamar',
  'cedro02.alemana.cl': 'altamar',
  'cedro03.alemana.cl': 'altamar',
  'cedro04.alemana.cl': 'altamar',
  'cedro05.alemana.cl': 'altamar',
  'cedro06.alemana.cl': 'altamar',
  'cedro07.alemana.cl': 'altamar',
  'cedro08.alemana.cl': 'altamar',
  'chilco01.alemana.cl': 'altamar',
  'chilco02.alemana.cl': 'altamar',
  'chilco03.alemana.cl': 'altamar',
  'chilco04.alemana.cl': 'altamar',
  'chilco05.alemana.cl': 'altamar',
  'merida01.alemana.cl': 'altamar',
  'merida02.alemana.cl': 'altamar',
  'merida03.alemana.cl': 'altamar',
  'merida04.alemana.cl': 'altamar',
  'merida05.alemana.cl': 'altamar',
  'merida06.alemana.cl': 'altamar',
  'merida07.alemana.cl': 'altamar',
  'merida08.alemana.cl': 'altamar',
  'nevada01.alemana.cl': 'altamar',
  'nevada02.alemana.cl': 'altamar',
  'nevada03.alemana.cl': 'altamar',
  'nevada04.alemana.cl': 'altamar',
  'noruega01.alemana.cl': 'lascar02',
  'noruega02.alemana.cl': 'lascar02',
  'noruega03.alemana.cl': 'lascar02',
  'noruega04.alemana.cl': 'lascar02',
  'noruega05.alemana.cl': 'lascar02',
  'tacora1.alemana.cl': 'lascar01',
  'tacora2.alemana.cl': 'lascar01',
  'tacora3.alemana.cl': 'lascar01',
  'tacora4.alemana.cl': 'lascar01',
  'tacora5.alemana.cl': 'lascar02',
  'tacora6.alemana.cl': 'lascar02',
  'tacora7.alemana.cl': 'lascar02',
  'tacora8.alemana.cl': 'lascar02',
  'tacora9.alemana.cl': 'lascar01',
  'tacora10.alemana.cl': 'lascar02',
};

export { dictHypervisors };
