import React from 'react';
import './DockerContainerChild.css';
import  {icons} from "../../utils/dictionaryIcons"
import  {color_status} from "../../utils/dictionaryStates"

function DockerContainerChild({status, icon, title, font_color, style = undefined, content= undefined}) {
  return (
    <>
        <div className="docker-child-container" style={style}>
            <img src={icons["Container"]} style={{marginRight: "10px"}}/>
            <img src={icons[icon]}/>
            <div className='container-title' style={{color: font_color}}>{title}</div>
            <div className='docker-child-status' style={{background: color_status[status]}}></div>
            {content}
        </div>
    </>
  );
}

export default DockerContainerChild;
