export const components = {
  'Cancha Salitre': { status: 3, position: { top: '20px', left: '50px' } },
  'Encargado Cancha NPT2': { status: 3, position: { top: '240px', left: '50px' } },
  Garita: { status: 3, position: { top: '350px', left: '50px' } },
  'Eléctricos secado': { status: 3, position: { top: '460px', left: '50px' } },
  CIO: { status: 3, position: { top: '670px', left: '50px' } },
  'Cancha Secado': { status: 3, position: { top: '20px', left: '250px' } },
  'Cerro Mirador': { status: 3, position: { top: '130px', left: '250px' } },
  'Obras sanitarias': { status: 3, position: { top: '350px', left: '250px' } },
  'T. Terrestres': { status: 3, position: { top: '460px', left: '250px' } },
  'Embarque': { status: 3, position: { top: '670px', left: '250px' } },
  'Taller DD. EE.': { status: 3, position: { top: '730px', left: '250px' } },
  'Planta Cristal': { status: 3, position: { top: '20px', left: '450px' } },
  Pozas: { status: 3, position: { top: '130px', left: '450px' } },
  'Casa de fuerza': { status: 3, position: { top: '240px', left: '450px' } },
  Convertidora: { status: 3, position: { top: '350px', left: '450px' } },
  'Bodega proyecto': { status: 3, position: { top: '460px', left: '450px' } },
  'Prilado': { status: 3, position: { top: '670px', left: '450px' } },
  'Sala de servidores': {
    icon: 'Industry',
    status: 3,
    position: { top: '130px', left: '650px', width: '160px', height: "106px" },
  },
  'Planta NPT I': { status: 3, position: { top: '350px', left: '650px' } },
  'Taller NPT 4': { status: 3, position: { top: '460px', left: '650px' } },
  'Gerencia de Proyectos I GOCS y Prilado': {
    status: 3,
    position: { top: '570px', left: '650px', width: "160px", height: "200px" },
  },
  'Romana 1': { status: 3, position: { top: '20px', left: '850px' } },
  'Laboratorio Unificado': { status: 3, position: { top: '130px', left: '850px' } },
  'Pta Secado': { status: 3, position: { top: '460px', left: '850px' } },
  'NPT3': { status: 3, position: { top: '730px', left: '850px' } },
  'Cancha Prilado': { status: 3, position: { top: '20px', left: '1050px' } },
  'Taller Unificado': { status: 3, position: { top: '130px', left: '1050px' } },
  'Planta NPT II': { status: 3, position: { top: '240px', left: '1050px' } },
  'Caldera NPT 2': { status: 3, position: { top: '350px', left: '1050px' } },
  'Contenedores de secado': { status: 3, position: { top: '460px', left: '1050px' } },
  'Romana Automática': { status: 3, position: { top: '570px', left: '1050px' } },
  'Planta Piloto': { status: 3, position: { top: '670px', left: '1050px' } },
  'Planta Muriato | Pts | Vapor | Cancha KNO3': { status: 3, position: { top: '130px', left: '1250px' } },
  'Planta NPT 4': { status: 3, position: { top: '350px', left: '1250px' } },
  'Encarpadora Ruta 5': { status: 3, position: { top: '460px', left: '1250px' } },
  'Garita Ruta 5': { status: 3, position: { top: '570px', left: '1250px' } },
  'Romana 2 automática': { status: 3, position: { top: '670px', left: '1250px' } },
};
