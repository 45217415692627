// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.docker-container{
    background-color: #cceaf9;
    border: dashed 2px #2396ec;
    border-radius: 10px;
    padding: 5px;
}

.docker-container .docker-header{
    display: flex;
    flex-direction: column;
    color: #fac710;
    font-size: 20px;
}


.docker-container .docker-header img{
    width: 40px;
    height: 40px;
}

.docker-container .docker-header .docker-status{

}
`, "",{"version":3,"sources":["webpack://./components/DockerContainer/DockerContainer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;AAEA","sourcesContent":[".docker-container{\n    background-color: #cceaf9;\n    border: dashed 2px #2396ec;\n    border-radius: 10px;\n    padding: 5px;\n}\n\n.docker-container .docker-header{\n    display: flex;\n    flex-direction: column;\n    color: #fac710;\n    font-size: 20px;\n}\n\n\n.docker-container .docker-header img{\n    width: 40px;\n    height: 40px;\n}\n\n.docker-container .docker-header .docker-status{\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
