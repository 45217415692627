// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.critical-services{
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-family: Sansation;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.31px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #A0A1A5;
    width: 200px;
}

.critical-services .critical-service{
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    width: 90px;
}

.critical-services .critical-service .crit-status{
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./components/CriticalServices/CriticalServices.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;IAC9B,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".critical-services{\n    width: 100%;\n    display: flex;\n    justify-content: space-around;\n    font-family: Sansation;\n    font-size: 11px;\n    font-weight: 400;\n    line-height: 12.31px;\n    letter-spacing: 0.04em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    color: #A0A1A5;\n    width: 200px;\n}\n\n.critical-services .critical-service{\n    display: flex;\n    margin-bottom: 5px;\n    align-items: center;\n    width: 90px;\n}\n\n.critical-services .critical-service .crit-status{\n    margin-right: 10px;\n    width: 15px;\n    height: 15px;\n    border-radius: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
