import React from 'react';
import { getStatusColorByNumber } from '../../utils/color';

import './CardInfoRedes.css';
import CustomTooltip from 'components/Tooltip/Tooltip';

type Props = {
  status: any;
  name: any;
  label: any;
  bodyLabel:any
  info: any;
  totals: any;
  problems: any;
};

const CardInfoRedes: React.FC<Props> = ({ status, name = '', label = '', bodyLabel = '', info = '', totals, problems }) => {
  function getCard() {
    

    const percentage = (totals.ack_total / totals.pr_total) * 100;
    const problems_toolip_info =  {["La florida"]:   [{name: "JUMBO JCEN15479", status: 1,wanStatus: [0,1,0,0]},]}
    const content = (

      <div className='tooltip-content'>
        {
          Object.entries(problems_toolip_info).map(([location, data] : any) => 
            <>
              <div className='tooltip-label'>{location}</div>
              {
                data.map((store : any) => 
                <div className='store-problem-wrapper'>
                  <div className='store-info'>
                    <div className='status' style={{backgroundColor: getStatusColorByNumber(store.status)}}></div>
                    <div className='store-name'>{store.name}</div>
                  </div>
                  <div className='wan-container'>
                    <div className='wann-wrapper'>
                      <div className='status' style={{backgroundColor: getStatusColorByNumber(store.wanStatus[0])}}></div> Wann 1
                    </div>
                    <div className='wann-wrapper'>
                      <div className='status' style={{backgroundColor: getStatusColorByNumber(store.wanStatus[1])}}></div> Wann 2
                    </div>
                    <div className='wann-wrapper'>
                      <div className='status' style={{backgroundColor: getStatusColorByNumber(store.wanStatus[2])}}></div> Wann 3
                    </div>
                    <div className='wann-wrapper'>
                      <div className='status' style={{backgroundColor : getStatusColorByNumber(store.wanStatus[3])}}></div> Wann 4
                    </div>
                  </div>
                  
                </div>
                
                )
              }
            </>
          )
        };
      </div>
    )
    return (
      <>
        <div className="header">
          <div className='label-header-wrap'>
            <div className="name">{name}</div>
            <div className="header-label">{label}</div>
          </div>
          <CustomTooltip content={content} show={false} direction={"right"} style={{position: "inherit"}} backgroundColor={"white"}>
            <div className='services' style={{backgroundColor: getStatusColorByNumber(status)}}>{totals.pr_total} / {totals.sv_total}</div>
          </CustomTooltip>
          {/* <div className="mount">{problems}</div> */}
        </div>
        <div className="body">
          <svg className="graphic" style={{ width: '90px', height: '11px' }}>
            <rect className="graphic-white" height= "100%" width= "100%"></rect>
            <rect className="graphic-gray" height= "100%"  width={ percentage + "%"}></rect>
            <text x="50%" y="70%" text-anchor="middle">{totals.ack_total} / {totals.pr_total - totals.ack_total}</text>
          </svg>
          <div className="text-body-supplier">
            <div className="label-body">{bodyLabel}</div>
          </div>
        </div>
        {/* <div className="title-card">{info}</div>
        <div className="info-card">{label}</div> */}
      </>
    );
    
  }
  return (
    <section
      className="card-info-redes-basic"
      style={{
        //backgroundColor: getStatusColorByNumber(status),
        alignItems: 'flex-end',
      }}
    >
      {getCard()}
    </section>
  );
};

export default CardInfoRedes;
