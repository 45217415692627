import React from 'react';
import './SimpleDropDown.css';
import { getStatusColorByType } from '../../utils/color';
function SimpleDropDown({ title, subTitle, status, statusInfo }) {
  return (
    <div className="simple-drop-down">
        <div>
          {subTitle && <div className='subTitle-d-down'>{subTitle}</div>}
          <div className='title-d-down'>{title}</div>
        </div>
        <div className='items-d-down'>
            {status && <div className='status' style={{background: getStatusColorByType(status)}}>{statusInfo}</div>}
            <i class="fas fa-angle-down" style={{fontSize: "15px", color: "#A0A1A5"}}></i>
        </div>
    </div>
  );
}

export default SimpleDropDown;