import React from 'react';
import './Toolbar.css';
// import Logo from '../../img/sqm-logo.svg';
import { icons } from '../../utils/dictionaryIcons';
import { useThemeContext } from '../ThemeContext';

function Toolbar({ title, location, changeDashboard }) {
  const { isDarkMode, toggleTheme } = useThemeContext();
  return (
    <div className="toolbar-container">
      <div className={isDarkMode ? "white-bar bg-dark": "white-bar" } style={{position: "relative"}}>
        <label className={`toggle-switch ${isDarkMode ? "off" : "on"}`}>
          <input type="checkbox" checked={isDarkMode} onChange={toggleTheme} />
          <span className="slider">
            <span className="icon">{isDarkMode ? "🌙": "🔆"}</span>
          </span>
        </label>
      </div>
      <div className={isDarkMode ? "blue-bar bg-dark-blue" : "blue-bar"}>
        <img className='logo' src={icons['logo']} />
        {location && <div className="title">{location}: </div>}
        <div className="title-bold">{title}</div>
        {changeDashboard &&
          (
            <div className='back' onClick={changeDashboard}>
              <i class="fa-solid fa-reply"></i> Volver
            </div>
          )
        }
      </div>
      <div className={isDarkMode? "white-bar bg-dark":"white-bar"}></div>
    </div>
  );
}

export default Toolbar;
