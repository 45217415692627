// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.providers-body{
    display: flex;
    flex-direction: column;
    font-family: Sansation;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.43px;
    letter-spacing: 0.04em;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #A0A1A5;
    padding-top: 15px;
    border-top: 1px solid #C9D6F2;
    margin-top: 10px;
}

.providers-body .providers-info{
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
}

.providers-body .providers-info .percentage{
    font-weight: 700;
    color: #51D59E;
}`, "",{"version":3,"sources":["webpack://./components/ProvidersInfo/ProvidersInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,iBAAiB;IACjB,kCAAkC;IAClC,8BAA8B;IAC9B,cAAc;IACd,iBAAiB;IACjB,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".providers-body{\n    display: flex;\n    flex-direction: column;\n    font-family: Sansation;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 13.43px;\n    letter-spacing: 0.04em;\n    text-align: right;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    color: #A0A1A5;\n    padding-top: 15px;\n    border-top: 1px solid #C9D6F2;\n    margin-top: 10px;\n}\n\n.providers-body .providers-info{\n    display: flex;\n    justify-content: space-between;\n    margin: 3px 0px;\n}\n\n.providers-body .providers-info .percentage{\n    font-weight: 700;\n    color: #51D59E;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
