import React from 'react';
import './SimpleStatusCard.css';
// import { colorStatusDictionary } from '../../utils/color';
import { useThemeContext } from '../ThemeContext';

function SimpleStatusCard({ name, colorStatus, position, body, onSelect, status }) {
  // console.log(status);
  const { isDarkMode } = useThemeContext();
  const isUnknown = status === 3 ? true : false;
  return (
    <div
      className={isDarkMode ? "simple-card-container bg-dark-grey box-shadow-dark" : "simple-card-container"}
      style={{ ...position, opacity: isUnknown ? '0.6' : '1', cursor: isUnknown ? 'not-allowed' : 'pointer' }}
      onClick={isUnknown ? () => {} : onSelect}
    >
      <div className="header">
        <div className={isDarkMode ? "title color-b7" :"title"}>{name}</div>
        <div className="status" style={{ backgroundColor: colorStatus }}></div>
      </div>
      <div className="content">{body}</div>
    </div>
  );
}

export default SimpleStatusCard;
