// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-branch{
    display: flex;
    padding: 10px 10px;
    border: solid 1px #C9D6F2;
    border-radius: 10px;
    margin: 5px 0px;
    color: #A0A1A5;
    font-family: Sansation;
    font-size: 14px;
    font-weight: 700;
    line-height: 15.67px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.row-branch .branch-status-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-branch  .branch-status{
    width: 15px;
    height: 15px;
    border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./components/RowBranch/RowBranch.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".row-branch{\n    display: flex;\n    padding: 10px 10px;\n    border: solid 1px #C9D6F2;\n    border-radius: 10px;\n    margin: 5px 0px;\n    color: #A0A1A5;\n    font-family: Sansation;\n    font-size: 14px;\n    font-weight: 700;\n    line-height: 15.67px;\n    letter-spacing: 0.04em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n}\n\n.row-branch .branch-status-container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.row-branch  .branch-status{\n    width: 15px;\n    height: 15px;\n    border-radius: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
