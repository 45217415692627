import React from 'react';

import DatacenterTableItem from '../DatacenterTableItem/DatacenterTableItem';

import './DatacenterTable.css';

function DatacenterTable({ customNameColum, items }) {
  function getItems() {
    return items.map((item, index) => (
      <DatacenterTableItem
        key={`${index}-item-dc`}
        name={item.name}
        status={item.status}
        ip={item.ip}
        saturation={item.saturation}
        crc={item.crc}
        prov={item.prov}
      />
    ));
  }
  return (
    <section className="datacenter-table-cmpt">
      <div className="header">
        <div className="name">DC</div>
        <div className="prov">PROV</div>
        <div className="status">{customNameColum}</div>
        <div className="ip">IP HOST</div>
        <div className="saturation">SAT</div>
        <div className="crc">CRC</div>
      </div>
      <div className="table">{items && items.length ? getItems() : null}</div>
    </section>
  );
}

export default DatacenterTable;
