import React from 'react';

import { buildUrlWithHostTags } from '../../utils/common.js';

import './TooltipProblems.css';

function TooltipProblems({ items = [], siteUrl }) {
  function getItems() {
    return items.map((item, index) => {
      return (
        <div
          className="tp-item-cmpt"
          key={`${index}-tp-item-cmpt`}
          onClick={(event) => {
            const resultUrl = buildUrlWithHostTags(siteUrl, item.name, item.codeStore);
            window.open(resultUrl);
            event.stopPropagation();
          }}
        >
          <div className="status">
            <div className="flag"></div>
          </div>
          <div className="content">
            <div className="device">{item.name}</div>
            <div className="store">{`Codigo tienda: ${item.codeStore}`}</div>
          </div>
        </div>
      );
    });
  }
  return <section className="tooltip-problems-cmpt">{getItems()}</section>;
}

export default TooltipProblems;


// http://200.1.131.103:7605/NREDES_REGIONAL/check_mk/index.py?start_url=%2FNREDES_REGIONAL%2Fcheck_mk%2Fview.py%3Fhost%3DCLSGOPA502FW186%26service%3DBGP%3A172.26.236.1_INFO%3AModem-4G%26site%3DNREDES_REGIONAL%26view_name%3Dservice

// http://200.1.131.103:7605/NREDES_REGIONAL/check_mk/index.py?start_url=%2FNREDES_REGIONAL%2Fcheck_mk%2Fview.py%3Fhost%3DCLSGOPA502FW186%26service%3DBGP%253A172.26.236.1_INFO%253AModem-4G%26site%3DNREDES_CL%26view_name%3Dservice