import React from 'react';
import './CriticalServices.css';
import { getStatusColorByType } from '../../utils/color';
function CriticalServices({ status }) {
  return (
    <div>
        <div className='critical-services'>
            <div className='critical-service'>
                <div className='crit-status' style={{background: getStatusColorByType(status.router)}}></div>
                Router
            </div>
            <div className='critical-service'>
                <div className='crit-status' style={{background: getStatusColorByType(status.saturation)}}></div>
                Saturación
            </div>
        </div>
        <div className='critical-services'>
            <div className='critical-service'>
                <div className='crit-status' style={{background: getStatusColorByType(status.interface)}}></div>
                Interfaz
            </div>
            <div className='critical-service'>
                <div className='crit-status' style={{background: getStatusColorByType(status.conection)}}></div>
                Conexión
            </div>
        </div>
    </div>
  );
}

export default CriticalServices;