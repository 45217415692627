// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .fiber-link{} */

.fiber-link .link{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}

.fiber-link .link img{
    height: 32px;
    width: 29px !important;
    margin-bottom: 0px !important;
}

.fiber-link .link .dashed-link{
    width: 80px;
    border-top: dashed 2px #AAB8D6;
}

.fiber-link .link .status{
    height: 20px;
    width: 20px;
}

.fiber-link .info-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

.fiber-link .info-container .info{
    display: flex;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 16.55px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #A0A1A5;
}

.fiber-link .info-container .info .status{
    width: 12px;
    height: 12px;
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./components/FiberLink/FiberLink.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":["/* .fiber-link{} */\n\n.fiber-link .link{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 10px 0px;\n}\n\n.fiber-link .link img{\n    height: 32px;\n    width: 29px !important;\n    margin-bottom: 0px !important;\n}\n\n.fiber-link .link .dashed-link{\n    width: 80px;\n    border-top: dashed 2px #AAB8D6;\n}\n\n.fiber-link .link .status{\n    height: 20px;\n    width: 20px;\n}\n\n.fiber-link .info-container{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0px 10px;\n}\n\n.fiber-link .info-container .info{\n    display: flex;\n    font-family: Poppins;\n    font-size: 11px;\n    font-weight: 500;\n    line-height: 16.55px;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    color: #A0A1A5;\n}\n\n.fiber-link .info-container .info .status{\n    width: 12px;\n    height: 12px;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
