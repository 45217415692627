// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableactivealerts {
  position: relative;
}

.tableactivealerts .clear-filter {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: #428bc1;
  color: #ffffff;
  border-radius: 16px;
  cursor: pointer;
  top: -36px;
  right: 0;
  height: 32px;
  width: 136px;
}

.p-selectable-cell,
.p-selectable-cell span,
.p-selectable-cell a {
  user-select: auto !important;
}`, "",{"version":3,"sources":["webpack://./components/TableActiveAlerts2/TableActiveAlerts2.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,UAAU;EACV,QAAQ;EACR,YAAY;EACZ,YAAY;AACd;;AAEA;;;EAGE,4BAA4B;AAC9B","sourcesContent":[".tableactivealerts {\n  position: relative;\n}\n\n.tableactivealerts .clear-filter {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 20px;\n  background-color: #428bc1;\n  color: #ffffff;\n  border-radius: 16px;\n  cursor: pointer;\n  top: -36px;\n  right: 0;\n  height: 32px;\n  width: 136px;\n}\n\n.p-selectable-cell,\n.p-selectable-cell span,\n.p-selectable-cell a {\n  user-select: auto !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
