// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.percentage-graphic {
    height: 100%;
    display: flex;
}
  
.percentage-graphic text{
    font-size: 9px;
    fill: white;
}
  
.percentage-graphic .graphic-label{
    width: 35px;
}
.percentage-graphic .graphic-percent{
    margin-left: 5px;
}
  
.percentage-graphic .graphic-gray {
    fill: #80BC00;
}
  
.percentage-graphic .graphic-white {
    fill: #D7DBDD;
}`, "",{"version":3,"sources":["webpack://./components/PercentageBar/PercentageBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".percentage-graphic {\n    height: 100%;\n    display: flex;\n}\n  \n.percentage-graphic text{\n    font-size: 9px;\n    fill: white;\n}\n  \n.percentage-graphic .graphic-label{\n    width: 35px;\n}\n.percentage-graphic .graphic-percent{\n    margin-left: 5px;\n}\n  \n.percentage-graphic .graphic-gray {\n    fill: #80BC00;\n}\n  \n.percentage-graphic .graphic-white {\n    fill: #D7DBDD;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
