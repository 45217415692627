import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { getStatusColorByNumber } from 'utils/color';
import { buildUrlHostService } from '../../utils/common.js';

import ack from '../../img/icon_ack.png';

import './TableAlertEnlace.css';

function TableAlertEnlace({ title, arrayAlerts = [], arrayFilters = [], onClose, siteUrl }) {
  const [filters, setFilters] = useState({});
  const [alerts, setAlerts] = useState(arrayAlerts);
  const [allAlerts, setAllAlerts] = useState(arrayAlerts);

  useEffect(() => {
    setAlerts(arrayAlerts);
    setAllAlerts(arrayAlerts);
  }, [arrayAlerts]);

  function onFilter(value, newFilter) {
    const newAlerts = [];
    const copyAlerts = JSON.parse(JSON.stringify(allAlerts));
    const copyFilter = JSON.parse(JSON.stringify(filters));
    copyFilter[newFilter] = value.label;
    copyAlerts.forEach((alert) => {
      let isAlertInFilter = true;
      for (const key in copyFilter) {
        const element = copyFilter[key];
        if (alert[key] !== element) {
          isAlertInFilter = false;
        }
      }
      if (isAlertInFilter) {
        newAlerts.push(alert);
      }
    });
    setFilters(copyFilter);
    setAlerts(newAlerts);
  }

  function orderAlerts(sortDirection, field) {
    const copyAlerts = [...alerts];
    copyAlerts.sort((a, b) => {
      let valA = Number(a[field]);
      let valB = Number(b[field]);
      if (!isNaN(valA) && !isNaN(valB)) {
        return sortDirection === 'up' ? valA - valB : valB - valA; // Orden ascendente o descendente
      }
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return sortDirection === 'up' ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]);
      }
      return typeof a[field] === 'string' ? (sortDirection === 'up' ? 1 : -1) : sortDirection === 'up' ? -1 : 1;
    });
    setAlerts(copyAlerts);
  }

  return (
    <section className="table-alert-enlace-cmpt">
      <div className="table-header">
        <div className="table-title">{title}</div>
        <div className="table-filters">
          <div className="filters">
            {arrayFilters.length
              ? arrayFilters.map((filter, index) => (
                  <div className="filter-item" key={`alert-enlace-item-${index}`}>
                    <Select
                      onChange={(value) => onFilter(value, filter.filter)}
                      options={filter.options}
                      placeholder={filter.placeholder}
                    />
                  </div>
                ))
              : null}
          </div>
          <div className="icon-close" onClick={onClose}></div>
        </div>
      </div>
      <div className="table-body">
        <div className="table-body-header">
          <div className="status">
            <div>ESTADO</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'status')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'status')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="hour">
            <div>FECHA / HORA</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'lastChange')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'lastChange')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="brand">BRAND</div>
          <div className="code">
            <div>CÓDIGO</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'code')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'code')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="location">
            <div>COMUNA</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'location')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'location')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="hostname">HOSTNAME</div>
          <div className="ip">IP</div>
          <div className="service">
            <div>SERVICIO</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'service')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'service')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="acknowledged">REVISIÓN</div>
          <div className="url">MONITOREO</div>
        </div>
        <div className="table-body-items">
          {alerts.map((alert, index) => (
            <div className="item" key={`body-item-${index}`}>
              <div className="status">
                <div className="icon-status" style={{ backgroundColor: getStatusColorByNumber(alert.status) }} />
              </div>
              <div className="hour">{alert.lastChange}</div>
              <div className="brand">{alert.brand}</div>
              <div className="code">{alert.code}</div>
              <div className="location">{alert.location}</div>
              <div className="hostname">{alert.hostname}</div>
              <div className="ip">{alert.ip}</div>
              <div className="service">{alert.service}</div>
              <div className="acknowledged">{alert.acknowledged ? <img src={ack} /> : null}</div>
              <div className="url">
                <div
                  className="action-button"
                  onClick={() => window.open(buildUrlHostService(alert.hostname, alert.service, siteUrl, alert.site))}
                >
                  <div className="triangle"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TableAlertEnlace;
