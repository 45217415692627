import React from 'react';

import CardInfo from 'components/CardInfo/CardInfo';
import { getFlags, getStatusColorByNumber } from '../../utils/color';
//@ts-ignore
import { buildUrlDatacenterDevice, buildUrlDatacenterEnlace } from '../../utils/common.js';
import './DatacenterWrap.css';
// import Argentina from 'components/GeographicMap/Argentina';

type Props = {
  name: any;
  siteUrl: any;
  totalAlerts: any;
  arrayDatacenter: any;
  showAll: any;
};

const DatacenterWrap: React.FC<Props> = ({ name = 'Chile', siteUrl, totalAlerts = 34, arrayDatacenter, showAll }) => {
  // console.log(arrayDatacenter);

  

  function redirectMonitoring(datacenterName: any, deviceType: any) {
    const url = buildUrlDatacenterDevice(datacenterName, deviceType, siteUrl);
    window.open(url);
  } // name -> pais dt.name -> datacenter name

  function redirectMonitoringEnlace(datacenterName: any) {
    const url = buildUrlDatacenterEnlace(datacenterName, siteUrl);
    window.open(url);
  }

  function getDatacenters() {
    // <div className="datacenter-info">
    //   <div className="datacenter-row">
    //     <div className="item-dtinfo">SWITCH</div>
    //     <div className="item-dtinfo">ROUTER</div>
    //     <div className="item-dtinfo">FIREWALL</div>
    //     <div className="item-dtinfo">WIRELESS</div>
    //     <div className="item-dtinfo">ENLACES</div>
    //   </div>
    return arrayDatacenter.map((dt: any, index: number) => {
      return (
        <div className="datacenter-info" key={`dt-each-${index}`}>
          <div className="wrap-flag-dt">
            <div
              className="flag-dt"
              style={{
                background: getStatusColorByNumber(dt.status),
                width: '20px',
                height: '20px',
                borderRadius: '4px',
              }}
            ></div>
            <div className="title-dt">
              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{`${dt.name}`}</span>
              {` | ${dt.alertsDt} Problemas activos`}
            </div>
          </div>
          {/* <div className="datacenter-row">
          <div className="item-dtinfo">SWITCH</div>
          <div className="item-dtinfo">ROUTER</div>
          <div className="item-dtinfo">FIREWALL</div>
          <div className="item-dtinfo">WIRELESS</div>
          <div className="item-dtinfo">COM. UNIFICADAS</div>
          <div className="item-dtinfo">ENLACES</div>
        </div> */}
          <div className="datacenter-row">
            <div className="item-cardinfo" onClick={() => redirectMonitoring(dt.name, 'SWITCH')}>
              <CardInfo
                type={'info'}
                problems={dt.switch.problemServices}
                status={dt.switch.status}
                label={dt.switch.label}
                info={`${dt.switch.ok} / ${dt.switch.total}`}
                ok={dt.switch.ok}
                warnServ={dt.switch.warningServices}
                okServ={dt.switch.okServices}
                critServ={dt.switch.criticalServices}
                unknServ={dt.switch.unknownServices}
                total={dt.switch.total}
                device={'switch'}
                deviceType={'Switch'}
                showAll={showAll}
              />
            </div>
            <div className="item-cardinfo" onClick={() => redirectMonitoring(dt.name, 'ROUTER')}>
              <CardInfo
                type={'info'}
                problems={dt.router.problemServices}
                status={dt.router.status}
                label={dt.router.label}
                info={`${dt.router.ok} / ${dt.router.total}`}
                ok={dt.router.ok}
                warnServ={dt.router.warningServices}
                okServ={dt.router.okServices}
                critServ={dt.router.criticalServices}
                unknServ={dt.router.unknownServices}
                total={dt.router.total}
                device={'router'}
                deviceType={'Router'}
                showAll={showAll}
              />
            </div>
            <div className="item-cardinfo" onClick={() => redirectMonitoring(dt.name, 'FIREWALL')}>
              <CardInfo
                type={'info'}
                problems={dt.firewall.problemServices}
                status={dt.firewall.status}
                label={dt.firewall.label}
                info={`${dt.firewall.ok} / ${dt.firewall.total}`}
                ok={dt.firewall.ok}
                warnServ={dt.firewall.warningServices}
                okServ={dt.firewall.okServices}
                critServ={dt.firewall.criticalServices}
                unknServ={dt.firewall.unknownServices}
                total={dt.firewall.total}
                device={'firewall'}
                deviceType={'Firewall'}
                showAll={showAll}
              />
            </div>
            <div className="item-cardinfo" onClick={() => redirectMonitoring(dt.name, 'WIRELESS')}>
              <CardInfo
                type={'info'}
                problems={dt.wireless.problemServices}
                status={dt.wireless.status}
                label={dt.wireless.label}
                info={`${dt.wireless.ok} / ${dt.wireless.total}`}
                ok={dt.wireless.ok}
                warnServ={dt.wireless.warningServices}
                okServ={dt.wireless.okServices}
                critServ={dt.wireless.criticalServices}
                unknServ={dt.wireless.unknownServices}
                total={dt.wireless.total}
                device={'wireless'}
                deviceType={'Wireless'}
                showAll={showAll}
              />
            </div>
            <div className="item-cardinfo" onClick={() => redirectMonitoring(dt.name, 'TELEFONIA')}>
              <CardInfo
                type={'info'}
                problems={dt.telefonia.problemServices}
                status={dt.telefonia.status}
                label={dt.telefonia.label}
                info={`${dt.telefonia.ok} / ${dt.telefonia.total}`}
                ok={dt.telefonia.ok}
                warnServ={dt.telefonia.warningServices}
                okServ={dt.telefonia.okServices}
                critServ={dt.telefonia.criticalServices}
                unknServ={dt.telefonia.unknownServices}
                total={dt.telefonia.total}
                device={'wireless'}
                deviceType={'Telefonía'}
                showAll={showAll}
              />
            </div>
            <div className="item-cardinfo" onClick={() => redirectMonitoringEnlace(dt.name)}>
              <CardInfo
                type={'info'}
                problems={dt.enlaces.problemServices}
                status={dt.enlaces.status}
                label={dt.enlaces.label}
                info={`${dt.enlaces.ok} / ${dt.enlaces.total}`}
                ok={dt.enlaces.ok}
                warnServ={dt.enlaces.warningServices}
                okServ={dt.enlaces.okServices}
                critServ={dt.enlaces.criticalServices}
                unknServ={dt.enlaces.unknownServices}
                total={dt.enlaces.total}
                device={'enlaces'}
                deviceType={'Enlaces'}
                showAll={showAll}
              />
            </div>
            <div className="item-cardinfo" onClick={() => redirectMonitoring(dt.name, 'OTHERS')}>
              <CardInfo
                type={'info'}
                problems={dt.others.problemServices}
                status={dt.others.status}
                label={dt.others.label}
                info={`${dt.others.ok} / ${dt.others.total}`}
                ok={dt.others.ok}
                warnServ={dt.others.warningServices}
                okServ={dt.others.okServices}
                critServ={dt.others.criticalServices}
                unknServ={dt.others.unknownServices}
                total={dt.others.total}
                device={'Switch'}
                deviceType={'others'}
                showAll={showAll}
              />
            </div>
          </div>
        </div>
      );
    });
    // </div>
  }
  return (
    <section className="datacenter-wrap">
      <div className="title-wrap">
        <div className="title">
          <img src={getFlags(name)} />
        </div>
        {/* <div className="title-alerts">{`${totalAlerts} ALERTAS ACTIVAS TOTALES`}</div> */}
      </div>
      <div className="wrap-datacenters">{getDatacenters()}</div>
    </section>
  );
};

export default DatacenterWrap;
