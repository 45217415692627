// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-view{
    height: 100%;
    padding: 20px 10px;
    background: #eff5ff;
    border-radius: 20px;
}

.panel-view .body{
    height: calc(100% - 76px);
    padding: 16px;
    display: flex;
}

.panel-view .body .col1{
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2px 5px;
    align-items: center;
}

.panel-view .body .col2{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2px 5px;
    align-items: center;
}

.panel-view .body .col3{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2px 5px;
    align-items: center;
}

.panel-view .body .col1 .row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
}

.panel-view .body .col2 .row{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: inherit;
}`, "",{"version":3,"sources":["webpack://./views/PanelEjecutivo/PanelEjecutivo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,eAAe;AACnB","sourcesContent":[".panel-view{\n    height: 100%;\n    padding: 20px 10px;\n    background: #eff5ff;\n    border-radius: 20px;\n}\n\n.panel-view .body{\n    height: calc(100% - 76px);\n    padding: 16px;\n    display: flex;\n}\n\n.panel-view .body .col1{\n    width: 55%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 2px 5px;\n    align-items: center;\n}\n\n.panel-view .body .col2{\n    width: 20%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 2px 5px;\n    align-items: center;\n}\n\n.panel-view .body .col3{\n    width: 25%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 2px 5px;\n    align-items: center;\n}\n\n.panel-view .body .col1 .row{\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 15px;\n    width: 100%;\n}\n\n.panel-view .body .col2 .row{\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    height: inherit;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
