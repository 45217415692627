import React, { useEffect, useState } from 'react';
import Toolbar from '../Toolbar/Toolbar';
import SimpleStatusCard from '../SimpleStatusCard/SimpleStatusCard';
import TableStatus from '../TableStatus/TableStatus';
import { colorStatusDictionary } from '../../utils/color';
import './Network.css';
import { useThemeContext } from '../ThemeContext';

import { components } from '../../utils/info_network';
// import { prtgStatus } from '../../utils/dict_prtg';

function Network({ networkData, changeDashboard }) {
  const [infoComponents, setInfoComponents] = useState(components);
  const [dataTable, setDataTable] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(0);
  const { isDarkMode } = useThemeContext();

  function selectGroup(name, status) {
    setSelectedGroup(name);
    setSelectedStatus(status);
  }

  function getNetworkItems() {
    const networkItems = [];
    Object.entries(infoComponents).forEach(([groupName, groupInfo]) => {
      if (groupInfo.children) {
        const body = groupInfo.children.map((child, index) => (
          <div className="content-item" key={`${index}-ntwk-item`}>
            <div
              className="child-link"
              style={{
                borderColor: child.link_color,
                borderBottomStyle: child.link_style,
                borderLeftStyle: child.link_style,
              }}
            ></div>
            <SimpleStatusCard
              name={child.name}
              status={child.status}
              colorStatus={colorStatusDictionary[child.status]}
              onSelect={(event) => {
                event.stopPropagation();
                selectGroup(child.name, child.status);
              }}
              position={{ position: 'static', width: '120px' }}
            />
          </div>
        ));
        networkItems.push(
          <SimpleStatusCard
            key={`${groupName}-simple-status-card`}
            name={groupName}
            status={groupInfo.status}
            colorStatus={colorStatusDictionary[groupInfo.status]}
            position={{ ...groupInfo.position, width: '160px' }}
            body={body}
            onSelect={() => selectGroup(groupName, groupInfo.status)}
          />
        );
      } else {
        networkItems.push(
          <SimpleStatusCard
            key={`${groupName}-simple-status-card`}
            name={groupName}
            status={groupInfo.status}
            colorStatus={colorStatusDictionary[groupInfo.status]}
            position={groupInfo.position}
            onSelect={() => selectGroup(groupName, groupInfo.status)}
          />
        );
      }
    });
    return networkItems;
  }

  useEffect(() => {
    const copyInfoComponents = { ...networkData };
    setDataTable(copyInfoComponents[selectedGroup]);
  }, [selectedGroup]);

  useEffect(() => {
    if (networkData) {
      for (const deviceName in networkData) {
        const element = networkData[deviceName];
        const copyInfoComponents = { ...infoComponents };
        copyInfoComponents[deviceName]['status'] = element['status'];
        setInfoComponents(copyInfoComponents);
      }
    }
  }, [networkData]);
  // console.log(selectedGroup);
  // console.log(dataTable);

  return (
    <section className="network-container">
      <Toolbar title={`Dashboard Redes`} location={'Coya Sur'} changeDashboard={()=>changeDashboard("main")}/>
      <div className="network-content">
        <div className={isDarkMode ? "main-content bg-dark" : "main-content"}>
          {getNetworkItems()}
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '165px', left: '810px', width: '40px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '165px', left: '990px', width: '59px' }}
          ></div>
           <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '262px', left: '296px', width: '152px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '178px', left: '297px', height: '85px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '50px', left: '828px', height: '100px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '146px', left: '810px', width: '18px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '50px', left: '832px', width: '18px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '195px', left: '310px', width: '340px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '205px', left: '515px', width: '135px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '215px', left: '615px', width: '35px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '178px', left: '310px', height: '17px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '209px', left: '515px', height: '30px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '219px', left: '615px', height: '160px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '375px', left: '591px', width: '24px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '288px', left: '515px', height: '30px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '314px', left: '316px', width: '199px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '318px', left: '316px', height: '32px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '398px', left: '316px', height: '62px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '160px', left: '590px', width: '60px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '150px', left: '620px', width: '30px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '40px', left: '590px', width: '30px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '44px', left: '616px', height: '110px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '216px', left: '810px', width: '190px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '216px', left: '1000px', height: '50px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '262px', left: '1004px', width: '45px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '480px', left: '590px', width: '60px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '508px', left: '725px', height: '62px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '45px', left: '990px', width: '60px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '685px', left: '392px', width: '57px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '700px', left: '420px', width: '30px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '750px', left: '392px', width: '30px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '685px', left: '590px', width: '60px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '700px', left: '590px', width: '60px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '640px', left: '130px', width: '519px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '640px', left: '130px', height: '30px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '700px', left: '418px', height: '50px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '224px', left: '810px', width: '20px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '485px', left: '830px', width: '20px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '224px', left: '830px', height: '261px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '610px', left: '1190px', width: '30px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '700px', left: '1220px', width: '30px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '610px', left: '1220px', height: '90px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '613px', left: '236px', width: '413px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '486px', left: '236px', height: '129px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '486px', left: '191px', width: '45px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '300px', left: '230px', width: '272px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '288px', left: '500px', height: '16px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '303px', left: '230px', height: '77px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '376px', left: '191px', width: '39px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '69px', left: '310px', height: '60px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '155px', left: '125px', width: '124px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '158px', left: '125px', height: '80px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '70px', left: '125px', height: '32px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '98px', left: '128px', width: '165px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '98px', left: '291px', height: '31px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '236px', left: '711px', height: '112px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '288px', left: '1120px', height: '60px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '590px', left: '1190px', width: '60px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '475px', left: '990px', width: '60px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '527px', left: '1322px', height: '42px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '288px', left: '1140px', height: '45px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '329px', left: '1144px', width: '62px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '333px', left: '1202px', height: '45px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '374px', left: '1206px', width: '44px' }}
          ></div>
          <div
            className="solid-vertical yellow-solid"
            style={{ position: 'absolute', top: '718px', left: '1124px', height: '28px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '746px', left: '810px', width: '41px' }}
          ></div>
          <div
            className="solid-horizontal yellow-solid"
            style={{ position: 'absolute', top: '746px', left: '989px', width: '139px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '650px', left: '810px', width: '318px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '636px', left: '1124px', height: '14px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '201px', left: '810px', width: '420px' }}
          ></div>
          <div
            className="solid-vertical blue-solid"
            style={{ position: 'absolute', top: '173px', left: '1226px', height: '28px' }}
          ></div>
          <div
            className="solid-horizontal blue-solid"
            style={{ position: 'absolute', top: '173px', left: '1230px', width: '19px' }}
          ></div>
        </div>
        <div className={isDarkMode ? "side-bar bg-dark-grey" :"side-bar"}>
          {selectedGroup ? (
            <>
              <div className="sensors">
                <div className="flag" style={{ backgroundColor: colorStatusDictionary[selectedStatus] }}></div>
                <div className={isDarkMode ?"selected-group color-b7":"selected-group"}>{selectedGroup}</div>
              </div>
              <div className="table-group-info">
                <TableStatus dataTable={dataTable} level={-1} />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default Network;
