import React, { useEffect, useState, useRef } from 'react';
import ChileMapSvg from './Chile';
//@ts-ignore
import ServiceProblemItem from '../ServiceProblemItem/ServiceProblemItem.js';
import Argentina from './Argentina';
import Brasil from './Brasil';
import Peru from './Peru';
import Colombia from './Colombia';
import './GeographicMap.css';

type Props = {
  chooseRegion: any;
  regiones: any;
  info: any;
  regionNumber: any;
  region: any;
  scale: any;
  country: any;
  onSelectSectionStoreStatus: any;
  storeProblems: any;
};

const GeographicMap: React.FC<Props> = ({
  chooseRegion,
  regiones,
  info,
  regionNumber,
  region,
  scale,
  country,
  onSelectSectionStoreStatus,
  storeProblems = [],
}) => {
  const [alerts, setAlerts] = useState<any>([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  let ref: any = useRef();
  function handleOnHoverRegion(region: string) {
    console.log('entra handle', region);
    // onSelectSectionStoreStatus(region);
    chooseRegion(region);
    setSelectedRegion(region);
  }

  function handleOnChooseRegion(region: string) {
    // console.log('entra handle', region);
    onSelectSectionStoreStatus(region);
    // chooseRegion(region);
    // setSelectedRegion(region);
  }

  function showPaintRegions() {
    const arrayAlerts: any[] = [];
    regiones.map((regItem: any, index: number) => {
      const status = regItem.status;
      let labelStatus = 'ok';

      let colorButton = '#10D884';
      if (status === 0) {
        colorButton = '#10D884';
      } else if (status === 1) {
        colorButton = '#EFCB0D';
        labelStatus = 'warning';
      } else if (status === 2) {
        colorButton = '#ED4C5C';
        labelStatus = 'critical';
      } else if (status === 3) {
        colorButton = '#FF8B1F';
      }

      // const delta = index % 2 === 0 ? -20 : 20;
      // onSelectSectionStoreStatus(regItem.region);
      // let elementImageContainer = document.getElementById('image-container');
      // const elementImageContainerPosition = elementImageContainer?.getBoundingClientRect();
      // const widthImageContainer = elementImageContainerPosition?.width || 0;
      // const topImageContainer = elementImageContainerPosition?.top || 0;

      let element: any = document.getElementById(regItem.region);
      // const positionImage = element?.getBoundingClientRect();
      // const top = positionImage?.top || 0;
      // const left = positionImage?.left || 0;
      // const width = positionImage?.width || 0;
      if (regItem.storeProblems) {

        let countFallStores = 0;
        let countFallEnlaces = 0;

        for (const name in regItem.serviceArrayProblems) {
          const arrayProblems = regItem.serviceArrayProblems[name];
          const [st, stCode] = name.split('-');
          let storeIsFall = storeProblems.some((store: any) => store.name === st && store.code === stCode);
          if (storeIsFall) {
            countFallStores += 1;
          }
          countFallEnlaces += arrayProblems.length;
        }
        arrayAlerts.push(
          <div
            className="alerts-geomap-item"
            style={{
              visibility: selectedRegion === regItem.region ? 'visible' : 'hidden',
              position: 'absolute',
              // top: `${(top - topImageContainer + 20) / 1.75}px`,
              top: '300px',
              // left: `${(left - widthImageContainer + 40) / 1.75 + width / 2 + 40 + delta + 220}px`,
              left: '196px',
              width: `100px`,
              borderRadius: '8px',
              background: `#ffffff`,
              border: '1px solid #ffffff',
              display: 'flex',
              flexDirection: 'column',
              fontSize: `10px`,
              padding: '3px',
              zIndex: 3000,
              maxHeight: '120px',
              overflowY: 'auto',
            }}
          >
            <ServiceProblemItem
              region={regItem.region}
              allStores={100}
              fallStores={countFallStores}
              allEnlaces={100}
              fallEnlaces={countFallEnlaces}
            />
          </div>
        );
      }

      element.style.fill = colorButton;
      element.style.stroke = 'white';
      element.addEventListener('mouseover', () => {
        element.style.strokeWidth = '4px';
        element.style.stroke = 'white';
        element.style.cursor = 'pointer';
      });
      element.addEventListener('mouseout', () => {
        element.style.fill = colorButton;
        element.style.stroke = 'white';
        element.style.strokeWidth = '1px';
      });
      // });
    });
    setAlerts(arrayAlerts);

    const movableElement: any = document.getElementById('image-container');

    let initialX: any, initialY: any;
    let isDragging = false;

    function handleMouseDown(event: any) {
      isDragging = true;
      initialX = event.clientX - movableElement.offsetLeft;
      initialY = event.clientY - movableElement.offsetTop;
      document.addEventListener('mousemove', handleMouseMove);
    }

    function handleMouseMove(event: any) {
      if (isDragging) {
        const newX = event.clientX - initialX;
        const newY = event.clientY - initialY;
        movableElement.style.left = newX + 'px';
        movableElement.style.top = newY + 'px';
      }
    }

    function handleMouseUp() {
      isDragging = false;
      document.removeEventListener('mousemove', handleMouseMove);
    }

    movableElement.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      movableElement.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      showPaintRegions();
      // showAlerts();
    });
    observer.observe(ref.current);
    return () => ref.current && observer.unobserve(ref.current);
  }, [ref, regiones, selectedRegion]);

  return (
    <section className="region-map-section" ref={ref}>
      <div className="image-container" id="image-container" style={{ transform: `scale(${scale})`, top: '235px' }}>
        {/* <div className="image-container" id="image-container" style={{ transform: `scale(${scale})` }}> */}
        {country === 'chile' ? <ChileMapSvg choose={handleOnChooseRegion} regiones={info} onHover={handleOnHoverRegion}/> : null}
        {country === 'argentina' ? <Argentina /> : null}
        {country === 'colombia' ? <Colombia /> : null}
        {country === 'brasil' ? <Brasil /> : null}
        {country === 'peru' ? <Peru /> : null}
        {country === 'chile' ? alerts : null}
      </div>
    </section>
  );
};

export default GeographicMap;
