// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dvu-container{
    display: flex;
    background: white;
    margin: 10px 0px;
    border: solid 2px #6cb23e;
    border-radius: 5px;
    align-items: center;
    padding: 5px;
    position: relative;
}

.dvu-container img{
    height: 40px;
}

.dvu-container .dvu-title{
    font-size: 14px;
    color: #6cb23e;
}`, "",{"version":3,"sources":["webpack://./components/DVUContainer/DVUContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".dvu-container{\n    display: flex;\n    background: white;\n    margin: 10px 0px;\n    border: solid 2px #6cb23e;\n    border-radius: 5px;\n    align-items: center;\n    padding: 5px;\n    position: relative;\n}\n\n.dvu-container img{\n    height: 40px;\n}\n\n.dvu-container .dvu-title{\n    font-size: 14px;\n    color: #6cb23e;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
