import axios from 'axios';

const url = 'https://dvirtualuser.dparadig.com/api';
const token = 'nMgq8dKBJ9Qqrp8TLHF6qfWEkagML6kqgKmbTkKCFyVRyDDV=='

const fetchRobot = async (robotId) => {
  const endpoint = "getRobotState";
  try {
    const response = await axios.get(`${url}/${endpoint}`, {
      params: {
        robotId: robotId,
        apiToken: token
      }
    } 
  );
    return response.data;
  } catch (error) {
    console.error('Hubo un error al hacer la solicitud GET:', error.message);
    //throw error;
  }
};

export default { fetchRobot };
