import React, { useEffect, useState, useRef } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';

import {
  getHostsServicesMap,
  getHostDetail,
  getHostDetailDatacenter,
  getGraphicInfo,
  getHostsServicesMapCloud,
  //@ts-ignore
} from '../api/datasource.js';

import { getHostsAndStores } from '../api/store';

import NOCPanel from 'views/NOCPanel/NOCPanel';
import NetworkPanel from 'views/NetworkPanel/NetworkPanel';
import DatacenterPanel from 'views/DatacenterPanel/DatacenterPanel';
import Toolbar from 'components/Toolbar/Toolbar';
import logo from '../img/logo_cencosud.png';

import {
  getHosts,
  getServices,
  getServicesCloud,
  getLinks,
  getServicesCpuMemory,
  getServicesLinks,
  //@ts-ignore
} from '../rest/checkmk.js';

import './SimplePanel.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: any;
  height: any;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const containerRef = useRef(null);
  const [services, setServices] = useState<any[]>([]);
  const [hostsServicesCloud, setHostsServicesCloud] = useState({});
  const [hostsServicesCPUMem, setHostsServicesCPUMem] = useState<any[]>([]);

  const [hosts, setHosts] = useState<any[]>([]);
  const [hostsServices, setHostsServices] = useState<any[]>([]);
  const [hostsDatacenter, setHostsDatecenter] = useState<any[]>([]);
  const [hostsTagCloud, setHostsTagCloud] = useState<any[]>([]);
  const [cloudUsage, setCloudUsage] = useState<any[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState(options['dashboardType']);
  const [infoDatacenter, setInfoDatacenter] = useState<any[]>([]);
  const [arrayToDraw, setArrayToDraw] = useState<any[]>([]);
  const [arrayToDrawList, setArrayToDrawList] = useState<any[]>([]);
  const [enlacesProblems, setEnlacesProblems] = useState<any[]>([]);
  const [allProblems, setAllProblems] = useState<any[]>([]);
  const [servicesCloud, setServicesCloud] = useState<any[]>([]);
  const [siteUrl, setSiteUrl] = useState('');
  const [alertSLAduration, setAlertSLAduration] = useState('600');

  const [responseServiceLinks, setResponseServiceLinks] = useState<any[]>([]);

  async function getInfoHosts(baseUrl: any, arraySites: any) {
    let responseHosts: any[] = [];
    let allHosts: any[] = [];
    for (let index = 0; index < arraySites.length; index++) {
      const site = arraySites[index];
      const hosts = await getHosts(baseUrl, site);
      responseHosts = responseHosts.concat(hosts);
    }
    responseHosts.forEach((rpHt: any) => {
      allHosts.push(rpHt.extensions);
    });
    const store_type_map: any = {};
    options['store_type_map'].split('\n').forEach((line: string) => {
      let [value, key] = line.split(',');
      store_type_map[key] = value;
    });
    allHosts.forEach((host: any) => {
      if (store_type_map[host.tags['CODIGO_DE_TIENDA']]) {
        host.tags['TIENDA'] = store_type_map[host.tags['CODIGO_DE_TIENDA']];
      }
    });
    return allHosts;
  }

  async function getData(funcToGetData: any, baseUrl: any, arraySites: any) {
    let responseData: any[] = [];
    let allData: any[] = [];
    for (let index = 0; index < arraySites.length; index++) {
      const site = arraySites[index];
      const hosts = await funcToGetData(baseUrl, site);
      responseData = responseData.concat(hosts);
    }
    responseData.forEach((rpHt: any) => {
      allData.push(rpHt.extensions);
    });
    return allData;
  }

  async function getLinksInfo(funcToGetData: any, baseUrl: any, arraySites: any) {
    let responseData: any[] = [];
    for (let index = 0; index < arraySites.length; index++) {
      const site = arraySites[index];
      const hosts = await funcToGetData(baseUrl, site);
      responseData = responseData.concat(hosts);
    }
    return responseData;
  }

  async function processAllData() {
    let hosts: any[] = [];
    const arraySites = options.sites.split(',');
    hosts = await getInfoHosts(options.baseUrl, arraySites);
    
    let services: any[] = [];
    let links: any[] = [];
    let services_links: any[] = [];
    let services_cloud: any[] = [];
    let services_cpu_memory: any[] = [];

    services = await getData(getServices, options.baseUrl, arraySites);
    links = await getLinksInfo(getLinks, options.baseUrl, arraySites);
    services_cloud = await getData(getServicesCloud, options.baseUrl, arraySites);
    services_cpu_memory = await getData(getServicesCpuMemory, options.baseUrl, arraySites);
    services_links = await getData(getServicesLinks, options.baseUrl, arraySites);
    setResponseServiceLinks(services_links);

    const arrayInterfaces: any = [];
    const arrayNeighbors: any = [];
    if (options && options.enlaces) {
      const arrayEnlaces = options.enlaces.split('\n');
      arrayEnlaces.forEach((enl: any) => {
        const [interf, neighbor] = enl.split(',');
        arrayInterfaces.push(interf);
        arrayNeighbors.push(neighbor);
      });
    }
    const graphicEnlacesInfo: any = {};
    if (options && options.graphic_enlaces) {
      const arrayEnlaces = options.graphic_enlaces.split('\n');
      arrayEnlaces.forEach((enl: any) => {
        const [type, speed, host, to, interf, neighbor, label] = enl.split(',');
        if (!graphicEnlacesInfo[host]) {
          graphicEnlacesInfo[host] = { type, conections: [] };
        }
        graphicEnlacesInfo[host]['conections'].push({ speed, interf, neighbor, to, label });
      });
    }
    if (options && options.site_url) {
      const arraySiteUrlTemp = options.site_url.split('\n');
      let site_url: any = {};
      arraySiteUrlTemp.forEach((url: any) => {
        let arrayUrl = url.split(' ');
        site_url[arrayUrl[0]] = arrayUrl[1];
      });

      setSiteUrl(site_url);
    }

    if (options && options.alertSLAduration) {
      setAlertSLAduration(options.alertSLAduration);
    }
    const hostServices = getHostsServicesMap(services);

    const services_links_filtered: any[] = [];

    services_links.forEach((sl: any) => {
      if (
        sl.description.toLowerCase().includes('enlace') ||
        sl.description.toLowerCase().includes('ospf') ||
        sl.description.toLowerCase().includes('bgp')
      ) {
        services_links_filtered.push(sl);
      }
    });

    const hostServicesCloud = getHostsServicesMapCloud(services_cloud, hosts);
    const { arrayToGraphic, arrayToList } = getGraphicInfo(hosts, links, graphicEnlacesInfo);

    setArrayToDraw(arrayToGraphic);
    setArrayToDrawList(arrayToList);
    const { arrayInfoDatacentersByCountry, problemsWithEnlaces, allProblemsServ } = getHostDetailDatacenter(
      hosts,
      hostServices,
      services_links_filtered,
      links,
      arrayInterfaces,
      arrayNeighbors
    );
    const allProblemsServWithStores = getHostsAndStores(hosts, allProblemsServ);
    let hostTagCloudTemp = hosts.filter((host) => host.tags.CODIGO_DE_TIENDA === 'CLOUD');
    const hostsTagCloud = getHostsServicesMapCloud(services, hostTagCloudTemp);
    const cloudUsage = getHostsServicesMapCloud(services_cpu_memory, hostTagCloudTemp);
    const hostsWithDetails = getHostDetail(hosts, hostServices);
    const hostsWithDetailsCloud = getHostDetail(hosts, hostServicesCloud);
    setInfoDatacenter(arrayInfoDatacentersByCountry);
    setEnlacesProblems(problemsWithEnlaces);
    setAllProblems(allProblemsServWithStores);
    setServices(services);
    setHosts(hostsWithDetails);
    setHostsDatecenter(hostsWithDetailsCloud);
    setHostsTagCloud(hostsTagCloud);
    setCloudUsage(cloudUsage);
    setHostsServicesCloud(hostServicesCloud);
    setHostsServicesCPUMem(services_cpu_memory);
    setServicesCloud(hostServicesCloud);
    setHostsServices(hostServices);
  }

  useEffect(() => {
    processAllData();
  }, [data]);

  useEffect(() => {
    function adjustScale() {
      const screenWidth = width;
      const screenHeight = height;
      const baseWidth = 1920;
      const baseHeight = 1000;
      const scaleX = screenWidth / baseWidth;
      const scaleY = screenHeight / baseHeight;
      const maxScale = 1.5;
      const scale = Math.min(scaleX, scaleY, maxScale);
      if (containerRef.current) {
        containerRef.current.style.transform = `scale(${scale})`;
        containerRef.current.style.transformOrigin = 'top left';
      }
    }
    adjustScale();
    window.addEventListener('resize', adjustScale);
    return () => window.removeEventListener('resize', adjustScale);
  }, [width, height]);

  const dashboardPanels: any = {
    datacenter: {
      title: 'DASHBOARD OPERACIONES - DATACENTER VISTA GENERAL',
      panel: (
        <DatacenterPanel
          hosts={hostsDatacenter}
          services={services}
          infoDatacenter={infoDatacenter}
          arraytoDraw={arrayToDraw}
          arrayToDrawList={arrayToDrawList}
          enlacesProblems={enlacesProblems}
          allProblems={allProblems}
          servicesCloud={servicesCloud}
          siteUrl={siteUrl}
          alertSLAduration={alertSLAduration}
          hostsTagCloud={hostsTagCloud}
          cloudUsage={cloudUsage}
        />
      ),
    },
    network: {
      title: 'DASHBOARD OPERACIONES - REDES PAISES VISTA GENERAL',
      panel: (
        <NetworkPanel
          hosts={hosts}
          servicesCpuMem={hostsServicesCPUMem}
          siteUrl={siteUrl}
          responseServiceLinks={responseServiceLinks}
          hostServices={hostsServices}
        />
      ),
    },
    noc: {
      title: 'DASHBOARD OPERACIONES - NOC VISTA GENERAL',
      panel: (
        <NOCPanel
          hosts={hosts}
          hostsServices={hostsServicesCloud}
          infoDatacenter={infoDatacenter}
          onChange={setSelectedDashboard}
        />
      ),
    },
  };
  function getPanelToolbarButons() {
    const toolbarBotons = [
      {
        key: 'datacenter',
        label: 'Ir a datacenters',
        click: () => setSelectedDashboard('datacenter'),
      },
      {
        key: 'network',
        label: 'Ir a Redes Paises',
        click: () => setSelectedDashboard('network'),
      },
      {
        key: 'noc',
        label: 'Ir a NOC',
        click: () => setSelectedDashboard('noc'),
      },
    ];
    return toolbarBotons.filter((button) => button.key !== selectedDashboard);
  }
  return (
    <div
      className="dashboard-redes-container"
      ref={containerRef}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <div className="toolbar-wrap">
        <Toolbar title={dashboardPanels[selectedDashboard]['title']} actions={getPanelToolbarButons()}>
          <img src={logo} />
        </Toolbar>
      </div>
      <div className="content">
        <div className="network-dashboard">{dashboardPanels[selectedDashboard]['panel']}</div>
      </div>
    </div>
  );
};
