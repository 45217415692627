import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import CardVmware from '../../components/CardVmware/CardVmware';
import CardToco from '../../components/CardVmware/CardToco';
import CardWrapper from '../../components/CardWrapper/CardWrapper';
import InfoHost from '../../components/InfoHost/InfoHost';
import './VDI.css';

function VDI({ selectMainDashboard, infoDashboard }) {

    const [vcenterObject, setVcenterObject] = useState([]);
    const [tocoObject, setTocoObject] = useState([]);
    const [tocoUrls, setTocoUrls] = useState([]);

    useEffect(() => {
        if (infoDashboard['vcenter']) {
            setVcenterObject(infoDashboard['vcenter']);
        }
        if(infoDashboard["toco"]){
            setTocoObject(infoDashboard["toco"])
        }
        if(infoDashboard["tocourls"]){
            setTocoUrls(infoDashboard["tocourls"])
        }
        //console.log(infoDashboard);
    }, [infoDashboard]);

    function getVcenter() {
        const vcenterRest = [];

        const arrayDtEmo = [];
        const arrayVitacura = [];
        const arrayLaDehesa = [];
        const arrayVx = [];
        let size = undefined;

        for (const vcenterHostname in vcenterObject["lascar01"]){
            const vcenterHostInfo = vcenterObject["lascar01"][vcenterHostname];
            if (vcenterHostname === 'status') {
            } else {
                let temp = <CardVmware
                    host={{ name: vcenterHostname, status: vcenterHostInfo.status }}
                    uptime={{ status: vcenterHostInfo['Uptime'].status }}
                    overall_status={{ status: vcenterHostInfo['Overall_status'].status }}
                    cpu={{ status: vcenterHostInfo['CPU'].status }}
                    memory={{ status: vcenterHostInfo['Memory'].status }}
                    size = {size}
                />
                if(vcenterHostname.includes("tacora")){
                    arrayVitacura.push(temp);
                }else{
                    arrayDtEmo.push(temp);
                }

            }
        }

        

        for (const vcenterHostname in vcenterObject["lascar02"]){
            const vcenterHostInfo = vcenterObject["lascar02"][vcenterHostname];
            if (vcenterHostname === 'status') {
            } else {
                let temp = <CardVmware
                    host={{ name: vcenterHostname, status: vcenterHostInfo.status }}
                    uptime={{ status: vcenterHostInfo['Uptime'].status }}
                    overall_status={{ status: vcenterHostInfo['Overall_status'].status }}
                    cpu={{ status: vcenterHostInfo['CPU'].status }}
                    memory={{ status: vcenterHostInfo['Memory'].status }}
                    size = {size}
                />
                if(vcenterHostname.includes("tacora")){
                    arrayLaDehesa.push(temp);
                }else{
                    arrayVx.push(temp);
                }

            }
        }

        let hostnamesToco = [
            {hostname: "TOCO10", arr: "vitacura", url: "toco10.alemana.cl"},
            {hostname: "TOCO13", arr: "vitacura", url: "toco13.alemana.cl"},
            {hostname: "TOCO08", arr: "la dehesa", url: "toco08.alemana.cl"},
            {hostname: "TOCO09", arr: "la dehesa", url: "toco09.alemana.cl"},
        ];
        hostnamesToco.forEach((host) =>{
            let hostname = host.hostname;
            if(tocoObject.hasOwnProperty(hostname)){
                let tocoInfo = tocoObject[hostname];
                let temp = <CardToco 
                    host={{name: host.url, status: tocoInfo.status}}
                    cpu= {{status: tocoInfo.CPU.status}}
                    memory= {{status: tocoInfo.Memory.status}}
                    disk_c= {{status: tocoInfo["Disk C"].status}}
                    disk_e= {tocoInfo["Disk E"] ? {status: tocoInfo["Disk E"].status} : undefined}
                />
                if(host.arr ===  "vitacura"){
                    arrayVitacura.push(temp)
                }
                else if(host.arr === "la dehesa"){
                    arrayLaDehesa.push(temp)
                }
            }

        })

        vcenterRest.push(
            <div className="vcenter">
                <div className="vcenter-title">
                    <div style={{ fontWeight: 'bold' }}>lascar01</div>
                </div>
                <div className="vcenter-items lascar-items">
                    <div style={{ fontWeight: 'bold' }}>VDI-DT-EMO</div>
                    {arrayDtEmo}  
                </div>
                <div className="vcenter-items lascar-items">
                    <div style={{ fontWeight: 'bold' }}>VDI-Vitacura</div>
                    {arrayVitacura}                          
                </div>
            </div>
        );

        vcenterRest.push(
            <div className="vcenter">
                <div className="vcenter-title">
                    <div style={{ fontWeight: 'bold' }}>lascar02</div>
                </div>
                <div className="vcenter-items lascar-items">
                    <div style={{ fontWeight: 'bold' }}>VDI La Dehesa</div>
                    {arrayLaDehesa}  
                </div>
                <div className="vcenter-items lascar-items">
                    <div style={{ fontWeight: 'bold' }}>VxRail VDI</div>
                    {arrayVx}                          
                </div>
            </div>
        );
        

        return vcenterRest;
    }

    function getUrls(){
        const urlitems = tocoUrls.map((item) =>
            <CardWrapper 
                status={item.status}
                mainContent={<InfoHost iconName={'web'} type={item.label} hostname={item.name} ip={item.url} />}
                items={[]}
                style={{
                    width: '400px', 
                    height: "150px",
                }}
            />
        );
        return urlitems
    }

    return (
        <section className="bdi-view">
            <Toolbar title={`VDI`} />
            <div className='vmware'>
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Vmware</div>
                <div className='wrapper-lascar'>
                    {getVcenter()}
                    <div className='urls'>
                        {getUrls()}
                    </div>
                </div>
            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default VDI;