// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bdi-view {
    color: #596c88;
    height: 100%;
  }

.bdi-view .back-icon{
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.bdi-view .back-icon img{
    width: 40px;
}

.bdi-view .vmware {
    height: calc(100% - 76px);;
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bdi-view .vmware .wrapper-lascar {
    display: flex;
    justify-content: space-evenly;
}

.bdi-view .vmware .vcenter {
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 10px;
	border: 2px dashed gray;
	background-color: #ffffff;
	margin-top: 5px;
	width: max-content;
    height: max-content;
}

.bdi-view .vmware .vcenter .vcenter-title {
	width: 20px;
	height: initial;
	display: flex;
	align-items: center;
}

.bdi-view .vmware .vcenter .vcenter-items {
	/* height: 100%; */
}

`, "",{"version":3,"sources":["webpack://./views/VDI/VDI.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;EACd;;AAEF;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,4BAA4B;IAC5B,mBAAmB;IACnB,iDAAiD;AACrD;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;CACZ,mBAAmB;CACnB,uBAAuB;CACvB,yBAAyB;CACzB,eAAe;CACf,kBAAkB;IACf,mBAAmB;AACvB;;AAEA;CACC,WAAW;CACX,eAAe;CACf,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".bdi-view {\n    color: #596c88;\n    height: 100%;\n  }\n\n.bdi-view .back-icon{\n    position: absolute;\n    right: 50px;\n    top: 50px;\n    display: flex;\n    flex-direction: column;\n    cursor: pointer;\n}\n\n.bdi-view .back-icon img{\n    width: 40px;\n}\n\n.bdi-view .vmware {\n    height: calc(100% - 76px);;\n    width: 100%;\n    padding: 10px 10px 10px 10px;\n    border-radius: 10px;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n\n.bdi-view .vmware .wrapper-lascar {\n    display: flex;\n    justify-content: space-evenly;\n}\n\n.bdi-view .vmware .vcenter {\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 5px;\n\tborder-radius: 10px;\n\tborder: 2px dashed gray;\n\tbackground-color: #ffffff;\n\tmargin-top: 5px;\n\twidth: max-content;\n    height: max-content;\n}\n\n.bdi-view .vmware .vcenter .vcenter-title {\n\twidth: 20px;\n\theight: initial;\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.bdi-view .vmware .vcenter .vcenter-items {\n\t/* height: 100%; */\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
