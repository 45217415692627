// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-drop-down{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    border: #C9D6F2 1px solid;
    border-radius: 10px;
    font-family: Sansation;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: 0.04em;
    font-weight: 700;
    margin: 10px 0px;
    padding: 5px;
    background-color: white;
}

.simple-drop-down .title-d-down{
    font-size: 14px;
    text-align: left;
    line-height: 15.67px;
    color: #A0A1A5;
}

.simple-drop-down .subTitle-d-down{
    font-size: 8px;
    text-align: left;
    line-height: 15.67px;
    color: #A0A1A5;
}

.simple-drop-down .items-d-down{
    display: flex;
    align-items: end;
}

.simple-drop-down .items-d-down .status{
    width: 40px;
    height: 22px;
    border-radius: 3px;
    font-family: Sansation;
    font-size: 16px;
    line-height: 17.91px;
    text-align: center;
    color: white;
    padding: 2px;
    margin-right: 2px;
}
`, "",{"version":3,"sources":["webpack://./components/SimpleDropDown/SimpleDropDown.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,yBAAyB;IACzB,mBAAmB;IACnB,sBAAsB;IACtB,kCAAkC;IAClC,8BAA8B;IAC9B,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,oBAAoB;IACpB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".simple-drop-down{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 45%;\n    border: #C9D6F2 1px solid;\n    border-radius: 10px;\n    font-family: Sansation;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    letter-spacing: 0.04em;\n    font-weight: 700;\n    margin: 10px 0px;\n    padding: 5px;\n    background-color: white;\n}\n\n.simple-drop-down .title-d-down{\n    font-size: 14px;\n    text-align: left;\n    line-height: 15.67px;\n    color: #A0A1A5;\n}\n\n.simple-drop-down .subTitle-d-down{\n    font-size: 8px;\n    text-align: left;\n    line-height: 15.67px;\n    color: #A0A1A5;\n}\n\n.simple-drop-down .items-d-down{\n    display: flex;\n    align-items: end;\n}\n\n.simple-drop-down .items-d-down .status{\n    width: 40px;\n    height: 22px;\n    border-radius: 3px;\n    font-family: Sansation;\n    font-size: 16px;\n    line-height: 17.91px;\n    text-align: center;\n    color: white;\n    padding: 2px;\n    margin-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
