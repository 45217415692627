import React from 'react';
import './K8s.css';
import  {icons} from "../../utils/dictionaryIcons"
import  {color_status} from "../../utils/dictionaryStates"

function K8s({status, style = undefined, content}) {
  return (
    <>
        <div className="hexagon-container" style={style}>
            <div className='hexagon-border'/>
            <div className='hexagon' >{content}</div>
            <img className='img-hex' src={icons["Node"]}/>
            {/* style={{background: color_status[status]}} */}
        </div>
    </>
  );
}

export default K8s;
