import React from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import LogoTransback from '../../img/transback_logo.svg';
import ApiIcon from '../../img/api_icon.svg';
import CardInfoTransbank from '../../components/CardInfoTransbank/CardInfoTransbank';
import InfoItem from '../../components/InfoItem/InfoItem';
import InfoHost from '../../components/InfoHost/InfoHost';
import { icons } from '../../utils/dictionaryIcons';
import CardTransbank from '../../components/CardTransbank/CardTransbank';
import CardInfo from '../../components/CardInfo/CardInfo';
import './NewTransbank.css';

function NewTransbank({ infoDashboard, selectMainDashboard }) {
  const firstColumn = [
    {
      title: 'Servidores',
      label: 'Console',
      iconName: 'servers',
      servers: ['Bank', 'Curl'],
      horizontal: true,
    },
    {
      title: 'Servidores',
      label: 'Transacciones',
      iconName: 'servers',
      servers: ['Artic', 'Manto'],
      horizontal: true,
    },
  ];
  const secondColumn = [
    {
      servers: ['ASTON'],
    },
  ];

  console.log(infoDashboard)
  function getFirstColumn() {
    const arrayCard = [];
    firstColumn.map((itemColumn, indexCard) => {
      const arrayDeviceCmpt = [];
      itemColumn.servers.map((server, indexServer) => {
        const arrayItemsCmpt = [];
        const infDev = infoDashboard[server];
        
        if (infDev.items && infDev.items.length) {
          infDev.items.forEach((itm, index) => {
            arrayItemsCmpt.push(
              <InfoItem
                style={{ marginTop: '0px', padding: '4px 10px' }}
                iconName={itm.iconName}
                status={itm.status}
                itemName={itm.name}
                itemLabel={itm.label}
                extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                key={`item-${itm.name}-${itm.label}-${server}-${index}`}
              />
            );
          });
        } else {
          arrayItemsCmpt.push(
            <InfoItem
              style={{ marginTop: '0px', padding: '8px 10px' }}
              iconName={'query'}
              status={'NOTDEFINED'}
              itemName={'Cpu utilization'}
              itemLabel={'Infraestructura'}
              key={`item-cpu-utilization`}
            />
          );
          arrayItemsCmpt.push(
            <InfoItem
              style={{ marginTop: '0px', padding: '8px 10px' }}
              iconName={'query'}
              status={'NOTDEFINED'}
              itemName={'Memory utilization'}
              itemLabel={'Infraestructura'}
              key={`item-memory-utilization`}
            />
          );
          arrayItemsCmpt.push(
            <InfoItem
              style={{ marginTop: '0px', padding: '8px 10px' }}
              iconName={'query'}
              status={'NOTDEFINED'}
              itemName={'Disk utilization'}
              itemLabel={'Infraestructura'}
              key={`item-disk-utilization`}
            />
          );
        }
        arrayDeviceCmpt.push(
          <CardInfoTransbank
            status={infDev.status}
            mainContent={
              <InfoHost
                iconName={infDev.iconName}
                type={infDev.custom_desc ? infDev.custom_desc : infDev.type}
                hostname={infDev.name}
                ip={`IP ${infDev.ip}`}
              />
            }
            items={arrayItemsCmpt}
            key={`${server}-${indexServer}-first`}
          />
        );
      });

      arrayCard.push(
        <CardTransbank title={itemColumn.title} label={itemColumn.label} iconName={itemColumn.iconName}>
          {arrayDeviceCmpt}
        </CardTransbank>
      );
    });
    return (
      <div className="column-items" key={`transbank-first-column`} style={{ flex: '2' }}>
        {arrayCard}
      </div>
    );
  }

  function getColumn(orderColumns) {
    return orderColumns.map((order, index) => {
      const arrayDevicesName = order.split(',');
      const arrayDeviceCmpt = [];
      let isFlex1 = false;
      if (arrayDevicesName && arrayDevicesName.length) {
        arrayDevicesName.forEach((device) => {
          const arrayItemsCmpt = [];
          const infDev = infoDashboard[device];
          isFlex1 = infDev.name === 'SYS1' ? true : false;
          infDev.items.forEach((itm, index) => {
            if (!itm.name.includes('utilization')) {
              arrayItemsCmpt.push(
                <InfoItem
                  iconName={itm.iconName}
                  status={itm.status}
                  itemName={itm.name}
                  itemLabel={itm.label}
                  extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                  key={`item-${itm.name}-${itm.label}-${device}-${index}`}
                />
              );
            }
          });
          // console.log(infDev);

          arrayDeviceCmpt.push(
            <CardInfo
              status={infDev.status}
              style={{
                position: infDev.name === 'SYS1' ? 'absolute' : '',
                top: infDev.name === 'SYS1' ? '350px' : '',
                left: infDev.name === 'SYS1' ? '450px' : '',
              }}
              mainContent={
                <InfoHost
                  iconName={infDev.iconName}
                  type={infDev.custom_desc ? infDev.custom_desc : infDev.type}
                  hostname={infDev.name}
                  ip={`IP ${infDev.ip}`}
                />
              }
              items={arrayItemsCmpt}
            />
          );
        });
      } else {
        return null;
      }
      return (
        <>
          <div
            className="column-items"
            key={`column-${index}`}
            style={{ width: '287px', height: '100%', flex: isFlex1 ? 1 : 2 }}
          >
            {arrayDeviceCmpt}
          </div>
        </>
      );
    });
  }

  function getInfoEnlace(name){
    if(infoDashboard.hasOwnProperty(name)){
      const sw = infoDashboard[name];
      if(sw && sw.items && sw.items.length > 0){
        const itm = sw.items[0];
        return itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''
      }
    }
  }

  return (
    <section className="new-transback-view">
      <Toolbar title={`Pago / Cajas clínica alemana`} />
      <div className="content">
        {getFirstColumn()}
        {getColumn(['ASTON', 'Switch 50823-vit.cdpiso-3.sw.alemana,Switch 50824-vit.cdpiso-1.sw.alemana'])}
        <div className="column-items">
          <div
            style={{
              width: '198px',
              height: '171px',
              background: '#ffffff',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            }}
          >
            <div style={{ width: '161px', height: '64px' }}>
              <img src={LogoTransback} style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ width: '59px' }}>
              <img src={ApiIcon} style={{ width: '100%', height: 'auto' }} />
            </div>
            <div style={{ fontSize: '12px', lineHeight: '19.26px' }}>API</div>
            <div style={{ fontSize: '12px', lineHeight: '19.26px' }}>Nombre 123</div>
          </div>
        </div>
        {/* FLECHAS DE BASE DE DATOS ARRIBA */}
        <div className="line" style={{ width: '3px', height: '55px', top: '305px', left: '510px' }}></div>
        <div className="line" style={{ width: '74px', top: '305px', left: '436px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '300px', left: '434px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '309px', left: '434px' }}></div>
        {/* FLECHAS DE BASE DE DATOS ABAJO */}
        <div className="line" style={{ width: '3px', height: '55px', top: '502px', left: '510px' }}></div>
        <div className="line" style={{ width: '74px', top: '554px', left: '436px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '550px', left: '434px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '559px', left: '434px' }}></div>
        {/* ARTIC - SWITCH 50238 */}
        <div className="line" style={{ width: '345px', top: '610px', left: '435px' }}></div>
        <div className="line" style={{ width: '3px', height: '310px', top: '300px', left: '777px' }}></div>
        <div className="line" style={{ width: '137px', top: '300px', left: '780px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '295px', left: '906px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '305px', left: '906px' }}></div>
        {/* BANK - SWITCH 50238 */}
        <div className="line" style={{ width: '481px', top: '227px', left: '437px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '222px', left: '905px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '232px', left: '905px' }}></div>
        {/* MANTO - SWITCH 50248 */}
        <div className="line" style={{ width: '390px', top: '737px', left: '435px' }}></div>
        <div className="line" style={{ width: '3px', height: '101px', top: '636px', left: '822px' }}></div>
        <div className="line" style={{ width: '93px', top: '636px', left: '825px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '631px', left: '906px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '640px', left: '906px' }}></div>
        {/* CURL - SWITCH 50248 */}
        <div className="line" style={{ width: '390px', top: '270px', left: '435px' }}></div>
        <div className="line" style={{ width: '3px', height: '330px', top: '273px', left: '822px' }}></div>
        <div className="line" style={{ width: '93px', top: '600px', left: '825px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '595px', left: '906px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '604px', left: '906px' }}></div>
        {/* FLECHAS A TRANSBANK DE ARRIBA */}
        <div className="info-enlace" style={{position: "absolute", top: "229px", left: "1224px", color: "black"}}>{getInfoEnlace("Switch 50823-vit.cdpiso-3.sw.alemana")}</div>
        <div className="dashed-line-horizontal" style={{ width: '215px', top: '260px', left: '1206px' }}></div>
        <div className="dashed-line-vertical" style={{ width: '3px', height: '130px', top: '260px', left: '1421px' }}></div>
        <div className="dashed-line-horizontal" style={{ width: '111px', top: '390px', left: '1421px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '385px', left: '1521px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '395px', left: '1521px' }}></div>
        {/* FLECHAS A TRANSBANK DE ABAJO */}
        <div className="info-enlace" style={{position: "absolute", top: "634px", left: "1224px", color: "black"}}>{getInfoEnlace("Switch 50824-vit.cdpiso-1.sw.alemana")}</div>
        <div className="dashed-line-horizontal" style={{ width: '215px', top: '610px', left: '1206px' }}></div>
        <div className="dashed-line-vertical" style={{ width: '3px', height: '130px', top: '482px', left: '1421px' }}></div>
        <div className="dashed-line-horizontal" style={{ width: '111px', top: '482px', left: '1421px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '477px', left: '1521px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '487px', left: '1521px' }}></div>
      </div>
      <div className="back-icon" onClick={selectMainDashboard}>
        <div>
          <img src={icons['back']} />
        </div>
      </div>
    </section>
  );
}

export default NewTransbank;
