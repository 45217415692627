import React from 'react';
import './NginxContainer.css';
import  {icons} from "../../utils/dictionaryIcons"
import  {color_status} from "../../utils/dictionaryStates"

function NginxContainer({status, style = undefined, content= undefined}) {
  return (
    <>
        <div className="nginx-container" style={style}>
            <img src={icons["Nginx"]} style={{marginRight: "10px"}}/>
            <div className='nginx-title'>NGINX</div>
            <div className='nginx-status' style={{background: color_status[status]}}></div>
            {content}
        </div>
    </>
  );
}

export default NginxContainer;
