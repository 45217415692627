// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-country-cloud {
  width: 170px;
  height: 122px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #63666E;
  border-radius: 10px;
  padding: 15px;
}

.card-country-cloud .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height: 70px; */
}

.card-country-cloud .header .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.card-country-cloud .header .content .title-cc {
  font-weight: bold;
  font-size: 14px;
}

.card-country-cloud .header .content .label-cc {
  font-size: 10px;
}

.card-country-cloud .body {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.card-country-cloud .body img {
  width: 35px;
  height: 35px;
}

.card-country-cloud .body .flag-card {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./components/CardCountryCloud/CardCountryCloud.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".card-country-cloud {\n  width: 170px;\n  height: 122px;\n  display: flex;\n  flex-direction: column;\n  background: #ffffff;\n  color: #63666E;\n  border-radius: 10px;\n  padding: 15px;\n}\n\n.card-country-cloud .header {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  /* height: 70px; */\n}\n\n.card-country-cloud .header .content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-left: 12px;\n}\n\n.card-country-cloud .header .content .title-cc {\n  font-weight: bold;\n  font-size: 14px;\n}\n\n.card-country-cloud .header .content .label-cc {\n  font-size: 10px;\n}\n\n.card-country-cloud .body {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 15px;\n}\n\n.card-country-cloud .body img {\n  width: 35px;\n  height: 35px;\n}\n\n.card-country-cloud .body .flag-card {\n  width: 20px;\n  height: 20px;\n  margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
