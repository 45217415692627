// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-problem-item-cmpt {
  display: flex;
  width: 100%;
  margin-bottom: 1px;
}

.service-problem-item-cmpt .info-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.service-problem-item-cmpt .info-section .store {
  font-weight: bold;
  text-transform: uppercase;
  color: gray;
  font-size: 6px;
}

.service-problem-item-cmpt .info-section .fall-stores {
  display: flex;
  border-radius: 4px;
  border: 1px solid #7b89a8;
  color: gray;
  width: 100%;
  height: 20px;
  justify-content: space-between;
  margin-top: 2px;
}

.service-problem-item-cmpt .info-section .fall-stores .label-spi {
  display: flex;
  align-items: center;
  font-size: 5px;
  width: 55%;
  height: 100%;
  padding-left: 4px;
}

.service-problem-item-cmpt .info-section .fall-stores .label-status {
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  font-size: 7px;
}

.service-problem-item-cmpt .info-section .fall-stores .label-status .wrapper {
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./components/ServiceProblemItem/ServiceProblemItem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".service-problem-item-cmpt {\n  display: flex;\n  width: 100%;\n  margin-bottom: 1px;\n}\n\n.service-problem-item-cmpt .info-section {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.service-problem-item-cmpt .info-section .store {\n  font-weight: bold;\n  text-transform: uppercase;\n  color: gray;\n  font-size: 6px;\n}\n\n.service-problem-item-cmpt .info-section .fall-stores {\n  display: flex;\n  border-radius: 4px;\n  border: 1px solid #7b89a8;\n  color: gray;\n  width: 100%;\n  height: 20px;\n  justify-content: space-between;\n  margin-top: 2px;\n}\n\n.service-problem-item-cmpt .info-section .fall-stores .label-spi {\n  display: flex;\n  align-items: center;\n  font-size: 5px;\n  width: 55%;\n  height: 100%;\n  padding-left: 4px;\n}\n\n.service-problem-item-cmpt .info-section .fall-stores .label-status {\n  color: #ffffff;\n  font-weight: bold;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 45%;\n  font-size: 7px;\n}\n\n.service-problem-item-cmpt .info-section .fall-stores .label-status .wrapper {\n  height: 80%;\n  width: 80%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
