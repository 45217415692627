import Logo from '../img/logo.svg';
import Admin from '../img/admin.png';
import Android from '../img/android.png';
import App from '../img/app.png';
import Barney from '../img/barney.png';
import BD from '../img/bd.png';
import Carl from '../img/carl.png';
import Cluster from '../img/clusterk8s.png';
import Container from '../img/container.png';
import DockerHub from '../img/docker-hub.png';
import Docker from '../img/docker.png';
import DVU from '../img/dvu.png';
import Edna from '../img/edna.png';
import Hyman from '../img/hyman.png';
import IA from '../img/ia.png';
import Ingress from '../img/ingress.png';
import Java from '../img/java.png';
import Jeff from '../img/jeff.png';
import Julius from '../img/julius.png';
import Kent from '../img/kent.png';
import Krusty from '../img/krusty.png';
import Lenny from '../img/lenny.png';
import Luann from '../img/luann.png';
import Milhouse from '../img/milhouse.png';
import MongoDB from '../img/mongodb.png';
import MySQL from '../img/mysql.png';
import Nginx from '../img/nginx.png';
import Node from '../img/node.png';
import Patty from '../img/patty.png';
import Pod from '../img/pod.png';
import Redis from '../img/redis.png';
import Rod from '../img/rod.png';
import Santa from '../img/santa.png';
import Skinner from '../img/skinner.png';
import Todd from '../img/todd.png';
import User from '../img/user.png';
import Web from '../img/web.png';

const icons = {
  Logo: Logo,
  Admin: Admin,
  Android: Android,
  App: App,
  Barney: Barney,
  BD: BD,
  Carl: Carl,
  Cluster: Cluster,
  Container: Container,
  DockerHub: DockerHub,
  Docker: Docker,
  DVU: DVU,
  Edna: Edna,
  Hyman: Hyman,
  IA: IA,
  Ingress: Ingress,
  Java: Java,
  Jeff: Jeff,
  Julius: Julius,
  Kent: Kent,
  Krusty: Krusty,
  Lenny: Lenny,
  Luann: Luann,
  Milhouse: Milhouse,
  MongoDB: MongoDB,
  MySQL: MySQL,
  Nginx: Nginx,
  Node: Node,
  Patty: Patty,
  Pod: Pod,
  Redis: Redis,
  Rod: Rod,
  Santa: Santa,
  Seymour: Skinner,
  Todd: Todd,
  User: User,
  Web: Web
};



export { icons};
