import api from "./requests";
import {robotIds} from "../utils/robot_ids";

export function removeCircularReferences(obj, seen = new WeakSet()) {
  if (obj && typeof obj === 'object') {
    if (seen.has(obj)) {
      return '[Circular]';
    }
    seen.add(obj);
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = removeCircularReferences(obj[key], seen);
      }
    }
    seen.delete(obj);
  }
  return obj;
}

export function processData(data) {
  const series = {};
  data.series.forEach((serie) => {
    if (serie.fields) {
      serie.fields.forEach((field) => {
        const values = field.values.length ? field.values : [];
        const [category, host] = serie.name.split(':');
        if (!series[category]) {
          series[category] = {};
        }
        if (!series[category][host]) {
          series[category][host] = [];
        }
        values.forEach((value, index) => {
          if (!series[category][host][index]) {
            series[category][host].push({});
          }
          if ((field.name === 'tags' || field.name === 'triggers') && typeof value === 'string') {
            series[category][host][index][field.name] = JSON.parse(value);
          } else {
            series[category][host][index][field.name] = value;
          }
        });
      });
    }
  });
  return JSON.parse(JSON.stringify(series));
}

export function getFlujosSeguros(){
  const wraps = [];
  robotIds.forEach(async (id) => {
    const flujo = await api.fetchRobot(id);
    if(flujo){
      let name = flujo.robot.groupDescription;
      name = name.split('_')
      name.shift();
      name = name.join(" ")
      const uptime = flujo.robot.robotUptimeFactor * 100;
      let status = "OK";
      flujo.steps.forEach(step => {
        if(step.response.startsWith("OK")){

        }
        else if(step.response.startsWith("UNKNOWN")){
          if(status === "OK"){
            status = "UNKNOWN";
          }
        }
        else{
          if(status !== "CRITICAL"){
            status = "CRITICAL";
          }
        }
      });
      wraps.push({"name": name, "status": status, "percentage": uptime.toFixed(2) + "%"})
    }
  });
  return wraps;

}

export function getHostData(hostData, hosts){
  const filteredData = hostData.filter(host => hosts.includes(host.name));
  const processedData = filteredData.map(host =>{
    const perfData = host.perf_data;
    if(perfData){
      const arrayPerfData = perfData.split(" ");
      const pingData = {};
      arrayPerfData.forEach((perf_data) =>{
        const data = perf_data.split(";");
        let temp = data[0].split("=");
        if(temp[0] === "rta"){
          pingData["Response time"]= temp[1];
        }
      })
      return {...host, ...pingData};
    }
    else{
      return host;
    }
  })
  return processedData;
}

