import React from 'react';
import StatusCard from '../StatusCard/StatusCard';
import './WhiteContainer.css';

function WhiteContainer({ title, style, content = [], row = false, two_columns = false, status_card=undefined, status_card_title=""}) {

  if(two_columns){
    const midIndex = Math.floor(content.length / 2);
    const firstHalf = content.slice(0, midIndex);      // First half
    const secondHalf = content.slice(midIndex);
    return(
      <div className="white-container" style={style}>
        <div className='header'>
            <div className='title'>
              {title}
            </div>
        </div>
        <div className='content' style={{flexDirection: "row"}}>
          <div className='column'>{firstHalf}</div>
          <div className='column'>{secondHalf}</div>
        </div>
    </div>
    )
  }

  return (
    <div className="white-container" style={style}>
        <div className='header'>
            <div className='title'>
              {title}
            </div>
            {status_card && <StatusCard title={status_card_title} status={status_card}></StatusCard>}
        </div>
        <div className='content' style={row ? {flexDirection:"row"}: {}}>{content}</div>
    </div>
  );
}

export default WhiteContainer;
