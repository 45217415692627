import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import {
  processData,
  createDataForDashboard,
  transbankFilter,
  nucleoFilter,
  infraFilter,
  createDataForEnlacesDashboard,
  createDataForInfraCriticaDashboard,
  getClientesIndicadores,
  getClientesIndicadoresSubscriptors,
  appsByNucleoErrors,
  createDataforImedDashboard,
  createDataForVDI,
  createDataForBD,
  // @ts-ignore
} from 'api/datasource.js';
// @ts-ignore
import Transbank from '../views/Transbank/Transbank';
// @ts-ignore
import NewTransbank from '../views/NewTransbank/NewTransbank';
// @ts-ignore
import InfroCritica from '../views/InfraCritica/InfraCritica';
// @ts-ignore
import InfraCritica2 from '../views/InfraCritica2/InfraCritica2';
// @ts-ignore
import ClientesIndicadores from '../views/ClientesIndicadores/ClientesIndicadores';
// @ts-ignore
import Enlaces from '../views/Enlaces/Enlaces';
// @ts-ignore
import Central from '../views/Central/Central';
// @ts-ignore
import Nucleo from '../views/Nucleo/Nucleo';
// @ts-ignore
import NucleoDigital from '../views/NucleoDigital/NucleoDigital';
// @ts-ignore
import VDI from '../views/VDI/VDI';
// @ts-ignore
import Tanzu from '../views/Tanzu/Tanzu';
// @ts-ignore
import Imed from "../views/Imed/Imed";
// @ts-ignore
import BaseDeDatos from "../views/BaseDeDatos/BaseDeDatos";
// @ts-ignore
import array_of_central_items from './fake.js';
// @ts-ignore
import { number_status, global_states } from '../utils/dictionaryStates.js';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data }) => {
  const [infoCajasDashboard, setInfoCajasDashboard] = useState({});
  const [infoEnlacesDashboard, setInfoEnlacesDashboard] = useState({});
  const [infoTransbankDashboard, setInfoTransbankDashboard] = useState({});
  const [infoInfraCriticaDashboard, setInfoInfraCriticaDashboard] = useState({});
  const [infoVDIDashboard, setInfoVDIDashboard] = useState({});
  const [infoBdDashboard, setInfoBdDashboard] = useState({});
  const [infoNucleoDashboard, setInfoNucleoDashboard] = useState({});
  const [infoImed, setInfoImed] = useState({});
  //const [infoRisAgfa, setInfoRisAgfa] = useState({}); 
  const [completeSeries, setCompleteSeries] = useState(null);
  const [subscriptoresClientesIndicadores, setSubscriptoresClientesIndicadores] = useState({});
  const [urlsClientesIndicadores, setUrlsClientesIndicadores] = useState({});

  const [ldapStatus, setLdapStatus] = useState("UNK");
  const [tuxStatus, setTuxStatus] = useState("UNK");
  // const [infoHostsWeblogic, setInfoHostsWeblogic] = useState({});

  const [orderColumns, setOrderColumns] = useState([]);
  const [speedEnlaces, setEnlacesSpeed] = useState([]);
  const [thresholdSpeed, setThresholdsSpeed] = useState({ overloaded: 85, unused: 5 });
  // const [nameSelectedDashboard, setNameSelectedDashboard] = useState('Clientes indicadores');
  const [nameSelectedDashboard, setNameSelectedDashboard] = useState('');
  const [arrayCentralItems, setArrayCentralItems] = useState(array_of_central_items);
  const [centralItemsTmp, setCentralItemsTmp] = useState(null);
  const [infoIndicatorClients, setInfoIndicatorClients] = useState(null);
  const [infraStatusTmp, setInfraStatusTmp] = useState({ total: 0, problems: 0, status: 'UNDEFINED' });
  const [VDIStatus, setVDIStatus] = useState({ total: 0, problems: 0, status: 'UNDEFINED' });
  const [hasRedirected, setHasRedirected] = useState(false);

  const [tanzu, setTanzu] = useState([]);
  
  const [digiNucleo, setDigiNucleo] = useState([]);

  const [loadingInfraCritica, setLoadingInfraCritica] = useState(false);

  const [tmpCliInd,setTmpCliInd] = useState(false);

  function selectDashboard(dashboard: any) {
    setNameSelectedDashboard(dashboard);
  }
  function redirectView(view:any){
    if(hasRedirected){
      return 0;
    }
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has("view")){
      const viewParam = urlParams.get("view")
      if(viewParam != null && viewParam === view){
        setHasRedirected(true);
        setNameSelectedDashboard(viewParam);
      }
    }
  }

  function getDashboard() {
    // if (nameSelectedDashboard === 'Cajas (xCash / Transbank)') {
    // return (
    //   <Transbank
    //     infoDashboard={infoCajasDashboard}
    //     orderColumns={orderColumns}
    //     selectMainDashboard={() => {
    //       selectDashboard(null);
    //     }}
    //   />
    // );
  // }
  if (nameSelectedDashboard === 'Transbank') {
      return (
        <NewTransbank
          infoDashboard={infoTransbankDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Infraestructura Crítica') {
      return (
        <InfraCritica2
          infoDashboard={infoInfraCriticaDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }else if (nameSelectedDashboard === 'Clientes indicadores') {
      return (
        <ClientesIndicadores
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          ldapStatus={ldapStatus}
          tuxedoStatus={tuxStatus}
          tanzu={tanzu}
          infoIndicatorClients={infoIndicatorClients}
          subscriptoresClientesIndicadores={subscriptoresClientesIndicadores}
          urlsClientesIndicadores = {urlsClientesIndicadores}
        />
      );
    } else if (nameSelectedDashboard === 'Enlaces') {
      return (
        <Enlaces
          speedEnlaces={speedEnlaces}
          infoDashboard={infoEnlacesDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          overloadedSpeed={thresholdSpeed['overloaded']}
          unusedSpeed={thresholdSpeed['unused']}
        />
      );
    } else if (nameSelectedDashboard === 'Nucleo') {
      return (
        <Nucleo
          infoDashboard={infoNucleoDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }
    else if (nameSelectedDashboard === 'Imed') {
      return (
        <Imed
          infoImed = {infoImed}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }
    else if (nameSelectedDashboard === 'Ficha Nucleo') {
      return (
        <NucleoDigital
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }else if (nameSelectedDashboard === 'VDI') {
      return (
        <VDI
          infoDashboard={infoVDIDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }else if (nameSelectedDashboard === 'Tanzu') {
      return (
        <Tanzu
          tanzu = {tanzu}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    }else if (nameSelectedDashboard === 'Base de Datos') {
    return (
      <BaseDeDatos
        infoDashboard={infoBdDashboard}
        selectMainDashboard={() => {
          selectDashboard(null);
        }}
      />
    );
    } else {
      return <Central array_of_central_items={arrayCentralItems} selectDashboard={selectDashboard} />;
    }
  }

  // @ts-ignore
  function removeCircularReferences(obj, seen = new WeakSet()) {
    if (obj && typeof obj === 'object') {
      if (seen.has(obj)) {
        return '[Circular]';
      }
      seen.add(obj);
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = removeCircularReferences(obj[key], seen);
        }
      }
      seen.delete(obj);
    }
    return obj;
  }

  async function getInfraCritica(setLoading: any) {
    const cleanedData = removeCircularReferences(data);    
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');
    // const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');

    const series = JSON.parse(JSON.stringify(originalSeries));
    const seriesInfraFiltered = infraFilter(series["infracritica"])
    const {
      vcenter,
      infoHostsWeblogic: weblogic,
      ldap,
      dynatrace,
      infraStatus,
      totalInfra,
      problemInfra,
      ldapStatus,
      tuxedoStatus,
      tuxedo,
      tanzu,
      vcenterVDI,
      totalVDI,
      problemVDI,
      VDIstatus
    } = await createDataForInfraCriticaDashboard(seriesInfraFiltered, setLoading);
    const {hostsVDI, totalVDI: totalVDI2, problemVDI: problemVDI2, arrUrls: urlsVDI ,status: VDIstatus2} = await createDataForVDI(series["vdi"], series["TOCO_URLS"], VDIstatus);
    setInfraStatusTmp({ total: totalInfra, problems: problemInfra, status: infraStatus });
    setVDIStatus({total: totalVDI + totalVDI2, problems: problemVDI + problemVDI2, status: VDIstatus2 })
    setInfoInfraCriticaDashboard({ vcenter, weblogic, ldap, dynatrace, tuxedo });
    setInfoVDIDashboard({vcenter: vcenterVDI, toco: hostsVDI, tocourls: urlsVDI});
    setLdapStatus(ldapStatus);
    setTuxStatus(tuxedoStatus);
    let tanzuProblems = 0;
    let tanzuStatus = "UNKNOWN";
    if(tanzu){
      tanzuStatus = 'OK'
      tanzu.forEach((tanzuElem: any) => {
        if(tanzuElem.status!="OK"){
          tanzuProblems+=1
          if(tanzuStatus === "OK" || (tanzuStatus === "WARNING" && tanzuElem.status == "CRITICAL")){
            tanzuStatus = tanzuElem.status;
          }
        }
      })
    }
    const indexTanzu = arrayCentralItems.findIndex((itm: any) => itm.title === 'Tanzu');

    setArrayCentralItems((arrayCentralItems: any) =>{
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      copyArrayCentralItems[indexTanzu]['status'] = tanzuStatus;
      copyArrayCentralItems[indexTanzu]['loading'] = false;
      copyArrayCentralItems[indexTanzu]['total'] = tanzu.length;
      copyArrayCentralItems[indexTanzu]['problems'] = tanzuProblems;
      return copyArrayCentralItems;
    });
    setTanzu(tanzu);
    
    redirectView("Tanzu");
    redirectView("VDI");
  }

  async function getDigiNucleo(){
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');
    const series = JSON.parse(JSON.stringify(originalSeries));
// 
    const res =  await appsByNucleoErrors(series['infracritica']);
    // console.log(res);
    setDigiNucleo(res);
  }
  // console.log(loadingInfraCritica);

  async function getBD(){
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');
    const series = JSON.parse(JSON.stringify(originalSeries));
    const data_BD =  series['basededatos'];
    const{
      infoHosts: infoBD,
      globalStatus: infoBDGlobalStatus,
      total: infoBDTotal,
      totalProblems: infoBDProblems} = await createDataForBD(data_BD);
    
    setInfoBdDashboard(infoBD);
    
    const indexBD = arrayCentralItems.findIndex((itm: any) => itm.title === 'Base de Datos');
    setArrayCentralItems((arrayCentralItems: any) =>{
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      copyArrayCentralItems[indexBD]['status'] = infoBDGlobalStatus;
      copyArrayCentralItems[indexBD]['total'] = infoBDTotal;
      copyArrayCentralItems[indexBD]['problems'] = infoBDProblems;
      return copyArrayCentralItems;
    });
  }
  
  // @ts-ignore
  useEffect(() => {
    // setLoadingInfraCritica();
    getInfraCritica(setLoadingInfraCritica);
    redirectView("Infraestructura Crítica");
    // getSubscriptorsClientesIndicadores();
    // @ts-ignore
  }, [data]);

  // @ts-ignore
  useEffect(() => {
    getBD();
    // @ts-ignore
  }, [data]);

  // @ts-ignore
  useEffect(() => {
    // console.log(tmpCliInd);
    // if (tmpCliInd === true) {
      getSubscriptorsClientesIndicadores();
      if(infoIndicatorClients != null){
        redirectView("Clientes indicadores");
      }  
    // }
    // @ts-ignore
  }, [tmpCliInd]);

  useEffect(() =>{
    getDigiNucleo();
    redirectView("Ficha Nucleo")
  }, [data]);

  async function getSubscriptorsClientesIndicadores() {
    // console.log(completeSeries);
    // if (centralItemsTmp) {
    //   console.log(centralItemsTmp);
    // }
    
    if (completeSeries && centralItemsTmp && centralItemsTmp.length) {
      const clientesIndicadoresResponse = getClientesIndicadores(completeSeries['clientesindicadores']);
      const oracleInfo = clientesIndicadoresResponse['oracle'];
      const cliIndStatus = clientesIndicadoresResponse['globalStatus'];
      const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
      // ORACLE
      let infoIndicators = { oracleInfo: oracleInfo };
      // @ts-ignore
      setInfoIndicatorClients(infoIndicators);
      let infraResponse = null;
      if (completeSeries && completeSeries['infracritica']) {
        infraResponse = await getClientesIndicadoresSubscriptors(completeSeries['infracritica']);

        setSubscriptoresClientesIndicadores(infraResponse);
      }
      // 7:10 - 7:14

      let problems = 0;
      let total = 2;
      if (ldapStatus && tanzu.length && infoIndicatorClients && infraResponse) {
        let ldapStatusNumber = number_status[ldapStatus];
        let tanzuStatus = 0;
        let infoIndicatorClientsStatus = 0;
        tanzu.forEach(element => {
          total += 1;
          if (number_status[element.status] >= tanzuStatus) {
            tanzuStatus = number_status[element.status];
          }
        });
        if (infoIndicatorClients && infoIndicatorClients['oracleInfo']) {
          for (const item in infoIndicatorClients['oracleInfo']) {
            total += 1;
            const element = infoIndicatorClients['oracleInfo'][item];
            if (element.status >= infoIndicatorClientsStatus) {
              infoIndicatorClientsStatus = element.status;
            }
            if (element.status !== 0) {
              problems += 1;
            }
          }
        }
        let max = Math.max(...[ldapStatusNumber, infoIndicatorClientsStatus, number_status[cliIndStatus]]);
        if(tanzuStatus == 2 && max < 2){
          max = 1;
        }
        const{
          urls: infoV3,
          globalStatus: infoV3GlobalStatus,
          total: infoV3Total,
          problems: infoV3Problems,
        } = createDataforImedDashboard(completeSeries["CLIENTES_V3_URLS"]);
        total += infoV3Total;
        problems += infoV3Problems;
        setUrlsClientesIndicadores(infoV3);
        setArrayCentralItems((arrayCentralItems: any) => {
          const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
          copyArrayCentralItems[indexClientesIndicadores]['status'] = global_states[max];
          copyArrayCentralItems[indexClientesIndicadores]['loading'] = false;
          copyArrayCentralItems[indexClientesIndicadores]['total'] = total;
          // copyArrayCentralItems[indexClientesIndicadores]['problems'] = problems;
          return copyArrayCentralItems;
        });
      }
    }
  }

  useEffect(() => {
    const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');
    // const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
    
   
    // if (copyArrayCentralItems[indexClientesIndicadores]['status'] === 'OK' && infraStatusTmp.status === 'CRITICAL') {
    //   copyArrayCentralItems[indexClientesIndicadores]['status'] = infraStatusTmp.status;
    // }
    // getSubscriptorsClientesIndicadores();
    setTmpCliInd(!tmpCliInd);
    
    const indexVDI= arrayCentralItems.findIndex((itm: any) => itm.title === 'VDI');
    setArrayCentralItems((arrayCentralItems: any) =>{
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
      copyArrayCentralItems[indexInfraCrit]['status'] = infraStatusTmp.status;
      copyArrayCentralItems[indexInfraCrit]['total'] = infraStatusTmp.total;
      copyArrayCentralItems[indexInfraCrit]['problems'] = infraStatusTmp.problems;
      copyArrayCentralItems[indexVDI]['status'] = VDIStatus.status;
      copyArrayCentralItems[indexVDI]['total'] = VDIStatus.total;
      copyArrayCentralItems[indexVDI]['problems'] = VDIStatus.problems;
      return copyArrayCentralItems;
    });
    setTmpCliInd(!tmpCliInd);
  }, [infraStatusTmp, VDIStatus]);

  useEffect(() => {
    
  }, [VDIStatus]);

  // @ts-ignore
  useEffect(() => {
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');

    const series = JSON.parse(JSON.stringify(originalSeries));
    // console.log(series)
    setCompleteSeries(series);
    const {
      infoHosts: infoTmpDashboard,
      globalStatus: cajasGlobalStatus,
      total,
      problems,
    } = createDataForDashboard(series['cajas']);

    const {
      infoHosts: infoEnlacesDashboard,
      globalStatus: enlacesGlobalStatus,
      total: totalEnlaces,
      problems: problemsEnlaces,
    } = createDataForEnlacesDashboard(series['enlaces']);

    const seriesTransbankFiltered = transbankFilter(series['transbank']);

    const {
      infoHosts: transbankInfoDashboard,
      globalStatus: transbankGlobalStatus,
      total: transbankTotal,
      problems: transbankProblems,
    } = createDataForDashboard(seriesTransbankFiltered);

    const seriesNucleoFiltered = nucleoFilter(series["nucleo"])
    const {
      infoHosts: nucleoInfoDashboard,
      globalStatus: nucleoGlobalStatus,
      total: nucleoTotal,
      problems: nucleoProblems,
    } = createDataForDashboard(seriesNucleoFiltered, 'OK');    
    const {
      urls: infoImedTemp,
      globalStatus: ImedGlobalStatus,
      total: ImedTotal,
      problems: ImedProblems,

    } = createDataforImedDashboard(series["IMED_URLS"]);
    setInfoImed(infoImedTemp);

    const{
      urls: infoRisAgfaTemp,
      globalStatus: RisAgfaGlobalStatus,
      total: RisAgfaTotal,
      problems: RisAgfaProblems,
    } = createDataforImedDashboard(series["RIS_AGFA_URLS"]);
    // const clientesIndicadoresResponse = getClientesIndicadores(series['clientesindicadores']);
    // const oracleInfo = clientesIndicadoresResponse['oracle'];
    // const cliIndStatus = clientesIndicadoresResponse['globalStatus'];

    // const indexCajas = arrayCentralItems.findIndex((itm: any) => itm.title === 'Cajas (xCash / Transbank)');
    const indexEnlaces = arrayCentralItems.findIndex((itm: any) => itm.title === 'Enlaces');
    const indexTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Transbank');
    const nucleoTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Nucleo');
    const indexImed = arrayCentralItems.findIndex((itm: any) => itm.title === 'Imed');
    const indexRisAgfa = arrayCentralItems.findIndex((itm: any) => itm.title === 'RIS - AGFA');
    const nucleoDigitalTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Ficha Nucleo');
    // const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
    // const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');


    // copyArrayCentralItems[indexClientesIndicadores]['status'] = cliIndStatus;

    setInfoCajasDashboard(infoTmpDashboard);
    // Set info central panel
    setArrayCentralItems((arrayCentralItems: any) =>{
      const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
  // Cajas
      copyArrayCentralItems[nucleoTransbank]['status'] = nucleoGlobalStatus;
      copyArrayCentralItems[nucleoTransbank]['total'] = nucleoTotal;
      copyArrayCentralItems[nucleoTransbank]['problems'] = nucleoProblems;

      // Imed
      copyArrayCentralItems[indexImed]['status'] = ImedGlobalStatus;
      copyArrayCentralItems[indexImed]['total'] = ImedTotal;
      copyArrayCentralItems[indexImed]['problems'] = ImedProblems;

      // RIS - AFGA
      copyArrayCentralItems[indexRisAgfa]['status'] = RisAgfaGlobalStatus;
      copyArrayCentralItems[indexRisAgfa]['total'] = RisAgfaTotal;
      copyArrayCentralItems[indexRisAgfa]['problems'] = RisAgfaProblems;

      copyArrayCentralItems[indexEnlaces]['status'] = enlacesGlobalStatus;
      copyArrayCentralItems[indexEnlaces]['total'] = totalEnlaces;
      copyArrayCentralItems[indexEnlaces]['problems'] = problemsEnlaces;
      setInfoEnlacesDashboard(infoEnlacesDashboard);

      // Transbank
      copyArrayCentralItems[indexTransbank]['status'] = transbankGlobalStatus;
      copyArrayCentralItems[indexTransbank]['total'] = transbankTotal;
      copyArrayCentralItems[indexTransbank]['problems'] = transbankProblems;
      setInfoTransbankDashboard(transbankInfoDashboard);
      setCentralItemsTmp(copyArrayCentralItems);
      return copyArrayCentralItems;
    });

    setInfoNucleoDashboard(nucleoInfoDashboard);
    redirectView("Imed")
    redirectView("Transbank");
    redirectView("Nucleo");
  }, [data]);

  useEffect(() => {
    if (options && options.orderColumns) {
      setOrderColumns(JSON.parse(options.orderColumns));
    }
    if (options && options.enlacesSpeed) {
      setEnlacesSpeed(JSON.parse(options.enlacesSpeed));
    }
    if (options && options['enlace-critical-threshold'] && options['enlace-unused-threshold']) {
      setThresholdsSpeed({
        overloaded: options['enlace-critical-threshold'],
        unused: options['enlace-unused-threshold'],
      });
    }
    redirectView("Enlaces");
  }, [options]);

  return (
    <div
      className="panel-wrapper"
      style={{ width: '1800px', height: '1020px', backgroundColor: '#d1def0', padding: '15px' }}
    >
      {getDashboard()}
    </div>
  );
};
