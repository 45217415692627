import React from 'react';
import './WhiteContainer.css';
import { getStatusColorByType } from '../../utils/color';
function WhiteContainer({ style, title, subtitle, status, statusContent, content, horizontal }) {
  if(horizontal){
    return(
      <div className="white-container" style={style}>
        <div className='header'>
          <div className='header-titles'>
            <div className='header-title'>{title}</div>
            <div className='sub-title'>{subtitle}</div>
            <i class="fa-solid fa-eye" style={{width: "10%"}}></i>
          </div>
          <div className='body-horizontal'>
            {content}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="white-container" style={style}>
      <div className='header'>
        <div className='header-titles'>
          <div className='header-title'>{title}</div>
          <div className='sub-title'>{subtitle}</div>
        </div>
        {status && (
          <div
            className='status'
            style={{ background: getStatusColorByType(status) }}
          >
            {statusContent}
          </div>
        )}
      </div>
      <div className='content'>{content}</div>
    </div>
  );
}

export default WhiteContainer;
