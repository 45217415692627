// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 0;
}

.toolbar .title {
  font-family: Sansation;
  font-weight: 700;
  font-size: 30px;
  line-height: 33.57px;
  color: #4F70A7;
  margin-left: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.toolbar img {
  width: 290px;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./components/Toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;;AAEhC;;AAEA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".toolbar {\n  width: 100%;\n  height: 76px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  z-index: 0;\n}\n\n.toolbar .title {\n  font-family: Sansation;\n  font-weight: 700;\n  font-size: 30px;\n  line-height: 33.57px;\n  color: #4F70A7;\n  margin-left: 20px;\n  letter-spacing: 0.04em;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n\n}\n\n.toolbar img {\n  width: 290px;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
