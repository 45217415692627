const buildHostUrl = (hostname, siteName, siteUrl) => {
  siteName = 'NREDES_REGIONAL';
  return (
    siteUrl +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent('/' + siteName + '/check_mk/view.py?host=' + hostname + '&site=' + siteName + '&view_name=host')
  );
};

const buildUrlDatacenterDevice = (storeName, deviceType, siteUrl) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent(
      '/' +
        siteName +
        '/check_mk/view.py?filled_in=filter&_active=wato_folder%3Bhost_tags&wato_folder=&host_tag_0_grp=CODIGO_DE_TIENDA&host_tag_0_op=is&host_tag_0_val=' +
        storeName +
        '&host_tag_1_grp=TIPO_DE_DISPOSITIVO&host_tag_1_op=is&host_tag_1_val=' +
        deviceType +
        '&host_tag_2_grp=TIENDA&host_tag_2_op=is&host_tag_2_val=DATACENTER&view_name=searchhost&_show_filter_form=1'
    )
  );
};

const buildUrlDatacenterEnlace = (storeName, siteUrl) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent(
      '/' +
        siteName +
        '/check_mk/view.py?filled_in=filter&&_active=serviceregex%3Bhost_tags&service_regex=OSPF%7CBGP%7CENLACE&host_tag_0_grp=TIENDA&host_tag_0_op=is&host_tag_0_val=DATACENTER&host_tag_1_grp=CODIGO_DE_TIENDA&host_tag_1_op=is&host_tag_1_val=' +
        storeName +
        '&host_tag_2_op=is&view_name=searchsvc&_show_filter_form=0'
    )
  );
};

const buildUrlHostService = (host, service, siteUrl, site) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent(
      '/' +
        siteName +
        '/check_mk/view.py?host=' +
        host +
        '&service=' +
        service +
        '&site=' +
        site +
        '&view_name=service'
    )
  );
};

function buildUrlHostProblems(hosts, siteUrl) {
  const siteName = 'NREDES_REGIONAL';
  const hostRegex = hosts.map((host) => host.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')).join('|');

  const startUrlParams = new URLSearchParams({
    filled_in: 'filter',
    _active: 'wato_folder;hostregex;host_address;hostalias;host_labels;host_tags;siteopt',
    wato_folder: '',
    host_regex: hostRegex,
    host_address: '',
    host_address_prefix: 'yes',
    hostalias: '',
    host_labels_count: '1',
    host_labels_indexof_1: '1',
    host_labels_1_bool: 'and',
    host_labels_1_vs_count: '1',
    host_labels_1_vs_indexof_1: '1',
    host_labels_1_vs_1_bool: 'and',
    host_tag_0_op: 'is',
    host_tag_1_op: 'is',
    host_tag_2_op: 'is',
    view_name: 'searchhost',
    _show_filter_form: '1',
  });

  const encodedStartUrl = encodeURIComponent(`/${siteName}/check_mk/view.py?` + startUrlParams.toString());

  const finalUrl = `${siteUrl[siteName]}/${siteName}/check_mk/index.py?start_url=${encodedStartUrl}`;
  return finalUrl;
}

function buildUrlWithHostTags(siteUrl, store, store_code) {
  const siteName = 'NREDES_REGIONAL';
  const hostTagParams = [
    { group: 'TIENDA', value: store },
    { group: 'CODIGO_DE_TIENDA', value: store_code },
  ];
  const startUrlParams = new URLSearchParams({
    filled_in: 'filter',
    _active: 'wato_folder;hostregex;host_address;hostalias;host_labels;host_tags;siteopt',
    wato_folder: '',
    host_address: '',
    host_address_prefix: 'yes',
    hostalias: '',
    host_labels_count: '1',
    host_labels_indexof_1: '1',
    host_labels_1_bool: 'and',
    host_labels_1_vs_count: '1',
    host_labels_1_vs_indexof_1: '1',
    host_labels_1_vs_1_bool: 'and',
    view_name: 'searchhost',
    _show_filter_form: '1',
  });

  hostTagParams.forEach((tag, index) => {
    startUrlParams.set(`host_tag_${index}_grp`, tag.group);
    startUrlParams.set(`host_tag_${index}_op`, 'is');
    startUrlParams.set(`host_tag_${index}_val`, tag.value);
  });

  startUrlParams.set('host_tag_2_op', 'is');

  const encodedStartUrl = encodeURIComponent(`/${siteName}/check_mk/view.py?${startUrlParams.toString()}`);
  const finalUrl = `${siteUrl[siteName]}/${siteName}/check_mk/index.py?start_url=${encodedStartUrl}`;

  return finalUrl;
}

export {
  buildHostUrl,
  buildUrlDatacenterDevice,
  buildUrlDatacenterEnlace,
  buildUrlHostProblems,
  buildUrlWithHostTags,
  buildUrlHostService,
};
