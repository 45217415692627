import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder.addTextInput({
    path: 'orderColumns',
    name: 'Columns by type',
    description: 'Array with the order of columns',
    defaultValue: 'array',
  }).addTextInput({
    path: 'enlacesSpeed',
    name: 'Speed by enlace',
    description: 'Array with the speed of the enlace',
    defaultValue: "[]",
  }).addTextInput({
    path: 'enlace-critical-threshold',
    name: 'Overloaded threshold',
    description: 'Overloaded enlace speed',
    defaultValue: "85",
  }).addTextInput({
    path: 'enlace-unused-threshold',
    name: 'Unused threshold',
    description: 'Unused enlace speed',
    defaultValue: "5",
  }).addTextInput({
    path: 'services_relationships',
    name: 'Relacion entre nombres de servicios nagios y servicios en dashboard',
    description: 'El formato es: <Nombre de host>,<Nombre de servicio en nagios>,<Nombre de padre en dashboard>,<Nombre de item en dashboard>',
    defaultValue: '',
    settings: {
      useTextarea: true,
    },
  }).addTextInput({
    path: 'json_structure_panel_ejecutivo',
    name: 'Estructura dashboard panel_ejecutivo',
    description: 'Estructura dashboard panel_ejecutivo',
    defaultValue: '',
    settings: {
      useTextarea: true,
    },
  });
  // return builder.addTextInput({
  //   path: 'enlacesSpeed',
  //   name: 'Speed by enlace',
  //   description: 'Array with the speed of the enlace',
  //   defaultValue: "[]",
  // });
});
