import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
// @ts-ignore
import { SimplePanel } from './components/SimplePanel.tsx';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'json_structure_lims',
      name: 'Estructura dashboard lims',
      description: 'Estructura dashboard lims',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    })
    .addTextInput({
      path: 'services_lims_relationships',
      name: 'Relacion entre nombres de servicios nagios y servicios en dashboard',
      description: 'El formato es: <Nombre de host>,<Nombre de servicio en nagios>,<Nombre de servicio en dashboard>',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    })
    .addTextInput({
      path: 'device_by_group',
      name: 'Relacion entre nombres de grupos y dispositivos',
      description:
        'El formato es: <Nombre de grupo>;<Nombre de dispositivo en PRTG>;<Jerarquia de grupos al que pertenece>',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    });
});
