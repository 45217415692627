import React from 'react';
import './FiberLink.css';
import Group from '../../img/Group.png';
import { getStatusColorByType } from '../../utils/color';

function FiberLink({ from, to , status}) {
  return (
    <div className="fiber-link">
        <div className='link'>
            <img src={Group}></img>
            <div className='dashed-link'></div>
            <div className='status' style={{background: getStatusColorByType(status)}}></div>
            <div className='dashed-link'></div>
            <img src={Group}></img>
        </div>
        <div className='info-container'>
            <div className='info'>
                <div className='status' style={{background: getStatusColorByType(status)}}></div>
                {from}
            </div>
            <div className='info'>
                <div className='status' style={{background: getStatusColorByType(status)}}></div>
                {to}
            </div>
        </div>
    </div>
  );
}

export default FiberLink;
