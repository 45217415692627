import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import WhiteContainer from '../../components/WhiteContainer/WhiteContainer';
import CardInfo from '../../components/CardInfo/CardInfo';
import "./PanelEjecutivo.css"

function PanelEjecutivo({style, hosts, wraps}) {

    function getContainer(name, two_columns = false){
        const wrap = wraps[name];
        const content = wrap.items.map((item) => {
            if(item.status_card){
                return <CardInfo title={item.title} status={item.status} status_card={item.status_card} status_card_title={item.status_card_title} label={item.label}></CardInfo>
            }
            else{
                return <CardInfo title={item.title} status={item.status} label={item.label}></CardInfo>
            }
        });
        const row = wrap.row;
        
        return <WhiteContainer title={name} style={wrap.style} content={content} row = {row} two_columns = {two_columns} status_card={wrap.status_card} status_card_title={wrap.status_card_title}></WhiteContainer>
    }

    return (
        <section className="panel-view" style={style}>
            <Toolbar title={`Panel Ejecutivo`} />
            <div className="body">
                <div className='col1'>
                    <div className='row'>
                        {getContainer("Firewall y Balanceadores")}
                        {getContainer("Servidores")}
                        {getContainer("Servicios Consorcio")}
                    </div>
                    <div className='row'>
                        {getContainer("Monitoreo Sintético", true)}
                        {getContainer("Monitoreos")}
                    </div>
                    <WhiteContainer title={"Status URL"} style={{width: "100%", height:"inherit"}}></WhiteContainer>
                    
                </div>
                <div className='col2'>
                    {getContainer("Sucursales")}
                    {getContainer("VPN Site to Site")}
                    {getContainer("Enlace internet el bosque")}
                    <div className='row'>
                        {getContainer("Monitoreo PAM")}
                        {getContainer("Enlaces")}
                    </div>
                </div>
                <div className='col3'>
                    {getContainer("Sitio Central")}
                    <WhiteContainer title={"Enlaces 2"} style={{width: "100%", height: "inherit"}}></WhiteContainer>
                </div>
            </div>
        </section>
    )
}

export default PanelEjecutivo;