export function removeCircularReferences(obj, seen = new WeakSet()) {
  if (obj && typeof obj === 'object') {
    if (seen.has(obj)) {
      return '[Circular]';
    }
    seen.add(obj);
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = removeCircularReferences(obj[key], seen);
      }
    }
    seen.delete(obj);
  }
  return obj;
}

export function processData(data) {
  const series = {};
  data.series.forEach((serie) => {
    if (serie.fields && serie.name && serie.name.includes(':')) {
      serie.fields.forEach((field) => {
        const values = field.values.length ? field.values : [];
        const [category, host] = serie.name.split(':');
        if (!series[category]) {
          series[category] = {};
        }
        if (!series[category][host]) {
          series[category][host] = [];
        }
        values.forEach((value, index) => {
          if (!series[category][host][index]) {
            series[category][host].push({});
          }
          if (field.name === 'children') {
            series[category][host][index][field.name] = JSON.parse(value);
          } else {
            series[category][host][index][field.name] = value;
          }
        });
      });
    }
  });
  return JSON.parse(JSON.stringify(series));
}

export function getHostData(hosts){
  return hosts;
}