import React from 'react';
import { color_status } from '../../utils/dictionaryStates';

import './CardVmware.css';

function CardToco({ host, cpu, memory, disk_c, disk_e = undefined, size = undefined }) {
    if(disk_e === undefined){
        size = { col2: "100px",col3: "100px",col4: "110px"}
    }
    else{
        size = {col1: "70px", col2: "100px",col3: "60px",col4: "80px"}
    }
    return (
    <section className="card-vmware-cmpt" style={{ border: `1px solid ${color_status[host.status]}` }}>
        <div className="item host" style={{ borderRight: `2px solid ${color_status[host.status]}` }}>
        {/* <div className="status" style={{ background: color_status[host.status] }} /> */}
        <div className="label-vmware">{host.name}</div>
        </div>
        {/* <div className="item" style={{width: '120px'}}> */}
        <div className="item" style={size.col1 ? {  width: size.col1 }: {display: "none"}}>
        <div className="label-vmware">Disk E</div>
        <div className="status" style={disk_e  && { background: color_status[disk_e.status] }} />
        </div>
        <div className="item" style={{ width: size.col2 }}>
        <div className="label-vmware">Disk C</div>
        <div className="status" style={{ background: color_status[disk_c.status] }} />
        </div>
        <div className="item" style={{ width: size.col3 }}>
        <div className="label-vmware">Cpu</div>
        <div className="status" style={{ background: color_status[cpu.status] }} />
        </div>
        <div className="item" style={{ width: size.col4 }}>
        <div className="label-vmware">Memory</div>
        <div className="status" style={{ background: color_status[memory.status] }} />
        </div>
        {/* <div className="item">
        <div className="label-vmware"></div>
        <div className="status-vmware"></div>
        </div> */}
    </section>
    );
}

export default CardToco;