import React, { createContext, useContext, useState, useEffect } from 'react';

interface ThemeContextType {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const storedTheme = localStorage.getItem('darkMode') === 'true';
  const [isDarkMode, setIsDarkMode] = useState(storedTheme);

  useEffect(() => {
    localStorage.setItem('darkMode', String(isDarkMode));
    // const pagePanes = document.querySelector(".css-18a2o3k-page-panes");
    const pageWrapper = document.querySelector('[class^="css-"][class*="page-wrapper"]');
    if (pageWrapper) {
      pageWrapper.setAttribute(
        "style",
        `background-color: ${isDarkMode ? "black" : "white"} !important;`
      );
    }
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode((prev) => !prev);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};
