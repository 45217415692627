// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pod{
    display: flex;
    background: white;
    margin: 10px 0px;
    border: dashed 2px #2396ec;
    border-radius: 5px;
    align-items: center;
    padding: 5px;
    position: relative;
}

.pod img{
    height: 40px;
}

.pod .pod-title{
    font-size: 14x;
    color: grey;
}`, "",{"version":3,"sources":["webpack://./components/K8sPod/K8sPod.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,0BAA0B;IAC1B,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,WAAW;AACf","sourcesContent":[".pod{\n    display: flex;\n    background: white;\n    margin: 10px 0px;\n    border: dashed 2px #2396ec;\n    border-radius: 5px;\n    align-items: center;\n    padding: 5px;\n    position: relative;\n}\n\n.pod img{\n    height: 40px;\n}\n\n.pod .pod-title{\n    font-size: 14x;\n    color: grey;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
