import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import CardInfoRedes from 'components/CardInfoRedes/CardInfoRedes';
import Sidebar from 'components/Sidebar/Sidebar';
import GeographicMap from 'components/GeographicMap/GeographicMap';
//@ts-ignore
import TableAlertEnlace from 'components/TableAlertEnlace/TableAlertEnlace';
//@ts-ignore
import DatacenterTable from 'components/DatacenterTable/DatacenterTable';
//@ts-ignore
import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';
// import TooltipProblems from 'components/TooltipProblems/TooltipProblems';
//@ts-ignore
import CardNetworkDevice from 'components/CardNetworkDevice/CardNetworkDevice';
//@ts-ignore
import CardServiceCritical from 'components/CardServiceCritical/CardServiceCritical';
//@ts-ignore
import {
  getHostGroupsByLabel,
  getLinksByProviders,
  getLinksByProvidersDatacenter,
  getHostDetailForNetworkPanel,
  //@ts-ignore
} from '../../api/datasource.js';
import { analizeLink, getSaturationForDatacenter } from '../../api/link';

import { getRegionStatusByStores, getStoresForGraphic, getRegionStatusByStoresForGraphic } from '../../api/store';

import { getStatusColorByNumber } from '../../utils/color';
// import CustomTooltip from 'components/Tooltip/Tooltip';

import {
  countryDataTmp,
  dictBOCDADM,
  dictComunas,
  dictNames,
  enlacesTroncales,
  //@ts-ignore
} from './utils/database.js';

import './NetworkPanel.css';

type Props = {
  hosts: any;
  servicesCpuMem: any[];
  siteUrl: any;
  responseServiceLinks: any;
  hostServices: any;
};

const NetworkPanel: React.FC<Props> = ({
  hosts = [],
  servicesCpuMem = [],
  siteUrl,
  responseServiceLinks = [],
  hostServices = [],
}) => {
  const [countryData, setCountryData] = useState<any[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<any[]>([countryDataTmp[0]]);
  const [arrayRegionStatus, setArrayRegionStatus] = useState<any[]>([]);
  const [storeLinks, setStoreLinks] = useState<any[]>([]);
  const [storeInterfaces, setStoreInterfaces] = useState<any[]>([]);
  const [storeCpuMem, setStoreCpuMem] = useState<any[]>([]);
  const [storesCd, setStoresCd] = useState<any>(null);
  const [filterStoreLinks, setFilterStoreLinks] = useState<any>({ brand: [], code: [] });
  const [toFilterStoreLinks, setToFilterStoreLinks] = useState<any>({ brand: [], code: [] });
  const [storeLinksItems, setStoreLinksItems] = useState<any>([]);
  const [showSectionStoreStatus, setShowSectionStoreStatus] = useState<Boolean>(false);
  const [regionSelected, setRegionSelected] = useState<String>('');

  // Migrated States
  const [servicesLinks, setServicesLinks] = useState<any[]>([]);
  const [interfaces, setInterfaces] = useState<any[]>([]);
  const [providerLinksbyCountry, setProviderLinksbyCountry] = useState({ CHILE: {} });
  const [servicesLinksTroncales, setServicesLinksTroncales] = useState<any>({ CHILE: [] });
  const [servicesLinksStores, setServicesLinksStores] = useState<any>({ CHILE: [] });
  const [hostsForNetwork, setHostsForNetwork] = useState<any[]>([]);
  // const deviceTypesStores = getHostGroupsByLabel(devicesStores, 'cmk/device_type');
  // const deviceTypesBOCDADM = getHostGroupsByLabel(devicesBOCDADM, 'cmk/device_type');
  const [deviceTypesStores, setDeviceTypesStores] = useState<any[]>([]);
  const [deviceTypesBOCDADM, setDeviceTypesBOCDADM] = useState<any[]>([]);
  const [downloadStores, setDownloadStores] = useState<any[]>([]);
  const [downloadCDBOADM, setDownloadCDBOADM] = useState<any[]>([]);

  function loadDashboard(responseServiceLinks: any[]) {
    const hostsWithDetailsForNetwork = getHostDetailForNetworkPanel(hosts, hostServices);
    setHostsForNetwork(hostsWithDetailsForNetwork);
    const services_links_filtered: any[] = [];
    const services_links_store: any[] = []; //servicesLinks
    const services_links_troncales: any[] = [];

    responseServiceLinks.forEach((sl: any) => {
      if (sl.description.includes('CS:') && sl.description.includes('Interface')) {
        services_links_store.push(sl);
      }
      if (
        enlacesTroncales.some((enlT: any) => {
          const [hname, ip, sname, proveedor, dc] = enlT.split(';');
          sl['ip'] = ip;
          sl['dc'] = dc;
          sl['prov'] = proveedor;
          return sname === sl.description && sl.host_name === hname;
        })
      ) {
        services_links_troncales.push(sl);
      }
      // if (sl.description.includes('TLCO:') && !sl.description.includes('BW:')) {
      if (sl.description.includes('TLCO:')) {
        services_links_filtered.push(sl);
      }
    });
    setServicesLinksTroncales({ CHILE: services_links_troncales });

    const linksStore = getLinksByProvidersDatacenter(hosts, services_links_store, 'CHILE');
    const linksStoreArgentina = getLinksByProvidersDatacenter(hosts, services_links_store, 'ARGENTINA');
    setServicesLinksStores({ CHILE: linksStore, ARGENTINA: linksStoreArgentina, BRASIL: {}, COLOMBIA: {}, PERU: {} });
    // console.log('entra 2');
    const providersByCountry = getLinksByProviders(hosts, services_links_filtered);
    // console.log('entra 3');
    setProviderLinksbyCountry(providersByCountry.CHILE ? providersByCountry : { CHILE: {} });
    setServicesLinks(services_links_filtered);
    setInterfaces(services_links_store);
    // console.log('entra 4');
  }

  useEffect(() => {
    loadDashboard(responseServiceLinks);
  }, [responseServiceLinks, hosts]);

  function configureData() {
    let hostsFiltered: any[] = [];
    let hostsFilteredForGraphic: any[] = [];

    function filterHostsByCountry(hosts: any[], country: any, dictNames: any, dictBOCDADM: any) {
      return hosts.filter((host: any) => {
        const site = host.tags.SITE.toLowerCase();
        const countryName = country.country.toLowerCase();
        const isSiteMatching = site === countryName || dictNames[site] === countryName;
        // const isNotExcluded = !dictBOCDADM[countryName]?.some((hostname: any) => host.name.includes(hostname));
        const isNotExcluded = !inBOCDADM(host);
        return isSiteMatching && isNotExcluded;
      });
    }

    selectedCountries.forEach((country: any) => {
      // hostsFiltered = filterHostsByCountry(hosts, country, dictNames, dictBOCDADM);
      hostsFiltered = filterHostsByCountry(hosts, country, dictNames, []);
      // hostsFilteredForGraphic = filterHostsByCountry(hostsForNetwork, country, dictNames, dictBOCDADM);
      hostsFilteredForGraphic = filterHostsByCountry(hostsForNetwork, country, dictNames, []);
    });

    servicesLinks.forEach((link: any) => {
      const host = hostsFiltered.find((host: any) => host.name === link.host_name);
      if (host) {
        link.host = host;
      }
      link.data = getSaturationForDatacenter(link.plugin_output);
    });

    interfaces.forEach((link: any) => {
      const host = hostsFiltered.find((host: any) => host.name === link.host_name);
      if (host) {
        link.host = host;
      }
      link.data = analizeLink(link);
    });

    const linksWithProblems = servicesLinks.filter((link: any) => {
      return link.host;
    });
    linksWithProblems.sort((a: any, b: any) => {
      return b.priority - a.priority;
    });

    const interfacesWithProblems = interfaces.filter((link: any) => {
      return link.host_name.toLowerCase().includes('sw') && link.tienda !== 'DATACENTER';
    });
    console.log(interfacesWithProblems);

    interfacesWithProblems.sort((a: any, b: any) => {
      return b.priority - a.priority;
    });

    const hostStoreCpuMem: any = {};
    const auxHostCpuMem: any = {};

    servicesCpuMem.forEach((service: any) => {
      if (!auxHostCpuMem[service.host_name]) {
        auxHostCpuMem[service.host_name] = {
          host: service.host_name,
          problems: 0,
        };
      }
      if (service.state !== 0) {
        auxHostCpuMem[service.host_name].problems++;
      }
      if (service.description.toLowerCase().includes('temperature')) {
        auxHostCpuMem[service.host_name]['temperature'] = service;
      } else if (service.description.toLowerCase().includes('memory')) {
        auxHostCpuMem[service.host_name]['memory'] = service;
      } else if (service.description.toLowerCase().includes('cpu')) {
        auxHostCpuMem[service.host_name]['cpu'] = service;
      }
    });

    hostsFiltered.forEach((htFlt: any) => {
      if (auxHostCpuMem[htFlt.name]) {
        hostStoreCpuMem[htFlt.name] = auxHostCpuMem[htFlt.name];
        hostStoreCpuMem[htFlt.name]['host'] = htFlt;
      }
    });

    const storesGraphic = getStoresForGraphic(hostsFilteredForGraphic);
    // console.log(storesGraphic);
    const regionsGraphic = getRegionStatusByStoresForGraphic(storesGraphic);
    setArrayRegionStatus(regionsGraphic);
    // console.log(regionsGraphic);

    const stores = getStoresForGraphic(hostsFiltered);
    const regions = getRegionStatusByStores(stores);
    let globalStatus = 0;
    regions.forEach((region: any) => {
      globalStatus = globalStatus < region.status ? region.status : globalStatus;
    });
    countryDataTmp[0].generalStatus = globalStatus;

    setStoreLinks(JSON.parse(JSON.stringify(linksWithProblems)));
    setStoreInterfaces(interfacesWithProblems);
    setStoreCpuMem(Object.values(hostStoreCpuMem).filter((store: any) => store.problems > 0));
    setCountryData(countryDataTmp);
  }

  function getSuppliers() {
    return Object.entries(providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]).map(
      ([supplier, services]: any, index: any) => {
        const problems = services.filter((service: any) => service.state !== 0);
        let acknowledged = 0;
        let status = 0;
        problems.forEach((problem: any) => {
          if (problem.acknowledged) {
            acknowledged += 1;
          } else if (status < problem.state) {
            status = problem.state;
          }
        });
        const services_amount = { sv_total: services.length, pr_total: problems.length, ack_total: acknowledged };
        return (
          <div key={`supplier-${index}`} className="each-supplier">
            <CardInfoRedes
              status={0}
              name={supplier.toUpperCase()}
              label={'Enlaces Caídos'}
              bodyLabel={'Atendidos / Pendientes'}
              info={`LALALA`}
              totals={services_amount}
              problems={problems}
            />
          </div>
        );
      }
    );
  }

  function setCountry(country: string) {
    const countryDataTmp = countryData.map((countryItem: any) => {
      if (countryItem.country === country) {
        countryItem.selected = true;
      } else {
        countryItem.selected = false;
      }
      return countryItem;
    });
    const selected = countryDataTmp.filter((countryItem: any) => countryItem.selected);
    setCountryData(countryDataTmp);
    setSelectedCountries(selected);
  }

  function getResumeItems() {
    const resumeCD = {
      up: 0,
      down: 0,
      problems: [],
    };
    const resumeStores = {
      up: 0,
      down: 0,
      problems: [],
    };
    selectedCountries.forEach((country: any, index: any) => {
      const hostsFiltered = hosts.filter((host: any) => {
        return host.tags.SITE === country.country.toUpperCase();
      });
      const allStores = getStoresForGraphic(hostsFiltered);
      let countWithRegion = 0;
      let countWithoutRegion = 0;
      allStores.forEach((eachStore) => {
        if (eachStore.region !== '') {
          countWithoutRegion += 1;
        } else {
          countWithRegion += 1;
        }
      });
      const stores = {
        total: 0,
        down: [],
        withProblems: [],
        withUptimeProblems: [],
      };
      const cd = {
        total: 0,
        down: [],
        withProblems: [],
        withUptimeProblems: [],
      };
      allStores.forEach((store: any) => {
        let source: any = stores;
        if (store.name === 'ADM' || store.name === 'BODEGA' || store.name === 'BO' || store.name === 'CD') {
          source = cd;
        }
        if (store.state === 2) {
          source.down.push(store);
          source.withProblems.push(store);
        }
        if (store.criticity > 0) {
          source.withProblems.push(store);
        }
        if (store.hasUptimeProblems) {
          source.withUptimeProblems.push(store);
        }
        source.total += 1;
      });
      resumeCD.down += cd.down.length;
      resumeCD.up += cd.total - cd.down.length;
      resumeCD.problems = cd.withProblems;
      resumeStores.down += stores.down.length;
      resumeStores.up += stores.total - stores.down.length;
      resumeStores.problems = stores.withProblems;
    });
    return { resumeCD, resumeStores };
  }

  function getCardNetworkDevice(deviceTypes: any, fieldStores: string, title: string) {
    const validTypes = ['router', 'switch', 'access_point', 'firewall'];

    const arrayDeviceByType = deviceTypes.filter((deviceType: any) => {
      return validTypes.some((validType: string) => deviceType.key.includes(validType));
    });

    const arrayNew: any[] = [];

    arrayDeviceByType.forEach((deviceType: any) => {
      let types = validTypes.find((type) => deviceType.key.includes(type));
      if (arrayNew.some((element) => element.key === types)) {
        let index = arrayNew.findIndex((element) => element.key === types);
        arrayNew[index].hostProblems += deviceType.hostProblems;
        arrayNew[index].hostTotal += deviceType.hostTotal;
        arrayNew[index].problems = [...arrayNew[index].problems, ...deviceType.problems];
        arrayNew[index].serviceProblems += deviceType.serviceProblems;
        arrayNew[index].serviceTotal += deviceType.serviceTotal;
        arrayNew[index].serviceTotalCritical += deviceType.serviceTotalCritical;
        arrayNew[index].serviceTotalWarning += deviceType.serviceTotalWarning;
      } else {
        let newObj = {
          key: types,
          hostProblems: deviceType.hostProblems,
          hostTotal: deviceType.hostTotal,
          problems: deviceType.problems,
          serviceProblems: deviceType.serviceProblems,
          serviceTotal: deviceType.serviceTotal,
          serviceTotalCritical: deviceType.serviceTotalCritical,
          serviceTotalWarning: deviceType.serviceTotalWarning,
        };
        arrayNew.push(newObj);
      }
    });
    arrayNew.sort((a: any, b: any) => a.key.localeCompare(b.key));
    const problemsStores: any[] = [];
    if (storesCd && storesCd[fieldStores]) {
      storesCd[fieldStores]['problems'].forEach((prSt: any) => {
        problemsStores.push({ name: prSt.name, codeStore: prSt.code });
      });
      arrayNew.unshift({
        key: title,
        hostProblems: storesCd[fieldStores]['down'],
        hostTotal: storesCd[fieldStores]['down'] + storesCd[fieldStores]['up'],
        problems: problemsStores,
      });
    }

    return arrayNew.map((deviceGroup: any, index: any) => (
      <CardNetworkDevice
        key={`${index}-device-type`}
        title={deviceGroup.key}
        label={'DOWN / UP'}
        down={deviceGroup.hostProblems}
        up={parseInt(`${deviceGroup.hostTotal - deviceGroup.hostProblems}`, 10)}
        color={deviceGroup.hostProblems === 0 ? '#10D884' : '#ED4C5C'}
        problems={deviceGroup.problems}
        siteUrl={siteUrl}
      />
    ));
  }

  function inBOCDADM(host: any) {
    if (
      (host.labels['cmk/tienda'] && host.labels['cmk/tienda'] === 'centro_distribucion') ||
      (host.labels['cmk/tienda'] && host.labels['cmk/tienda'] === 'administracion') ||
      (host.labels['cmk/tienda'] && host.labels['cmk/tienda'] === 'bodega')
    ) {
      return true;
    }
    return false;
  }

  function getItemsStoreLinksItems() {
    const arrayStoreLinksCmpt: any = [];
    const simpleArrayFilterBrand: any = [];
    const simpleArrayFilterCode: any = [];

    filterStoreLinks['code'].forEach((filter: any) => {
      simpleArrayFilterCode.push(filter.value);
    });
    filterStoreLinks['brand'].forEach((filter: any) => {
      simpleArrayFilterBrand.push(filter.value);
    });
    // console.log(storeLinksItems);

    Object.values(storeLinksItems).forEach((store: any, index: any) => {
      if (
        simpleArrayFilterBrand.some((filter: any) => filter === store.brand) &&
        simpleArrayFilterCode.some((filter: any) => filter === store.code)
      ) {
        arrayStoreLinksCmpt.push(store);
      }
    });
    const getMaxPercentage = (store: any) => {
      return Math.max(
        ...[0, 1, 2, 3].map((i) => {
          const link = store.links[i]?.data;
          if (!link) {
            return 0;
          }
          return Math.max(link.inPercentage || 0, link.outPercentage || 0);
        })
      );
    };

    arrayStoreLinksCmpt.sort((a: any, b: any) => {
      const maxA = getMaxPercentage(a);
      const maxB = getMaxPercentage(b);
      return maxB - maxA;
    });
    const sortedRows = arrayStoreLinksCmpt.map((store: any, index: any) => {
      return (
        <tr key={`${index}-item`}>
          <td> {store.brand} </td>
          <td> {store.code} </td>
          {[0, 1, 2, 3].map((i) => (
            <td
              key={`${index}-link-${i}`}
              style={{
                color: getStatusColorByNumber(
                  store.links[i]?.data?.inState > store.links[i]?.data?.outState
                    ? store.links[i]?.data?.inState
                    : store.links[i]?.data?.outState
                ),
              }}
            >
              {store.links[i]
                ? `${store.links[i]?.data?.inPercentage || 0}% | ${store.links[i]?.data?.outPercentage || 0}%`
                : ''}
            </td>
          ))}
        </tr>
      );
    });
    return sortedRows;
  }

  function parseToJSON(text: string) {
    const cleanText = text.replace(/[\*\*]/g, '');
    const parts = cleanText.split('_');
    const result: any = {};
    parts.forEach((part: any) => {
      const [key, ...valueParts] = part.split(':');
      result[key] = valueParts.join(':');
    });
    return result;
  }

  function getStoreLinksTable() {
    const statePerformanceStores: any = {
      brand: [],
      code: [],
    };

    const storesLinks: any = {};
    selectedCountries.forEach((country: any, index: any) => {
      storeLinks.forEach((link: any) => {
        if (
          link.host.tags.SITE.toLowerCase() === country.country.toLowerCase() ||
          dictNames[link.host.tags.SITE.toLowerCase()] === country.country.toLowerCase()
        ) {
          if (!storesLinks[link.host.tags.CODIGO_DE_TIENDA]) {
            storesLinks[link.host.tags.CODIGO_DE_TIENDA] = {
              brand: link.host.tags.TIENDA,
              code: link.host.tags.CODIGO_DE_TIENDA,
              links: [],
            };
          }
          storesLinks[link.host.tags.CODIGO_DE_TIENDA].links.push(link);
        }
      });
    });
    const storeEnlaces: any = {};
    for (const storeName in storesLinks) {
      const storeInfo = storesLinks[storeName];
      const copyLinks = storeInfo.links;
      storeInfo.links.forEach((link: any) => {
        if (!link.description.includes('Interface')) {
          const jsonName = parseToJSON(link.description);
          const interfaceByEnlace = copyLinks.find(
            (eachlink: any) =>
              eachlink.description.includes(jsonName['CS']) &&
              eachlink.description.includes('Interface') &&
              eachlink.host_name === link.host_name
          );
          if (interfaceByEnlace) {
            if (!storeEnlaces[storeName]) {
              storeEnlaces[storeName] = {
                brand: storeInfo.brand,
                code: storeInfo.code,
                links: [],
              };
            }
            storeEnlaces[storeName]['links'].push({ ...interfaceByEnlace, state: link.state });
          }
        }
      });
    }
    Object.values(storeEnlaces).forEach((store: any, index: any) => {
      if (!statePerformanceStores['brand'].some((br: any) => br.value === store.brand)) {
        statePerformanceStores.brand.push({ value: store.brand, label: store.brand });
      }
      if (!statePerformanceStores['code'].some((br: any) => br.value === store.code)) {
        statePerformanceStores.code.push({ value: store.code, label: store.code });
      }
    });
    setStoreLinksItems(storeEnlaces);
    setFilterStoreLinks(statePerformanceStores);
    setToFilterStoreLinks(statePerformanceStores);
  }

  function getLinksTable() {
    const linksTable: any[] = [];
    selectedCountries.forEach((country: any, index: any) => {
      storeInterfaces.sort((a: any, b: any) => {
        const aValue = a.data?.errorsIn ?? 0;
        const bValue = b.data?.errorsIn ?? 0;
        return bValue - aValue;
      });
      const provs: any[] = [];
      storeInterfaces.forEach((link) => {
        if (!provs.some((pr) => pr === link.provider)) {
          provs.push(link.provider);
        }
        if (
          link.country &&
          (link.country.toLowerCase() === country.country.toLowerCase() ||
            dictNames[link.country.toLowerCase()] === country.country.toLowerCase())
        ) {
          linksTable.push(
            <tr key={`${index}-item`}>
              <td> {link.provider} </td>
              <td> {link.host?.tags?.TIENDA}</td>
              <td> {link.host?.tags?.CODIGO_DE_TIENDA}</td>
              <td> {link.host_name}</td>
              <td style={{ color: getStatusColorByNumber(link.data?.errorsInState) }}>
                {' '}
                {link.data?.errorsIn?.toFixed(2)}%
              </td>
            </tr>
          );
        }
      });
    });
    return linksTable;
  }

  function getImportantServicesTable() {
    storeCpuMem.forEach((device: any) => {
      if (device['cpu'] && device['cpu'].plugin_output.includes('minutes:')) {
        device['cpu'].percentage = parseFloat(device['cpu'].plugin_output.split('minutes: ')[1]) || 0;
      }
      if (device['memory'] && device['memory'].plugin_output.includes('Usage:')) {
        device['memory'].percentage = parseFloat(device['memory'].plugin_output.split('Usage: ')[1].split('%')[0]) || 0;
      }
      if (device['temperature'] && device['temperature'].plugin_output.includes('Temperature:')) {
        device['temperature'].percentage =
          parseFloat(device['temperature'].plugin_output.split('Temperature: ')[1].split(' ')[0]) || 0;
      }
      device.maxMetric = Math.max(
        device?.cpu?.percentage || 0,
        device?.memory?.percentage || 0,
        device?.temperature?.percentage || 0
      );
    });

    storeCpuMem.sort((a, b) => b.maxMetric - a.maxMetric);

    return storeCpuMem.map((device: any, index: any) => {
      return (
        <tr key={`${index}-item`}>
          <td>{device.host.tags.TIENDA}</td>
          <td>{device.host.tags.CODIGO_DE_TIENDA}</td>
          <td>{device.host.name}</td>
          <td>{device?.cpu?.percentage}</td>
          <td>{device?.memory?.percentage}</td>
          <td>{device?.temperature?.percentage}</td>
          <td></td>
        </tr>
      );
    });
  }

  function createLinkItem(slt: any, type: string) {
    const extraDataLink = analizeLink(slt);
    return {
      type,
      hostname: slt.host_name,
      description: slt.description,
      plugin_output: slt.plugin_output,
      name: slt.dc,
      status: slt.state === 0 ? 'Up' : 'Down',
      ip: slt.address || slt.ip,
      saturation: `${extraDataLink?.inPercentage.toFixed(1)}% | ${extraDataLink?.outPercentage.toFixed(1)}%`,
      crc: extraDataLink?.errorsIn?.toFixed(1),
      prov: slt.prov,
      maxMetric: Math.max(
        extraDataLink?.inPercentage || 0,
        extraDataLink?.outPercentage || 0,
        extraDataLink?.errorsIn || 0
      ),
    };
  }

  function processLinks(servicesLinks: any, country: string, type: string) {
    const items: any[] = [];
    const links = servicesLinks[country.toUpperCase()];
    if (links) {
      if (Array.isArray(links)) {
        links.forEach((slt: any) => items.push(createLinkItem(slt, type)));
      } else {
        for (const key in links) {
          const linkGroup = links[key];
          if (Array.isArray(linkGroup)) {
            linkGroup.forEach((slt: any) => items.push(createLinkItem(slt, type)));
          }
        }
      }
    }
    items.sort((a, b) => b.maxMetric - a.maxMetric);
    return items;
  }

  function getTroncalesItem() {
    // console.log(processLinks(servicesLinksTroncales, selectedCountries[0].country));
    return processLinks(servicesLinksTroncales, selectedCountries[0].country, 'troncal');
  }

  function getInternetItem() {
    // console.log(processLinks(servicesLinksStores, selectedCountries[0].country));
    return processLinks(servicesLinksStores, selectedCountries[0].country, 'internet');
  }

  function handleShowSectionStoreStatus(region: String) {
    setRegionSelected(region);
    setShowSectionStoreStatus(true);
  }

  function getHourWithFormate(last_state_change: number) {
    let hour;
    const currentDate = new Date(last_state_change * 1000);
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();
    let formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    let formattedTime =
      (hours < 10 ? '0' : '') +
      hours +
      ':' +
      (minutes < 10 ? '0' : '') +
      minutes +
      ':' +
      (seconds < 10 ? '0' : '') +
      seconds;
    hour = `${formattedDate} ${formattedTime}`;
    return hour;
  }

  function alertEnlaceItemsInfo() {
    const arrayProblems: any[] = [];
    let arrayRegionInfo = arrayRegionStatus.find((reg: any) => reg.region === regionSelected);
    if (arrayRegionInfo) {
      for (const key in arrayRegionInfo['serviceArrayProblems']) {
        const [store, codeStore] = key.split('-');
        const problemsByStore = arrayRegionInfo['serviceArrayProblems'][key];
        problemsByStore.forEach((prByStore: any) => {
          const com = getComuna(prByStore.host_name);
          arrayProblems.push({
            status: prByStore.state,
            lastChange: getHourWithFormate(prByStore.last_state_change),
            brand: store,
            code: codeStore,
            location: com,
            hostname: prByStore.host_name,
            ip: prByStore.ip,
            service: prByStore.description,
            details: prByStore.plugin_output,
            acknowledged: prByStore.acknowledged === 1 ? true : false,
            site: prByStore.site,
          });
        });
      }
      return arrayProblems;
    } else {
      return [];
    }
  }

  function getComuna(hostname: String) {
    for (const key of Object.keys(dictComunas)) {
      if (hostname.includes(key)) {
        return dictComunas[key];
      }
    }
    return null;
  }

  function alertEnlaceFilterInfo() {
    let brand: any[] = [];
    let comuna: any[] = [];
    let arrayRegionInfo = arrayRegionStatus.find((reg: any) => reg.region === regionSelected);
    if (arrayRegionInfo) {
      for (const key in arrayRegionInfo['serviceArrayProblems']) {
        const [store] = key.split('-');
        const problemsByStore = arrayRegionInfo['serviceArrayProblems'][key];
        problemsByStore.forEach((prByStore: any) => {
          const com = getComuna(prByStore.host_name);
          if (!brand.some((br: any) => br.value === store)) {
            brand.push({ label: store, value: store });
          }
          if (!comuna.some((br: any) => br.value === com)) {
            comuna.push({ label: com, value: com });
          }
        });
      }
      return [
        {
          filter: 'brand',
          placeholder: 'Tipo de tienda',
          options: brand,
        },
        {
          filter: 'location',
          placeholder: 'Comuna',
          options: comuna,
        },
      ];
    } else {
      return [];
    }
  }

  useEffect(() => {
    configureData();
    setSelectedCountries([countryDataTmp[0]]);
  }, [hosts]);

  useEffect(() => {
    configureData();
    if (selectedCountries && selectedCountries.length) {
      const { resumeCD, resumeStores } = getResumeItems();
      setStoresCd({ resumeCD, resumeStores });
    }

    const devicesStores: any[] = [];
    const devicesBOCDADM: any[] = [];
    selectedCountries.forEach((country: any) => {
      hosts.forEach((host: any) => {
        const isInBOCDADM = inBOCDADM(host);
        if (
          host.tags.SITE.toLowerCase() === country.country.toLowerCase() ||
          dictNames[host.tags.SITE.toLowerCase()] === country.country.toLowerCase()
        ) {
          if (isInBOCDADM) {
            devicesBOCDADM.push(host);
          } else {
            devicesStores.push(host);
          }
        }
      });
    });
    const dataToDownloadStores: any[] = [];
    const dataToDownloadBOCDADM: any[] = [];
    const deviceTypesStores = getHostGroupsByLabel(devicesStores, 'cmk/device_type');
    const deviceTypesBOCDADM = getHostGroupsByLabel(devicesBOCDADM, 'cmk/device_type');
    devicesStores.forEach((dv: any) => {
      dataToDownloadStores.push({
        name: dv.name,
        state: dv.state,
        device_type: dv.labels && dv.labels['cmk/device_type'] ? dv.labels['cmk/device_type'] : '',
      });
    });
    devicesBOCDADM.forEach((dv: any) => {
      dataToDownloadBOCDADM.push({
        name: dv.name,
        state: dv.state,
        device_type: dv.labels && dv.labels['cmk/device_type'] ? dv.labels['cmk/device_type'] : '',
      });
    });
    setDeviceTypesStores(deviceTypesStores);
    setDeviceTypesBOCDADM(deviceTypesBOCDADM);
    setDownloadStores(dataToDownloadStores);
    setDownloadCDBOADM(dataToDownloadBOCDADM);
  }, [selectedCountries]);

  useEffect(() => {
    getStoreLinksTable();
  }, [storeLinks, selectedCountries]);

  return (
    <section className="network-panel-cmpt">
      <div className="sidebar-network-panel">
        <Sidebar countryInfo={countryData} setCountryFn={setCountry} />
      </div>
      <CSVHocComponent
        personalizedClass={'supplier-wrap'}
        data={providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]}
      >
        <>
          <div className="title-supplier-wrap">{'Proveedores'}</div>
          {getSuppliers()}
        </>
      </CSVHocComponent>
      <CSVHocComponent
        personalizedClass={'geomap-wrap'}
        data={
          storesCd && storesCd['resumeStores']
            ? [...storesCd['resumeStores']['problems'], ...storesCd['resumeCD']['problems']]
            : []
        }
      >
        {/* <div className="geomap-wrap"> */}
        <div className="title-geomap">{'Geomap / Tiendas caidas'}</div>
        <div className="graphic-geomap">
          <div className="actions"></div>
          <div className="graphic-wrap">
            <GeographicMap
              chooseRegion={() => console.log()}
              regiones={arrayRegionStatus}
              info={''}
              regionNumber={[]}
              region={''}
              scale={1.75}
              country={selectedCountries[0].name.toLowerCase()}
              onSelectSectionStoreStatus={handleShowSectionStoreStatus}
              storeProblems={
                storesCd && storesCd['resumeStores']
                  ? [...storesCd['resumeStores']['problems'], ...storesCd['resumeCD']['problems']]
                  : []
              }
            />
          </div>
        </div>
        {/* </div> */}
      </CSVHocComponent>
      {showSectionStoreStatus ? (
        <TableAlertEnlace
          title={'Estado General de las tiendas'}
          arrayAlerts={alertEnlaceItemsInfo()}
          arrayFilters={alertEnlaceFilterInfo()}
          onClose={() => setShowSectionStoreStatus(false)}
          siteUrl={siteUrl}
        />
      ) : (
        <>
          <div className="active-alerts-wrap">
            <div className="performance-links-store">
              <div className="title-active-alerts-wrap">
                <span>Performance enlaces en tiendas</span>
                <div className="filters-alert">
                  <div className="filter-alert">
                    <span className="filter-title">BRAND - UNIDAD DE NEGOCIO</span>
                    <div className="filter-input">
                      <Select
                        options={toFilterStoreLinks['brand']}
                        closeMenuOnSelect={false}
                        isClearable
                        isMulti
                        onChange={(info, e) => {
                          const copyFilter = JSON.parse(JSON.stringify(filterStoreLinks));
                          copyFilter['brand'] = info.length ? info : toFilterStoreLinks['brand'];
                          setFilterStoreLinks(copyFilter);
                        }}
                      />
                    </div>
                  </div>
                  <div className="filter-alert">
                    <span className="filter-title">STORE - CÓDIGO</span>
                    <div className="filter-input">
                      <Select
                        options={toFilterStoreLinks['code']}
                        closeMenuOnSelect={false}
                        isClearable
                        isMulti
                        onChange={(info, e) => {
                          const copyFilter = JSON.parse(JSON.stringify(filterStoreLinks));
                          copyFilter['code'] = info.length ? info : toFilterStoreLinks['code'];
                          setFilterStoreLinks(copyFilter);
                        }}
                      />
                    </div>
                  </div>
                  <div className="filter-alert">
                    <span className="filter-title">ESTADO</span>
                    <div className="filter-input">
                      <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="active-problems" style={{ height: '200px' }}>
                <table className="store-problems-table">
                  <thead>
                    <tr>
                      <th style={{ width: '10%' }}>Brand</th>
                      <th style={{ width: '10%' }}>Código</th>
                      <th style={{ width: '20%' }}>Wan1</th>
                      <th style={{ width: '20%' }}>Wan2</th>
                      <th style={{ width: '20%' }}>Wan3</th>
                      <th style={{ width: '20%' }}>Wan4</th>
                    </tr>
                  </thead>
                  <tbody>{getItemsStoreLinksItems()}</tbody>
                </table>
              </div>
            </div>
            <div className="interfaces">
              <div className="title-active-alerts-wrap">
                <span>Interfaces (Enlaces / Uplink)</span>
                <div className="filters-alert">
                  <div className="filter-alert">
                    <span className="filter-title">BRAND - UNIDAD DE NEGOCIO</span>
                    <div className="filter-input">
                      <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                    </div>
                  </div>
                  <div className="filter-alert">
                    <span className="filter-title">STORE - CÓDIGO</span>
                    <div className="filter-input">
                      <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                    </div>
                  </div>
                  <div className="filter-alert">
                    <span className="filter-title">ESTADO</span>
                    <div className="filter-input">
                      <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="active-problems" style={{ height: '200px' }}>
                <table className="store-problems-table">
                  <thead>
                    <tr>
                      <th style={{ width: '15%' }}>Enlace</th>
                      <th style={{ width: '15%' }}>Brand</th>
                      <th style={{ width: '15%' }}>Código</th>
                      <th style={{ width: '40%' }}>Hostname</th>
                      <th style={{ width: '15%' }}>CRC</th>
                    </tr>
                  </thead>
                  <tbody>{getLinksTable()}</tbody>
                </table>
              </div>
            </div>
            <div className="vital-check">
              <div className="title-active-alerts-wrap">
                <span>Chequeos Vitales</span>
                <div className="filters-alert">
                  <div className="filter-alert">
                    <span className="filter-title">CATEGORIA - PROBLEMA</span>
                    <div className="filter-input">
                      <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                    </div>
                  </div>
                  <div className="filter-alert">
                    <span className="filter-title">STORE - CÓDIGO</span>
                    <div className="filter-input">
                      <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                    </div>
                  </div>
                  <div className="filter-alert">
                    <span className="filter-title">ESTADO</span>
                    <div className="filter-input">
                      <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="active-problems" style={{ height: '200px' }}>
                {
                  <table className="store-problems-table">
                    <thead>
                      <tr>
                        <th style={{ width: '15%' }}>Brand</th>
                        <th style={{ width: '15%' }}>Código</th>
                        <th style={{ width: '30%' }}>Equipo</th>
                        <th style={{ width: '10%' }}>CPU</th>
                        <th style={{ width: '10%' }}>MEM</th>
                        <th style={{ width: '10%' }}>FAN</th>
                        <th style={{ width: '10%' }}>PSU</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{getImportantServicesTable()}</tbody>
                  </table>
                }
              </div>
            </div>
          </div>
          <div className="network-status-wrap">
            <div className="store-status">
              <CSVHocComponent personalizedClass={'general-stores'} data={downloadStores}>
                {/* <div className="general-stores"> */}
                <div className="label-header">{'Estado general de tiendas'}</div>
                <div className="devices-wrapper">
                  {/* {getAlertsByGroup('resumeStores', 'Tiendas down', setDownloadStores)} */}
                  {getCardNetworkDevice(deviceTypesStores, 'resumeStores', 'Tiendas down')}
                </div>
                {/* </div> */}
              </CSVHocComponent>
              <CSVHocComponent personalizedClass={'general-cd_bo_adm'} data={downloadCDBOADM}>
                {/* <div className="general-cd_bo_adm"> */}
                <div className="label-header">{'Estado general CD - BO - ADM'}</div>
                <div className="devices-wrapper">
                  {/* {getAlertsByGroup('resumeCD', 'CD/BO/ADM down', setDownloadCDBOADM)} */}
                  {getCardNetworkDevice(deviceTypesBOCDADM, 'resumeCD', 'CD/BO/ADM down')}
                </div>
                {/* </div> */}
              </CSVHocComponent>
            </div>
            <CSVHocComponent
              personalizedClass={'datacenter-country'}
              data={[...getTroncalesItem(), ...getInternetItem()]}
            >
              {/* <div className="datacenter-country"> */}
              <div className="label-header">{'Datacenter país'}</div>
              <div className="filters-alert">
                <div className="filter-alert">
                  <span className="filter-title">DATACENTER</span>
                  <div className="filter-input">
                    <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                  </div>
                </div>
                <div className="filter-alert">
                  <span className="filter-title">ESTADO</span>
                  <div className="filter-input">
                    <Select options={[]} closeMenuOnSelect={false} isClearable isMulti onChange={() => {}} />
                  </div>
                </div>
              </div>
              <div className="tables">
                <div className="troncal">
                  <DatacenterTable customNameColum={'TRONCAL'} items={getTroncalesItem()} />
                </div>
                <div className="enlaces">
                  <DatacenterTable customNameColum={'INTERNET'} items={getInternetItem()} />
                </div>
              </div>
              {/* </div> */}
            </CSVHocComponent>
            <div className="services-country">
              <div className="title-services-country">{'Servicios criticos pais'}</div>
              <div className="first-line">
                <CardServiceCritical />
                <CardServiceCritical />
                <CardServiceCritical />
                <CardServiceCritical />
              </div>
              <div className="second-line">
                <CardServiceCritical />
                <CardServiceCritical />
                <CardServiceCritical />
                <CardServiceCritical />
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default NetworkPanel;
