// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-status-cmpt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  overflow-y: auto;
}

.table-status-cmpt .sensors {
  width: 100%;
  height: 35px;
  display: flex;
  border-bottom: 1px solid #e2e5ee;
  position: relative;
}

.table-status-cmpt .sensors .flag {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid white;
}

.table-status-cmpt .sensors .selected-group-table {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.39px;
  color: #63666e;
  margin-left: 12px;
}

.table-status-cmpt .sensors .arrow {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
}

.table-status-cmpt .sensors .arrow:hover {
  color: #3b7de0;
}
`, "",{"version":3,"sources":["webpack://./components/TableStatus/TableStatus.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".table-status-cmpt {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  border-radius: 8px;\n  overflow-y: auto;\n}\n\n.table-status-cmpt .sensors {\n  width: 100%;\n  height: 35px;\n  display: flex;\n  border-bottom: 1px solid #e2e5ee;\n  position: relative;\n}\n\n.table-status-cmpt .sensors .flag {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  border: 1px solid white;\n}\n\n.table-status-cmpt .sensors .selected-group-table {\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 16.39px;\n  color: #63666e;\n  margin-left: 12px;\n}\n\n.table-status-cmpt .sensors .arrow {\n  cursor: pointer;\n  position: absolute;\n  right: 10px;\n  top: 10px;\n  font-size: 18px;\n}\n\n.table-status-cmpt .sensors .arrow:hover {\n  color: #3b7de0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
