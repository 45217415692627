import React from 'react';
import './CircleContainer.css'; // Import the CSS file

const CircleContainer = ({style,  mainText, smallText}) => {
  return (
    <div className="circle-container" style={style}>
      <div className="circle">
        <div className="circle-text">
          {mainText}
        </div>
        <div className="circle-small-text">
          {smallText}
        </div>
      </div>
    </div>
  );
};

export default CircleContainer;
