import React from 'react';
import './DockerContainer.css';
import  {icons} from "../../utils/dictionaryIcons"
import  {color_status} from "../../utils/dictionaryStates"

function DockerContainer({status, style = undefined, content= undefined}) {
  return (
    <>
        <div className="docker-container" style={style}>
            <div className='docker-header'>
                <img src={icons["Docker"]}/>
               <div className='docker-status' style={{background: color_status[status]}}></div>
            </div>
            <div className='docker-content'>{content}</div>
        </div>
    </>
  );
}

export default DockerContainer;
