import React from 'react';
import './PercentageBar.css';

type Props = {
    percentage: number;
};

const PercentageBar: React.FC<Props> = ({ percentage }) => {
  return (
    <svg className="percentage-graphic" style={{ width: '90px', height: '11px' }}>
        <rect className="graphic-white" height= "100%" width= "100%"></rect>
        <rect className="graphic-gray" height= "100%"  width={ percentage + "%"}></rect>
        <text x="50%" y="70%" textAnchor="middle">{percentage}%</text>
    </svg>
  );
};

export default PercentageBar;

