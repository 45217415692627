export const main_components = [
    {type: "server", name: "Krusty", status: "UNK", position: {top: "120px", left: "520px"}, content:[
        {type: "docker", content:[
            {type: "container", icon: "BD", title: "DVU BD", content: [
                {type: "circle",mainText: "{JDBC}" , smallText: "port 5435", style: {top: "0px", right: "-35px"}}
            ]}
        ]},
    ]},
    {type: "server", name: "Barney", status: "UNK", position: {top: "70px", left: "130px"}, content:[
        {type: "docker", content:[
            {type: "container", icon: "DVU", title: "Authorization", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 8070", style: {top: "0px", right: "-35px"}}
            ]},
            {type: "container", icon: "MySQL", title: "BD - dpa_user"},
            {type: "container", icon: "DVU", title: "Userbackend", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 8075", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Patty", status: "UNK", position: {top: "120px", left: "830px"}, style: {width: "700px", height: "380px"} ,content: [
        {type: "docker", style: {width: "200px", position: "absolute", left: "300px"} ,content: [
            {type: "container", icon: "Redis", title: "", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 6379", style: {top: "0px", right: "-35px"}}
            ]}
        ]},
        {type: "dvu", title:"DVU Flow editor", status: "UNK", style: {position: "absolute", left: "0px", width: "200px"}, content:[
            {type: "circle", mainText: "{API}" , smallText: "port 9366", style: {top: "0px", right: "-35px"}}
        ]},
        {type: "dvu", title:"DVU File manager", status: "UNK", style: {position: "absolute", top: "60px", left: "0px", width: "200px"}, content: [
            {type: "circle", mainText: "{API}" , smallText: "port 9370", style: {top: "0px", right: "-35px"}}
        ]},
        {type: "dvu", title:"DVU Analytics", status: "UNK", style: {position: "absolute", top: "120px", left: "0px", width: "200px"}, content: [
            {type: "circle", mainText: "{API}" , smallText: "port 9368", style: {top: "0px", right: "-35px"}}

        ]},
        {type: "dvu", title:"DVU Dashboard", status: "UNK", style: {position: "absolute", top: "220px", left: "0px", width: "200px"}, content: [
            {type: "circle", mainText: "{API}" , smallText: "port 9631", style: {top: "0px", right: "-35px"}}

        ]},
        {type: "nginx", status: "UNK", style:{position: "absolute", top: "220px", right: "50px"}}
    ]},
    {type: "server", name: "Luann", status: "UNK", position: {top: "620px", left: "830px"}, style:{height: "620px", width: "400px", padding: "10px"}, content: [
        {type: "nginx", status: "UNK", style:{position: "absolute", top: "10px", left: "110px"}},
        {type: "k8s", status: "UNK", style:{position: "absolute", top: "110px"}, content: [
            {type: "k8spod", status: "UNK", icon: "Ingress", title: "INGRESS"},
            {type: "k8spod", status: "UNK", icon: "DVU", title: "DVU Manager"},
            {type: "k8spod", status: "UNK", icon: "DVU", title: "DVU Audit"}
        ]}
    ]},
    {type: "server", name: "Hyman", status: "UNK", position: {top: "620px", left: "1330px"}, style:{height: "620px", width: "400px", padding: "10px"}, content: [
        {type: "nginx", status: "UNK", style:{position: "absolute", top: "10px", left: "110px"}},
        {type: "k8s", status: "UNK", style:{position: "absolute", top: "110px"}, content:[
            {type: "k8spod", status: "UNK", icon: "DVU", title: "DVU Manager"},
            {type: "k8spod", status: "UNK", icon: "MongoDB", title: "Manager DB"},
        ]}
    ]},
    {type: "server", name: "Edna", hostName: "edna" , status: "UNK", position: {top: "620px", left: "1830px"}, style:{height: "620px", width: "400px", padding: "10px"}, content: [
        {type: "nginx", status: "UNK", style:{position: "absolute", top: "10px", left: "110px"}},
        {type: "k8s", status: "UNK", style:{position: "absolute", top: "110px"}, content:[
            {type: "k8spod", status: "UNK", icon: "IA", title: "DVU IA"},
        ]}
    ]},
    {type: "server", name: "Lenny", status: "UNK", position: {top: "1320px", left: "630px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Android", title: "Emulador"},
        ]}
    ]},
    {type: "server", name: "Jeff", status: "UNK", position: {top: "1320px", left: "930px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots APP"},
            {type: "container", icon: "Java", title: "Robots"},
            {type: "container", icon: "DVU", title: "DVU Video Converter", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Seymour", hostName: "seymour", status: "UNK", position: {top: "1320px", left: "1230px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
            {type: "container", icon: "DVU", title: "DVU Video Converter", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Santa", status: "UNK", position: {top: "1320px", left: "1530px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
            {type: "container", icon: "DVU", title: "DVU Video Converter", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Milhouse", status: "UNK", position: {top: "1320px", left: "1830px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
            {type: "container", icon: "DVU", title: "DVU Video Converter", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Todd", status: "UNK", position: {top: "1320px", left: "2130px"}, style:{width: "220px"},content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
            {type: "container", icon: "DVU", title: "DVU Video Converter", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Rod", hostName: "rod", status: "UNK", position: {top: "1320px", left: "2430px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
            {type: "container", icon: "DVU", title: "DVU Video Converter", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Julius", status: "UNK", position: {top: "1320px", left: "2730px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
            {type: "container", icon: "DVU", title: "DVU Video Converter", content: [
                {type: "circle",mainText: "{API}" , smallText: "port 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Carl", status: "UNK", position: {top: "1320px", left: "3030px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
        ]}
    ]},
    {type: "server", name: "Kent", status: "UNK", position: {top: "1320px", left: "3330px"}, style:{width: "220px"}, content: [
        {type: "docker", content: [
            {type: "container", icon: "Java", title: "Robots"},
        ]}
    ]},
]