import React from 'react';
import './AlertsTable.css';
function AlertsTable({ header_elements , height, children }) {
    
    const ths = header_elements.map((element) => 
        <div className='th' style={{width: element.width + "%"}}>{element.title}</div>
    )

    return (
        <div className="alert-div" style={{height: height + "px"}}>
            <div className='t-header'>
                {ths}
            </div>
            <div className='t-body' style={{height: height -13 + "px"}}>
                {children}
            </div>
        </div>
    );
}

export default AlertsTable;