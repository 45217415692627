// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoItemIndicadores-cmpt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  border: 1px dashed #d0dced;
  margin-top: 10px;
  padding: 5px 10px 0px 10px;
  font-family: Khula;
  font-weight: normal;
}

.infoItemIndicadores-cmpt .icon-infoitemIndicadores {
  width: 20px;
  height: 100%;
}

.infoItemIndicadores-cmpt .icon-infoitemIndicadores img {
  width: 100%;
  height: auto;
}

.infoItemIndicadores-cmpt .content-infoitemIndicadores {
  width: calc(100% - 40px);
  height: 100%;
  /* padding: 0 10px; */
  display: flex;
  flex-direction: column;
  /* position: relative; */
  align-items: flex-start;
}

.infoItemIndicadores-cmpt .content-infoitemIndicadores .name-infoitemIndicadores {
  font-size: 12px;
  line-height: 19.26px;
  color: #596c88;
  /* position: absolute; */
  top: -4px;
}


.infoItemIndicadores-cmpt .status-infoitemIndicadores {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./components/InfoItemIndicadores/InfoItemIndicadores.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,wBAAwB;EACxB,SAAS;AACX;;;AAGA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".infoItemIndicadores-cmpt {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n  border-radius: 2px;\n  border: 1px dashed #d0dced;\n  margin-top: 10px;\n  padding: 5px 10px 0px 10px;\n  font-family: Khula;\n  font-weight: normal;\n}\n\n.infoItemIndicadores-cmpt .icon-infoitemIndicadores {\n  width: 20px;\n  height: 100%;\n}\n\n.infoItemIndicadores-cmpt .icon-infoitemIndicadores img {\n  width: 100%;\n  height: auto;\n}\n\n.infoItemIndicadores-cmpt .content-infoitemIndicadores {\n  width: calc(100% - 40px);\n  height: 100%;\n  /* padding: 0 10px; */\n  display: flex;\n  flex-direction: column;\n  /* position: relative; */\n  align-items: flex-start;\n}\n\n.infoItemIndicadores-cmpt .content-infoitemIndicadores .name-infoitemIndicadores {\n  font-size: 12px;\n  line-height: 19.26px;\n  color: #596c88;\n  /* position: absolute; */\n  top: -4px;\n}\n\n\n.infoItemIndicadores-cmpt .status-infoitemIndicadores {\n  width: 15px;\n  height: 15px;\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
