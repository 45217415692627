import React, {useState,useEffect} from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import './SimplePanel.css'
import {
  processData,
  removeCircularReferences,
  getHostData,
// @ts-ignore
} from 'api/datasource.js'

// @ts-ignore
import {nagios_host} from 'utils/hostUtilNagios.js'
// @ts-ignore
import {icons} from "utils/dictionaryIcons.js"
// @ts-ignore
import {getFlujosSeguros} from "../api/datasource";
// @ts-ignore
import {getHosts,getHostsServices} from "../rest/nagios.js";
// @ts-ignore
import PanelEjecutivo from "../views/PanelEjecutivo/PanelEjecutivo"
// @ts-ignore
import SistemaDeSeguros from "../views/SistemaDeSeguros/SistemaDeSeguros"

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> =  ({ options, data }) => {

  const [flujosSeguros, setFlujosSeguros] = useState([]);
  const [hostsCns, setHostsCns] = useState([]);
  const [servicesCns, setServicesCns] = useState([]);
  const [panelDisplay, setPanelDisplay] = useState("ejecutivo");
  const [panelWraps, setPanelWraps] = useState(JSON.parse(options.json_structure_panel_ejecutivo));

  async function getFlujos(){
      const  flujosSegurosTemp = await getFlujosSeguros();
      setFlujosSeguros(flujosSegurosTemp);
  }

  async function getUtilData() {
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json')
    const infoNagiosHost = originalSeries?.nagios?.host;
    const infoNagiosService = originalSeries?.nagios?.service;

    if(infoNagiosHost){
      const hostNagiosTemp = getHostData(infoNagiosHost, nagios_host);
      setHostsCns(hostNagiosTemp);
      console.log(hostNagiosTemp)
    }
    
  }
  
  useEffect( ()=>{
    getFlujos();
    getUtilData();
  },
  [data]);

  function getStatesForServices(data: any, requests: any) {
    const results = [];
    for (const { hostName, serviceDescription } of requests) {
        const host = data.find((host: any) => host.host_name === hostName);
        if (!host) {
            results.push({
                hostName,
                serviceDescription,
                state: null,
                error: `Host "${hostName}" no encontrado.`
            });
            continue;
        }
        const service = host.services.find((service: any) => service.description === serviceDescription);
        if (!service) {
            results.push({
                hostName,
                serviceDescription,
                state: null,
                error: `Servicio "${serviceDescription}" no encontrado para el host "${hostName}".`
            });
            continue;
        }
        results.push({
            hostName,
            serviceDescription,
            state: service.state,
            error: null
        });
    }
    return results;
}

  async function getNagiosInfo() {
    let hosts = await getHosts("https://extensions.dparadig.com");
    // console.log(hosts);
    const groupedByTechnology = hosts.reduce((acc: any, device: any) => {
      const technology = device.custom_variables.TECNOLOGIA;
      if (!acc[technology]) {
        acc[technology] = [];
      }
      acc[technology].push(device);
      return acc;
    }, {});
    console.log(groupedByTechnology);

    let services = await getHostsServices("https://extensions.dparadig.com", "Amon,Ara");
    const arrayRelationships = options.services_relationships.split('\n').map((eachRlt: any) => {
      const arrayInfoRlt = eachRlt.split(',');
      return { hostname: arrayInfoRlt[0], serviceNagios: arrayInfoRlt[1], parent: arrayInfoRlt[2], item: arrayInfoRlt[3] };
    });
    const copyWraps = {...panelWraps};
    arrayRelationships.forEach((rlt: any) => {
      const [infoState] = getStatesForServices(services, [{hostName: rlt.hostname, serviceDescription: rlt.serviceNagios}]);
      console.log(infoState);
      const items = copyWraps[rlt.parent].items;
      const titleToUpdate = rlt.item;
      const newStatusCardValue = infoState.state;
      const itemToUpdate = items.find((item: any) => item.title === titleToUpdate);
      if (itemToUpdate) {
          const values: any = {
            0: 'OK',
            1: 'WARNING',
            2: 'CRITICAL',
            3: 'UNK',
            4: 'NOTDEFINED',
          };
          itemToUpdate.status = values[newStatusCardValue];
      } else {
          console.log(`Elemento con título "${titleToUpdate}" no encontrado.`);
      }
    });
    setPanelWraps(copyWraps);
  }

  useEffect(() => {
    getNagiosInfo();
  },[]);
  
  function togglePanel(){
    if(panelDisplay === "ejecutivo"){
      setPanelDisplay("seguros")
    }
    else{
      setPanelDisplay("ejecutivo")
    }
  }

  return (
    <div
      className="panel-wrapper"
      style={{ width: '1800px', height: '1020px', backgroundColor: '#d8e1f5', padding: '15px'}}
    >
      <button className='custom-hide' onClick={togglePanel}>
        <img src={icons.returnIcon}/>
      </button>
      <div>
        
      </div>
      <div className='view-container' style={panelDisplay === "ejecutivo" ? {}: {display: "none"}}>
        <PanelEjecutivo style={undefined} hosts={hostsCns} wraps={panelWraps}/>
      </div>
      <div className='view-container' style={panelDisplay === "seguros" ? {}: {display: "none"}}>
        <SistemaDeSeguros flujosSeguros={flujosSeguros}/>
      </div>
    </div>
  );
};
