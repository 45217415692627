import React, { useEffect, useState, useRef } from 'react';
// @ts-ignore
import Toolbar from '../../components/Toolbar/Toolbar';
import HospitalImg from '../../img/hospital.png';
import CardEnlace from '../../components/CardEnlace/CardEnlace';
import InfoEnlace from '../../components/InfoEnlace/InfoEnlace';
import InfoEnlaceItem from '../../components/InfoEnlaceItem/InfoEnlaceItem';
import EnlaceConexion from '../../components/EnlaceConexion/EnlaceConexion';
import { icons } from '../../utils/dictionaryIcons';

import './Enlaces.css';

function Enlaces({ infoDashboard, selectMainDashboard, speedEnlaces, overloadedSpeed, unusedSpeed }) {
  const [enlacesByHospital, setEnlacesByHospital] = useState({});
  const [linesSection, setLinesSection] = useState(null);
  const [enlaces, setEnlaces] = useState([]);
  const divRef = useRef(null);
  useEffect(() => {
    const infoByHospital = {};
    for (const host in infoDashboard) {
      const hostInfo = infoDashboard[host];
      if (!infoByHospital[hostInfo.site]) {
        infoByHospital[hostInfo.site] = [];
      }
      infoByHospital[hostInfo.site].push(infoDashboard[host]);
    }
    setEnlacesByHospital(infoByHospital);
  }, [infoDashboard]);

  useEffect(() => {
    const enlacesTmp = [];
    const copyEnlaces = JSON.parse(JSON.stringify(enlacesByHospital));

    for (const hospital in copyEnlaces) {
      const arrayServersByHospital = copyEnlaces[hospital];
      arrayServersByHospital.forEach((server) => {
        const newItems = {};
        const copyArrayHostItems = server.items;
        copyArrayHostItems.forEach((item) => {
          if (!newItems[item.label]) {
            newItems[item.label] = {};
          }
          if (item.inbound && item.outbound) {
            newItems[item.label]['inbound'] = item.inbound;
            newItems[item.label]['outbound'] = item.outbound;
            newItems[item.label]['status'] = item.status;
          } else {
            newItems[item.label]['iconName'] = item.iconName;
            newItems[item.label]['label'] = item.label;
            newItems[item.label]['portStatus'] = item.status;
          }
        });
        server.items = newItems;
        Object.keys(newItems).forEach((item) => {
          enlacesTmp.push({
            ...newItems[item],
            id: `${hospital}-${server.custom_desc}-${item}`,
          });
        });
      });
    }
    setEnlaces(enlacesTmp);
  }, [enlacesByHospital]);

  useEffect(() => {
    if (divRef.current && enlaces.length) {
      const cmptLinesSection = (
        <EnlaceConexion
          enlaces={enlaces}
          speedEnlaces={speedEnlaces}
          overloadedSpeed={overloadedSpeed}
          unusedSpeed={unusedSpeed}
        />
      );
      setLinesSection(cmptLinesSection);
    }
  }, [divRef, enlaces]);

  function getEnlaceByHospital() {
    const itemsByHospital = [];
    const modifiedEnlaces = {};
    const enlacesTmp = [];
    const copyEnlaces = JSON.parse(JSON.stringify(enlacesByHospital));
    for (const hospital in copyEnlaces) {
      if (!modifiedEnlaces[hospital]) {
        modifiedEnlaces[hospital] = [];
      }
      const arrayServersByHospital = copyEnlaces[hospital];
      arrayServersByHospital.forEach((server) => {
        const newItems = {};
        const copyArrayHostItems = server.items;
        copyArrayHostItems.forEach((item) => {
          if (!newItems[item.label]) {
            newItems[item.label] = {};
          }
          if (item.inbound && item.outbound) {
            newItems[item.label]['inbound'] = item.inbound;
            newItems[item.label]['outbound'] = item.outbound;
            newItems[item.label]['status'] = item.status;
          } else {
            newItems[item.label]['iconName'] = item.iconName;
            newItems[item.label]['label'] = item.label;
            newItems[item.label]['portStatus'] = item.status;
          }
        });
        server.items = newItems;
        modifiedEnlaces[hospital].push(server);
      });
    }
    const cmptsByHospital = {};
    for (const hospital in modifiedEnlaces) {
      if (hospital !== 'Vitacura') {
        const arrayServersByHospital = modifiedEnlaces[hospital];

        arrayServersByHospital.forEach((server) => {
          const items = server.items;
          const arrayItemsCmpt = [];
          Object.keys(items).forEach((keyItem) => {
            const itemValues = items[keyItem];
            arrayItemsCmpt.push(
              <InfoEnlaceItem
                iconName={'port'}
                status={itemValues.status}
                itemLabel={''}
                key={`item-${server}-${keyItem}`}
                idclass={`${hospital}-${server.custom_desc}-${keyItem}`}
              />
            );
          });
          if (!cmptsByHospital[hospital]) {
            cmptsByHospital[hospital] = [];
          }
          cmptsByHospital[hospital].push(
            <CardEnlace
              status={server.status}
              iconName={'computer'}
              bodyContent={
                <InfoEnlace type={server.custom_desc ? server.custom_desc : server.type} ip={`IP ${server.ip}`} />
              }
              items={arrayItemsCmpt}
            />
          );
        });
      }
    }

    Object.keys(cmptsByHospital).forEach((hospital) => {
      const items = cmptsByHospital[hospital];
      itemsByHospital.push(
        <div className="by-Hospital" style={{ border: '2px dashed gray', padding: '0px 10px', borderRadius: '20px' }}>
          <div className="wrap-items" style={{ display: 'flex' }}>
            {items}
          </div>
          <div className="title" style={{ display: 'flex', justifyContent: 'center', fontSize: '12px' }}>
            {hospital}
          </div>
        </div>
      );
    });
    return itemsByHospital;
  }

  function getVitacuraEnlaces() {
    const itemsByHospital = [];
    const modifiedEnlaces = {};
    const copyEnlaces = JSON.parse(JSON.stringify(enlacesByHospital));
    for (const hospital in copyEnlaces) {
      if (!modifiedEnlaces[hospital]) {
        modifiedEnlaces[hospital] = [];
      }
      const arrayServersByHospital = copyEnlaces[hospital];
      arrayServersByHospital.forEach((server) => {
        const newItems = {};
        const copyArrayHostItems = server.items;
        copyArrayHostItems.forEach((item) => {
          if (!newItems[item.label]) {
            newItems[item.label] = {};
          }
          if (item.inbound && item.outbound) {
            newItems[item.label]['inbound'] = item.inbound;
            newItems[item.label]['outbound'] = item.outbound;
            newItems[item.label]['status'] = item.status;
          } else {
            newItems[item.label]['iconName'] = item.iconName;
            newItems[item.label]['label'] = item.label;
            newItems[item.label]['portStatus'] = item.status;
          }
        });
        server.items = newItems;
        modifiedEnlaces[hospital].push(server);
      });
    }
    const cmptsByHospital = {};
    for (const hospital in modifiedEnlaces) {
      if (hospital === 'Vitacura') {
        const arrayServersByHospital = modifiedEnlaces[hospital];

        arrayServersByHospital.forEach((server) => {
          const items = server.items;
          const arrayItemsCmpt = [];
          Object.keys(items).forEach((keyItem) => {
            const itemValues = items[keyItem];
            // console.log(keyItem, server.custom_desc);
            arrayItemsCmpt.push(
              <InfoEnlaceItem
                iconName={'port'}
                status={itemValues.status}
                itemLabel={''}
                key={`item-${server}-${keyItem}`}
                idclass={`${hospital}-${server.custom_desc}-${keyItem}`}
              />
            );
          });
          if (!cmptsByHospital[hospital]) {
            cmptsByHospital[hospital] = [];
          }
          if (server.custom_desc === 'core_agg_1.alemana.cl') {
            cmptsByHospital[hospital].push(
              <CardEnlace
                status={server.status}
                iconName={'computer'}
                bodyContent={
                  <InfoEnlace type={server.custom_desc ? server.custom_desc : server.type} ip={`IP ${server.ip}`} />
                }
                items={arrayItemsCmpt}
                side={'left'}
              />
            );
          } else {
            cmptsByHospital[hospital].push(
              <CardEnlace
                status={server.status}
                iconName={'computer'}
                bodyContent={
                  <InfoEnlace type={server.custom_desc ? server.custom_desc : server.type} ip={`IP ${server.ip}`} />
                }
                items={arrayItemsCmpt}
              />
            );
          }
        });
      }
    }

    Object.keys(cmptsByHospital).forEach((hospital) => {
      const items = cmptsByHospital[hospital];
      itemsByHospital.push(
        <div className="by-Hospital" style={{ border: '2px dashed gray', padding: '0px 10px', borderRadius: '20px' }}>
          <div className="wrap-items" style={{ display: 'flex' }}>
            {items}
          </div>
          <div className="title" style={{ display: 'flex', justifyContent: 'center', fontSize: '12px' }}>
            {hospital}
          </div>
        </div>
      );
    });
    return itemsByHospital;
  }

  return (
    <section className="enlaces-view">
      <Toolbar title={`Enlaces`} />
      <section className="content" id="enlaces-view-content">
        <div className="cloud"></div>
        <div className="main-hospital">
          <div className="devices" ref={divRef}>
            {[...getVitacuraEnlaces()]}
          </div>
          <img src={HospitalImg} id="vitacura-img" className="img-vitacura" />
        </div>
        <div className="devices" ref={divRef}>
          {[...getEnlaceByHospital()]}
        </div>
        {linesSection}
      </section>
      <div className="server-icon-enlace" id={'sonda-icon-enlace'}>
        <img src={icons['server']} />
        <label>SONDA</label>
      </div>
      <div className="server-icon-enlace" id={'imed-icon-enlace'}>
        <img src={icons['server']} />
        <label>IMED</label>
      </div>
      <div className="server-icon-enlace" id={'transbank-icon-enlace'}>
        <img src={icons['server']} />
        <label>TRANSBANK</label>
      </div>
      <div className="server-icon-enlace" id={'prego-icon-enlace'}>
        <img src={icons['server']} />
        <label>PREGO</label>
      </div>
      <div className="server-icon-world" id={'sonda-icon-world'}>
        <div style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: 'blue' }}>
          <img src={icons['world']} />
        </div>
        <label>IFX</label>
      </div>
      <div className="server-icon-claro" id={'sonda-icon-claro'}>
        <div style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: 'blue' }}>
          <img src={icons['world']} />
        </div>
        <label>CLARO</label>
      </div>
      <div className="server-icon-oracle" id={'sonda-icon-oracle'}>
        <div>
          <img src={icons['oracle']} />
        </div>
      </div>
      <div className="server-icon-cirion" id={'sonda-icon-cirion'}>
        <div>
          <img src={icons['cirion']} />
        </div>
      </div>
      <div className="back-icon" onClick={selectMainDashboard}>
        <div>
          <img src={icons['back']} />
        </div>
      </div>
    </section>
  );
}

export default Enlaces;
