import React from 'react';
import './PercentageBar.css';

type Props = {
    total: number;
    subTotal: number;
};

const PercentageBar: React.FC<Props> = ({ total, subTotal }) => {
  const percentage = (subTotal/total)*100;
  return (
    <svg className="percentage-graphic" style={{ width: '90px', height: '11px' }}>
        <rect className="graphic-white" height= "100%" width= "100%"></rect>
        <rect className="graphic-gray" height= "100%"  width={ percentage + "%"}></rect>
        <text x="50%" y="70%" textAnchor="middle">{subTotal}/{total}</text>
    </svg>
  );
};

export default PercentageBar;

