/* eslint-disable eqeqeq */
import React from 'react';
import { getStatusColorByNumber, getFlags } from '../../utils/color';
import './SdwanCard.css';

type Props = {
  title: any;
  image: any;
  arrayCountry?: any;
  statusCloud: any;
  keyCloud: any;
  cloudUsage: any;
  display: any;
  selectAlerts: any;
};

const arrayCountryT = [
  {
    name: 'CHILE',
  },
  {
    name: 'ARGENTINA',
  },
  {
    name: 'BRASIL',
  },
  {
    name: 'PERU',
  },
  {
    name: 'COLOMBIA',
  },
];

const SdwanCard: React.FC<Props> = ({
  title,
  image,
  arrayCountry = arrayCountryT,
  statusCloud,
  keyCloud,
  cloudUsage,
  display,
  selectAlerts,
}) => {
  function getProgressBars(cloudUsage: any, display: any) {
    const progressBars: any = [];
    cloudUsage.forEach((barElement: any) => {
      if (keyCloud === 'AZURE') {
        barElement.name = 'East US';
      }
      if (keyCloud === 'GCP') {
        if (barElement.name === 'East') {
          barElement.name = 'C del Sur';
        }
        if (barElement.name === 'Legacy' || barElement.name === 'East') {
          barElement.name = 'C del Sur - Legacy';
        }
      }
      if (display == 'vertical') {
        progressBars.push(
          <div className={'progressBarWrap progressBarWrapVertical'}>
            <div className="wrap-label">
              <div
                className="flag-card"
                style={{
                  background: getStatusColorByNumber(statusCloud[barElement.address]),
                  width: '25px',
                  height: '25px',
                }}
              ></div>
              <div className="wrapLabel" title={barElement.address}>
                {barElement.name}
              </div>
            </div>
            <div className="progressBar progressBarVertical">
              <div className="progressBarItem">
                <div className="progressBarLabel" style={{ marginRight: '5px' }}>
                  {' '}
                  Mem
                </div>
                <svg className="tbc">
                  <rect className="bg" width="100%" height="100%" />
                  <rect className="bar" width={barElement.memory} height="100%" />
                  <text x="50%" y="70%" textAnchor="middle">
                    {barElement.memory}
                  </text>
                </svg>
              </div>
              <div className="progressBarItem">
                <div className="progressBarLabel" style={{ marginRight: '5px' }}>
                  CPU
                </div>
                <svg className="tbc">
                  <rect className="bg" width="100%" height="100%" />
                  <rect className="bar" width={barElement.cpu} height="100%" />
                  <text x="50%" y="70%" textAnchor="middle">
                    {barElement.cpu}
                  </text>
                </svg>
              </div>
            </div>
          </div>
        );
      } else {
        progressBars.push(
          <div className={'progressBarWrap'}>
            <div className="wrap-label">
              <div
                className="flag-card"
                style={{ background: getStatusColorByNumber(statusCloud[barElement.address]) }}
              ></div>
              <div className="wrapLabel" title={barElement.address}>
                {barElement.name}
              </div>
              <div className="wrapLabel">{barElement.address}</div>
            </div>
            <div className="progressBar">
              <div className="progressBarLabel"> Mem:</div>
              <svg className="tbc">
                <rect className="bg" width="100%" height="100%" />
                <rect className="bar" width={barElement.memory} height="100%" />
                <text x="50%" y="70%" textAnchor="middle">
                  {barElement.memory}
                </text>
              </svg>
              <div className="progressBarLabel">CPU:</div>
              <svg className="tbc">
                <rect className="bg" width="100%" height="100%" />
                <rect className="bar" width={barElement.cpu} height="100%" />
                <text x="50%" y="70%" textAnchor="middle">
                  {barElement.cpu}
                </text>
              </svg>
            </div>
          </div>
        );
      }
    });
    return progressBars;
  }

  const countries = ['CHILE', 'ARGENTINA', 'BRASIL', 'PERU', 'COLOMBIA'];
  const sdwanCardWrapItems = countries.map((country: string, index: any) => (
    <div
      className="sdwan-card-wrap-item"
      style={country === 'CHILE' ? { marginTop: '6px' } : {}}
      key={`${index}-sdwan-card`}
    >
      <div
        className="flag-card"
        style={{
          background: getStatusColorByNumber(statusCloud[country]['vpn'][keyCloud]),
          cursor: statusCloud[country]['vpn'][keyCloud] !== 0 ? 'pointer' : 'default',
        }}
        onClick={() => selectAlerts('VPN', country, keyCloud)}
      ></div>
      <div
        className="flag-card"
        style={{
          background: getStatusColorByNumber(statusCloud[country]['sla'][keyCloud]),
          marginLeft: '4px',
          cursor: statusCloud[country]['sla'][keyCloud] !== 0 ? 'pointer' : 'default',
        }}
        onClick={() => selectAlerts('SLA', country, keyCloud)}
      ></div>
      <div className="label-card">{country}</div>
    </div>
  ));
  sdwanCardWrapItems.unshift(
    <div style={{ position: 'absolute', top: 0, color: 'gray', fontSize: '10px', left: '10px' }}>{'VPN    SLA'}</div>
  );

  const sdwanCardWrapItemsVertical = countries.map((country: string) => (
    <>
      <div className="sdwan-card-wrap-item">
        {/* flaggg */}
        <img src={getFlags(country)} style={{ width: '22px' }} />
        <div
          className="flag-card"
          style={{
            background: getStatusColorByNumber(statusCloud[country]['vpn'][keyCloud]),
            margin: '4px 4px',
            cursor: statusCloud[country]['vpn'][keyCloud] !== 0 ? 'pointer' : 'default',
          }}
          onClick={() => selectAlerts('VPN', country, keyCloud)}
        ></div>
        <div
          className="flag-card"
          style={{
            background: getStatusColorByNumber(statusCloud[country]['sla'][keyCloud]),
            cursor: statusCloud[country]['sla'][keyCloud] !== 0 ? 'pointer' : 'default',
          }}
          onClick={() => selectAlerts('SLA', country, keyCloud)}
        ></div>
      </div>
    </>
  ));
  sdwanCardWrapItemsVertical.unshift(
    <div style={{ color: 'gray', fontSize: '10px', fontWeight: 'bold', height: '100%' }}>
      <div style={{ position: 'relative', top: '35px' }}>VPN</div>
      <div style={{ position: 'relative', top: '45px' }}>SLA</div>
    </div>
  );

  return (
    <section className={display === 'vertical' ? 'sdwan-card-cmpt mb-10' : 'sdwan-card-cmpt mr-10'}>
      <div className={display === 'vertical' ? 'sdwan-card-main-vertical' : 'sdwan-card-main'}>
        <div className="sdwan-card-image">
          {/* <div className="sdwan-card-title">{title}</div> */}
          <div className="sdwan-card-logo">
            <img src={image} />
          </div>
        </div>
        <div className={display === 'vertical' ? 'sdwan-card-indicator-vertical' : 'sdwan-card-indicator'}>
          {display === 'vertical' ? sdwanCardWrapItemsVertical : sdwanCardWrapItems}
        </div>
      </div>
      <div className={display === 'vertical' ? 'progressBarsContainer' : 'progressBarsContainer hr-adjust'}>
        {getProgressBars(cloudUsage[keyCloud], display)}
      </div>
    </section>
  );
};

export default SdwanCard;
