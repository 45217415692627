import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import {getStatusColorByType} from '../../utils/color';
import './BaseDeDatos.css';
import CardInfo from '../../components/CardInfo/CardInfo';
import InfoHost from '../../components/InfoHost/InfoHost';



function getBDCard(infoDashboard, hostName, ip, style ){
    const problems = infoDashboard[hostName].problems.map((item) => (
        <div className='alerted'>
            <div className='status' style={{background: getStatusColorByType(item.status)}}></div>
            {item.itemName}
        </div>
    ))

    const body = (
        <div className='active-alerts'>
            <div className='alert-header'>
                <div className='alert-header-text'>Alarmas activas</div>
                {/* <div className='alert-header-status'></div> */}
            </div>
            <div className='alerts'>
                {problems}
            </div>
        </div>
    )

    return (
        <CardInfo 
            status={infoDashboard && infoDashboard[hostName] ? infoDashboard[hostName].status : "UNK"} 
            mainContent={<InfoHost iconName={"database"} type={'Servidor'} hostname={hostName} ip={ip  && `IP ${ip}`} />} 
            items={[]}
            bodyContent= {body}
            style={style}>
        </CardInfo>
    )
}

function BaseDeDatos({ infoDashboard, selectMainDashboard}) {

    return (
        <section className="db-view">
            <Toolbar title={`Base de Datos`} />
            <div className='content'>
                <div className='column1'>
                    <div className='column-content'>
                        <div className='column-header'>
                            <img src={icons['oracle']} />
                            <div className='column-status'></div>
                        </div>
                        {getBDCard(infoDashboard, "ROBLE_ORACLE_PSOL7", "10.90.0.11", {height: "calc(50% - 30px)", width: "100%"})}
                        {getBDCard(infoDashboard, "EXA_PO11S7", undefined, {height: "calc(50% - 30px)", width: "100%"})}
                    </div>
                </div>
                <div className='column2'>
                    <div className='column-content'>
                        <div className='column-header'>
                            <img src={icons['PostgreSQL']} />
                            <div className='column-status'></div>
                        </div>
                        {getBDCard(infoDashboard, "Odiseo2", "10.62.11.33", {height: "calc(50% - 30px)", width: "100%"})}
                        {getBDCard(infoDashboard, "Ulises", "10.62.11.46", {height: "calc(50% - 30px)", width: "100%"})}
                    </div>
                </div>
                <div className='column3'>
                    <div className='column-content'>
                    <div className='column-header'>
                            <img src={icons['MongoDB']} />
                            <div className='column-status'></div>
                        </div>
                        {getBDCard(infoDashboard, "Africa", "10.62.33.56", {height: "calc(33% - 20px)", width: "100%"})}
                        {getBDCard(infoDashboard, "Bolivia", "10.62.33.59", {height: "calc(33% - 20px)", width: "100%"})}
                        {getBDCard(infoDashboard, "Uruguay", "10.62.13.58", {height: "calc(33% - 20px)", width: "100%"})}
                    </div>
                </div>
            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default BaseDeDatos;