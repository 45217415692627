
export function getStatusColorByType(status: String) {
  if (status === 'OK') {
    return '#10D884';
  } else if (status === 'WARNING' || status === 'WARN') {
    return '#F9CC2B';
  } else if (status === 'CRITICAL' || status === 'CRIT') {
    return '#ED4C5C';
  } else if (status === 'UNKNOWN' || status === 'UNK') {
    return '#AAB8D6';
  }
  return '#AAB8D6';
}

export function getStatusColorByNumber(status: any) {
  if (status === 0 || status === '0') {
    return '#10D884';
  } else if (status === 1 || status === '1') {
    return '#F9CC2B';
  } else if (status === 2 || status === '2') {
    return '#ED4C5C';
  }
  return '#AAB8D6';
}
