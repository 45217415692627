// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lims-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .lims-container .lims-content {
    width: 100%;
    height: 100%;
    background-color:#f1f6fa;
  }
  
  .lims-container .column-1 {
    width: 600px;
    margin: 15px;
    }


.lims-container .main-content .solid-horizontal {
  height: 4px;
}

.lims-container .main-content .solid-vertical {
  width: 4px;
}

.lims-container .main-content .dash-horizontal {
  height: 2px;
}

.lims-container .main-content .dash-vertical {
  width: 2px;
}

.yellow-solid {
  border-color: #ffc000;
  background-color: #ffc000;
}

.gray-solid {
  border-color: #b4c8d0;
  background-color: #b4c8d0;
}

.blue-solid {
  border-color: rgb(0, 59, 124);
  background-color: rgb(0, 59, 124);
}

.gray-dash {
  border: 1px dashed #b4c8d0;
}

.red-dash {
  border: 1px dashed #ff1010;
}

.green-dash {
  border: 1px dashed #04ac26;
}

`, "",{"version":3,"sources":["webpack://./components/Lims/Lims.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,WAAW;IACX,YAAY;IACZ,wBAAwB;EAC1B;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ;;;AAGJ;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,iCAAiC;AACnC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".lims-container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n  .lims-container .lims-content {\n    width: 100%;\n    height: 100%;\n    background-color:#f1f6fa;\n  }\n  \n  .lims-container .column-1 {\n    width: 600px;\n    margin: 15px;\n    }\n\n\n.lims-container .main-content .solid-horizontal {\n  height: 4px;\n}\n\n.lims-container .main-content .solid-vertical {\n  width: 4px;\n}\n\n.lims-container .main-content .dash-horizontal {\n  height: 2px;\n}\n\n.lims-container .main-content .dash-vertical {\n  width: 2px;\n}\n\n.yellow-solid {\n  border-color: #ffc000;\n  background-color: #ffc000;\n}\n\n.gray-solid {\n  border-color: #b4c8d0;\n  background-color: #b4c8d0;\n}\n\n.blue-solid {\n  border-color: rgb(0, 59, 124);\n  background-color: rgb(0, 59, 124);\n}\n\n.gray-dash {\n  border: 1px dashed #b4c8d0;\n}\n\n.red-dash {\n  border: 1px dashed #ff1010;\n}\n\n.green-dash {\n  border: 1px dashed #04ac26;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
