import React from 'react';
import './DVUContainer.css';
import  {icons} from "../../utils/dictionaryIcons"
import  {color_status} from "../../utils/dictionaryStates"

function DVUContainer({status, title, style = undefined, content= undefined}) {
  return (
    <>
        <div className="dvu-container" style={style}>
            <img src={icons["DVU"]} style={{marginRight: "10px"}}/>
            <div className='dvu-title'>{title}</div>
            <div className='dvu-status' style={{background: color_status[status]}}></div>
            {content}
        </div>
    </>
  );
}

export default DVUContainer;
