import React from "react";
import "./Modal.css"; // Import CSS for styling

const Modal = ({ isOpen, onClose, title, children, modalRef }) => {
  if (!isOpen) return null; // Don't render if modal is closed

  return (
    <div
      className="modal-overlay"
      onClick={onClose}
      style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%" }}
    >
      <div
        className="modal-content"
        ref={modalRef} // Reference the modal element for dynamic positioning
        onClick={(e) => e.stopPropagation()}
        style={{
          position: "absolute", // Use absolute positioning to place it in the viewport
        }}
      >
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <div className="modal-title">{title}</div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;