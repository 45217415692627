// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hexagon-container {
    position: relative;
    width: 370px;
    height: 400px;
}

.hexagon-container .img-hex{
    position: absolute;
    width: 50px;
    top: 20px;
    left: calc(50% - 25px);
}

.hexagon-border {
    position: absolute;
    width: 370px;
    height: 400px;
    background-color: #316be4;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagon {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 360px;
    height: 390px;
    background-color: white;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    padding: 100px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./components/K8s/K8s.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,wEAAwE;AAC5E;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,wEAAwE;IACxE,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".hexagon-container {\n    position: relative;\n    width: 370px;\n    height: 400px;\n}\n\n.hexagon-container .img-hex{\n    position: absolute;\n    width: 50px;\n    top: 20px;\n    left: calc(50% - 25px);\n}\n\n.hexagon-border {\n    position: absolute;\n    width: 370px;\n    height: 400px;\n    background-color: #316be4;\n    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);\n}\n\n.hexagon {\n    position: absolute;\n    top: 5px;\n    left: 5px;\n    width: 360px;\n    height: 390px;\n    background-color: white;\n    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);\n    padding: 100px 0px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
