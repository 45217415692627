export const colorStatusDictionary = {
  0: '#80bc00',
  1: '#ffc000',
  2: '#ed4c5c',
  3: '#ff8000',
  4: '#b4c8d0',
};

export const statusColorDictionary = {
  '#80bc00': 0,
  '#ffc000': 1,
  '#ed4c5c': 2,
  '#ff8000': 3,
  '#b4c8d0': 4,
}
