// export const prtgStatus = {
//   Unknown: '1',
//   Collecting: '2',
//   Up: '3',
//   Warning: '4',
//   Down: '5',
//   NoProbe: '6',
//   PausedbyUser: '7',
//   PausedbyDependency: '8',
//   PausedbySchedule: '9',
//   Unusual: '10',
//   PausedbyLicense: '11',
//   PausedUntil: '12',
//   DownAcknowledged: '13',
//   DownPartial: '14',
// };

export const prtgStatus = {
  1: 3,
  2: 0,
  3: 0,
  4: 1,
  5: 2,
  6: 2,
  7: 0,
  8: 0,
  9: 0,
  10: 1,
  11: 0,
  12: 0,
  13: 2,
  14: 2,
};

export const prtgSensorStatus = {
  OK: 0,
  Pausado: 0,
  Advertencia: 1,
  Inusual: 1,
  Desconocido: 1,
  Fallo: 2,
  'Fallo (Confirmado)': 2,
};
