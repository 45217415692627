// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-card{
    width: 60px;
    height: 25px;
    gap: 0px;
    border-radius: 5px;
    opacity: 0px;
    border: 0.5px solid #EFF5FF;
    font-family: Sen;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.44px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./components/StatusCard/StatusCard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,kBAAkB;IAClB,YAAY;IACZ,2BAA2B;IAC3B,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;IAC9B,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".status-card{\n    width: 60px;\n    height: 25px;\n    gap: 0px;\n    border-radius: 5px;\n    opacity: 0px;\n    border: 0.5px solid #EFF5FF;\n    font-family: Sen;\n    font-size: 12px;\n    font-weight: 700;\n    line-height: 14.44px;\n    letter-spacing: 0.04em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
