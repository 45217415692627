import React, { useEffect, useState } from 'react';
import './Network2.css';
import CardNetwork from './CardNetwork';
import { getStatusColorByNumber } from '../../utils/color';
import EnlaceTooltip from 'components/EnlaceTooltip/EnlaceTooltip';

function Network2({ enlaces }) {
  const status3Color = getStatusColorByNumber(3);
  const [lineStatus, setLineStatus] = useState({
    'CHL-PE-01': [
      { to: 'COL-PE-01', from: 'CHL-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
      { to: 'ARG-PE-01', from: 'CHL-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'CHL-PE-02': [
      { to: 'BRA-PE-02', from: 'CHL-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
      { to: 'PER-PE-02', from: 'CHL-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
      { to: 'ARG-PE-02', from: 'CHL-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'ARG-PE-01': [
      { to: 'CHL-PE-01', from: 'ARG-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
      { to: 'BRA-PE-01', from: 'ARG-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
      { to: 'PER-PE-01', from: 'ARG-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'ARG-PE-02': [
      { to: 'CHL-PE-02', from: 'ARG-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
      { to: 'COL-PE-02', from: 'ARG-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'PER-PE-01': [
      { to: 'ARG-PE-01', from: 'PER-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'PER-PE-02': [
      { to: 'CHL-PE-02', from: 'PER-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'COL-PE-01': [
      { to: 'CHL-PE-01', from: 'COL-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'COL-PE-02': [
      { to: 'ARG-PE-02', from: 'COL-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'BRA-PE-01': [
      { to: 'ARG-PE-01', from: 'BRA-PE-01', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
    'BRA-PE-02': [
      { to: 'CHL-PE-02', from: 'BRA-PE-02', status: status3Color, fromStatus: status3Color, toStatus: status3Color },
    ],
  });

  const links = {
    'CHL-PE-01': ['COL-PE-01', 'ARG-PE-01'],
    'CHL-PE-02': ['BRA-PE-02', 'PER-PE-02', 'ARG-PE-02'],
    'ARG-PE-01': ['CHL-PE-01', 'BRA-PE-01', 'PER-PE-01'],
    'ARG-PE-02': ['CHL-PE-02', 'COL-PE-02'],
    'PER-PE-01': ['ARG-PE-01'],
    'PER-PE-02': ['CHL-PE-02'],
    'COL-PE-01': ['CHL-PE-01'],
    'COL-PE-02': ['ARG-PE-02'],
    'BRA-PE-01': ['ARG-PE-01'],
    'BRA-PE-02': ['CHL-PE-02'],
  };

  useEffect(() => {
    const linksnewdata = [];
    enlaces.forEach((enlace) => {
      let from = enlace.from;
      let to = enlace.to;
      if (from in links) {
        let linkArr = links[from];
        let link = linkArr.find((l) => l === to);
        if (link) {
          linksnewdata.push(enlace);
        }
      }
    });
    let lineStatusNew = {};
    Object.entries(links).forEach(([from, arrTo]) => {
      let temp = [];
      arrTo.forEach((to) => {
        let item = linksnewdata.find((e) => (e.from === from && e.to === to) || (e.from === to && e.to === from));
        if (item) {
          temp.push({
            to: to,
            status: getStatusColorByNumber(item.status),
            problems: item.problems,
            spd: item.spd,
            label: item.label,
            usein: item.usein,
            useout: item.useout,
            deviceStatus: getStatusColorByNumber(item[from]),
          });
        } else {
          temp.push({ to: to, status: getStatusColorByNumber(3) });
        }
      });
      lineStatusNew[from] = temp;
    });

    setLineStatus(lineStatusNew);
  }, [enlaces]);

  function getTooltip(link) {
    return (
      <EnlaceTooltip
        problems={link.problems}
        code={link.label}
        speed={link.spd}
        usein={link.usein}
        useout={link.useout}
      />
    );
  }

  return (
    <div className="network-wrapper">
      <div className="cirion">
        <div className="cirion-title">Cirion</div>
        <div className="cirion-label">Red transporte WDM</div>
        <CardNetwork
          name={'DC - Chile'}
          location={'CHL'}
          interfaces={[
            { name: 'CHL-PE-01', status: lineStatus['CHL-PE-01'] },
            { name: 'CHL-PE-02', status: lineStatus['CHL-PE-02'] },
          ]}
          positions={{ left: '40px', top: '-37px' }}
          top_bottom={'top'}
        ></CardNetwork>
        <CardNetwork
          name={'DC - Argentina'}
          location={'ARG'}
          interfaces={[
            { name: 'ARG-PE-01', status: lineStatus['ARG-PE-01'] },
            { name: 'ARG-PE-02', status: lineStatus['ARG-PE-02'] },
          ]}
          positions={{ right: '40px', top: '-37px' }}
          top_bottom={'top'}
        ></CardNetwork>
        <CardNetwork
          name={'DC - Colombia'}
          location={'CO'}
          interfaces={[
            { name: 'COL-PE-01', status: lineStatus['COL-PE-01'] },
            { name: 'COL-PE-02', status: lineStatus['COL-PE-02'] },
          ]}
          positions={{ bottom: '-37px' }}
          top_bottom={'bottom'}
        ></CardNetwork>
        <CardNetwork
          name={'DC - Perú'}
          location={'PE'}
          interfaces={[
            { name: 'PER-PE-01', status: lineStatus['PER-PE-01'] },
            { name: 'PER-PE-02', status: lineStatus['PER-PE-02'] },
          ]}
          positions={{ left: '40px', bottom: '-37px' }}
          top_bottom={'bottom'}
        ></CardNetwork>
        <CardNetwork
          name={'DC - Brasil'}
          location={'BR'}
          interfaces={[
            { name: 'BRA-PE-01', status: lineStatus['BRA-PE-01'] },
            { name: 'BRA-PE-02', status: lineStatus['BRA-PE-02'] },
          ]}
          positions={{ right: '40px', bottom: '-37px' }}
          top_bottom={'bottom'}
        ></CardNetwork>

        {/* ch1 - co1 */}
        <div
          className="line"
          style={{
            width: '3px',
            height: '161px',
            top: '92px',
            left: '77px',
            backgroundColor: lineStatus['CHL-PE-01'][0].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-01'][0])}</span>
        </div>
        <div
          className="line"
          style={{
            left: '80px',
            top: '50%',
            right: 'calc(50% + 59px)',
            backgroundColor: lineStatus['CHL-PE-01'][0].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-01'][0])}</span>
        </div>
        <div
          className="line"
          style={{
            width: '3px',
            height: '141px',
            bottom: '106px',
            left: 'calc(50% - 62px)',
            backgroundColor: lineStatus['CHL-PE-01'][0].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-01'][0])}</span>
        </div>

        {/* ch1 - arg1 */}
        <div
          className="line"
          style={{
            width: '3px',
            height: '40px',
            top: '92px',
            left: '117px',
            backgroundColor: lineStatus['CHL-PE-01'][1].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-01'][1])}</span>
        </div>
        <div
          className="line"
          style={{ left: '117px', top: '132px', right: '239px', backgroundColor: lineStatus['CHL-PE-01'][1].status }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-01'][1])}</span>
        </div>
        <div
          className="line"
          style={{
            width: '3px',
            height: '40px',
            top: '92px',
            right: '239px',
            backgroundColor: lineStatus['CHL-PE-01'][1].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-01'][1])}</span>
        </div>

        {/* ch2 - br2 */}
        <div
          className="line"
          style={{
            width: '3px',
            height: '259px',
            top: '92px',
            left: '196px',
            backgroundColor: lineStatus['CHL-PE-02'][0].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-02'][0])}</span>
        </div>
        <div
          className="line"
          style={{ left: '196px', bottom: '146px', right: '100px', backgroundColor: lineStatus['CHL-PE-02'][0].status }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-02'][0])}</span>
        </div>
        <div
          className="line"
          style={{
            width: '3px',
            height: '40px',
            bottom: '106px',
            right: '100px',
            backgroundColor: lineStatus['CHL-PE-02'][0].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-02'][0])}</span>
        </div>

        {/* ch2 - pe2 */}

        <div
          className="line"
          style={{
            width: '3px',
            height: '288px',
            top: '106px',
            left: '216px',
            backgroundColor: lineStatus['CHL-PE-02'][1].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-02'][1])}</span>
        </div>

        {/* ch2 - ar2 */}
        <div
          className="line"
          style={{
            width: '3px',
            height: '60px',
            top: '92px',
            left: '236px',
            backgroundColor: lineStatus['CHL-PE-02'][2].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-02'][2])}</span>
        </div>
        <div
          className="line"
          style={{ left: '236px', top: '152px', right: '121px', backgroundColor: lineStatus['CHL-PE-02'][2].status }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-02'][2])}</span>
        </div>
        <div
          className="line"
          style={{
            width: '3px',
            height: '60px',
            top: '92px',
            right: '121px',
            backgroundColor: lineStatus['CHL-PE-02'][2].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['CHL-PE-02'][2])}</span>
        </div>

        {/* ar1 - br1 */}

        <div
          className="line"
          style={{
            width: '3px',
            height: '288px',
            top: '106px',
            right: '219px',
            backgroundColor: lineStatus['ARG-PE-01'][1].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['ARG-PE-01'][1])}</span>
        </div>

        {/* ar1 - pe1 */}

        <div
          className="line"
          style={{
            width: '3px',
            height: '231px',
            top: '92px',
            right: '199px',
            backgroundColor: lineStatus['ARG-PE-01'][2].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['ARG-PE-01'][2])}</span>
        </div>
        <div
          className="line"
          style={{ left: '98px', bottom: '174px', right: '199px', backgroundColor: lineStatus['ARG-PE-01'][2].status }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['ARG-PE-01'][2])}</span>
        </div>
        <div
          className="line"
          style={{
            width: '3px',
            height: '71px',
            bottom: '106px',
            left: '98px',
            backgroundColor: lineStatus['ARG-PE-01'][2].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['ARG-PE-01'][2])}</span>
        </div>

        {/* ar2 - co2 */}

        <div
          className="line"
          style={{
            width: '3px',
            height: '141px',
            bottom: '106px',
            right: 'calc(50% - 59px)',
            backgroundColor: lineStatus['ARG-PE-02'][1].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['ARG-PE-02'][1])}</span>
        </div>
        <div
          className="line"
          style={{
            right: '80px',
            top: '50%',
            left: 'calc(50% + 56px)',
            backgroundColor: lineStatus['ARG-PE-02'][1].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['ARG-PE-02'][1])}</span>
        </div>
        <div
          className="line"
          style={{
            width: '3px',
            height: '161px',
            top: '92px',
            right: '80px',
            backgroundColor: lineStatus['ARG-PE-02'][1].status,
          }}
        >
          <span className="tooltiptext">{getTooltip(lineStatus['ARG-PE-02'][1])}</span>
        </div>
      </div>
    </div>
  );
}

export default Network2;
