import React from 'react';
import './StatusCard.css';
import  {color_status} from "../../utils/dictionaryStates"

function StatusCard({ title , status}) {
  return (
    <div className="status-card" style={{background: color_status[status]}}>
        {title}
    </div>
  );
}

export default StatusCard;
