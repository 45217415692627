import AxiosFactory from './Axios';
const pako = require('pako');

function recoverOriginalContent(compressedBase64) {
  try {
    const compressedData = atob(compressedBase64);
    const compressedArray = new Uint8Array(compressedData.length);
    for (let i = 0; i < compressedData.length; i++) {
      compressedArray[i] = compressedData.charCodeAt(i);
    }
    const pako = require('pako');
    const decompressedArray = pako.inflate(compressedArray);
    const originalString = new TextDecoder().decode(decompressedArray);
    return originalString;
  } catch (error) {
    console.error('Error recovering original content:', error);
    return null;
  }
}

export async function getHosts(baseURL, site) {
  let response;
  const checkmkApi = AxiosFactory(baseURL);
  response = await checkmkApi.get(`cenr/${site}:host`);
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getServices(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  const response = await checkmkApi.get(`cenr/${site}:service`);
  return response.data;
}

export async function getServicesCloud(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  const response = await checkmkApi.get(`cenr/${site}:service_cloud`);
  return response.data;
}

export async function getLinks(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  const response = await checkmkApi.get(`cenr/${site}:link`);
  return response.data;
}

export async function getServicesCpuMemory(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  const response = await checkmkApi.get(`cenr/${site}:service_cpu_memory`);
  return response.data;
}

export async function getServicesLinks(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  const response = await checkmkApi.get(`cenr/${site}:service_links`);
  return response.data;
}
