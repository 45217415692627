// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.percentage-graphic {
    height: 100%;
    display: flex;
}
  
.percentage-graphic text{
    font-family: Sansation;
    font-size: 8px;
    font-weight: 700;
    line-height: 8.95px;
    letter-spacing: 0.04em;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    fill: #7988A7;
}
  
.percentage-graphic .graphic-label{
    width: 35px;
}
.percentage-graphic .graphic-percent{
    margin-left: 5px;
}
  
.percentage-graphic .graphic-gray {
    fill: #AAB8D6;
}
  
.percentage-graphic .graphic-white {
    fill: #E2E8F5;
}`, "",{"version":3,"sources":["webpack://./components/PercentageBar/PercentageBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;IACjB,kCAAkC;IAClC,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".percentage-graphic {\n    height: 100%;\n    display: flex;\n}\n  \n.percentage-graphic text{\n    font-family: Sansation;\n    font-size: 8px;\n    font-weight: 700;\n    line-height: 8.95px;\n    letter-spacing: 0.04em;\n    text-align: right;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    fill: #7988A7;\n}\n  \n.percentage-graphic .graphic-label{\n    width: 35px;\n}\n.percentage-graphic .graphic-percent{\n    margin-left: 5px;\n}\n  \n.percentage-graphic .graphic-gray {\n    fill: #AAB8D6;\n}\n  \n.percentage-graphic .graphic-white {\n    fill: #E2E8F5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
