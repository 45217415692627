import React, { useState } from 'react';

import DonloadIcon from '../../img/download.svg';

function CSVHocComponent({ children, personalizedClass, data }) {
  // console.log(data);

  const [showOptions, setShowOptions] = useState(false);

  const transformDataToCSV = (data) => {
    if (Array.isArray(data)) {
      const rows = [];

      const flattenObject = (obj, parentKey = '') => {
        return Object.keys(obj).reduce((acc, key) => {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach((item, index) => {
                Object.assign(acc, flattenObject(item, `${newKey}[${index}]`));
              });
            } else {
              Object.assign(acc, flattenObject(obj[key], newKey));
            }
          } else {
            acc[newKey] = obj[key];
          }
          return acc;
        }, {});
      };

      data.forEach((item) => {
        rows.push(flattenObject(item));
      });

      const headers = Array.from(new Set(rows.flatMap((row) => Object.keys(row))));
      const csvHeader = headers.join(',');

      const csvBody = rows
        .map((row) =>
          headers
            .map((header) => {
              const value = row[header];
              return value !== undefined && value !== null ? JSON.stringify(value) : '';
            })
            .join(',')
        )
        .join('\n');

      return `${csvHeader}\n${csvBody}`;
    } else if (typeof data === 'object' && data !== null) {
      const rows = [];
      const flattenObject = (obj, parentKey = '') => {
        return Object.keys(obj).reduce((acc, key) => {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
            Object.assign(acc, flattenObject(obj[key], newKey));
          } else {
            acc[newKey] = obj[key];
          }
          return acc;
        }, {});
      };

      for (const provider in data) {
        if (Array.isArray(data[provider])) {
          data[provider].forEach((item) => {
            rows.push(flattenObject({ provider, ...item }));
          });
        }
      }

      const headers = Array.from(new Set(rows.flatMap((row) => Object.keys(row))));
      const csvHeader = headers.join(',');
      const csvBody = rows
        .map((row) =>
          headers
            .map((header) => {
              const value = row[header];
              return value !== undefined && value !== null ? JSON.stringify(value) : '';
            })
            .join(',')
        )
        .join('\n');

      return `${csvHeader}\n${csvBody}`;
    }
  };
  // const transformDataToCSV = (data) => {
  //   const rows = [];

  //   const flattenObject = (obj, parentKey = '') => {
  //     return Object.keys(obj).reduce((acc, key) => {
  //       const newKey = parentKey ? `${parentKey}.${key}` : key;
  //       if (typeof obj[key] === 'object' && obj[key] !== null) {
  //         if (Array.isArray(obj[key])) {
  //           // Si es un arreglo, aplanamos cada elemento
  //           obj[key].forEach((item, index) => {
  //             Object.assign(acc, flattenObject(item, `${newKey}[${index}]`));
  //           });
  //         } else {
  //           // Si es un objeto, seguimos aplanando
  //           Object.assign(acc, flattenObject(obj[key], newKey));
  //         }
  //       } else {
  //         acc[newKey] = obj[key];
  //       }
  //       return acc;
  //     }, {});
  //   };

  //   // Convertimos cada elemento del array principal en una fila
  //   data.forEach((item) => {
  //     rows.push(flattenObject(item));
  //   });

  //   // Obtenemos los encabezados únicos
  //   const headers = Array.from(new Set(rows.flatMap((row) => Object.keys(row))));
  //   const csvHeader = headers.join(',');

  //   // Generamos las filas del CSV
  //   const csvBody = rows
  //     .map((row) =>
  //       headers
  //         .map((header) => {
  //           const value = row[header];
  //           return value !== undefined && value !== null ? JSON.stringify(value) : '';
  //         })
  //         .join(',')
  //     )
  //     .join('\n');

  //   return `${csvHeader}\n${csvBody}`;
  // };

  const handleDownload = () => {
    const csvContent = transformDataToCSV(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <section
      className={personalizedClass}
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
      style={{ position: 'relative' }}
    >
      {showOptions && (
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            borderRadius: '5px',
            padding: '5px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div onClick={handleDownload} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
            <img src={DonloadIcon} style={{ width: '20px' }} />
          </div>
        </div>
      )}
      {children}
    </section>
  );
}

export default CSVHocComponent;
