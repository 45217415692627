import React from 'react';

import './DatacenterTableItem.css';

function DatacenterTableItem({ name, status, ip, saturation, crc, prov }) {
  return (
    <section className="datacenter-table-item-cmpt">
      <div className="name">{name}</div>
      <div className="prov">{prov}</div>
      <div className="status" style={{ backgroundColor: status === 'Up' ? '#10D884' : '#ED4C5C' }}>
        {status}
      </div>
      <div className="ip">{ip}</div>
      <div className="saturation">{saturation}</div>
      <div className="crc">{crc}</div>
    </section>
  );
}

export default DatacenterTableItem;
