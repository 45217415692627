import React, {useState, useEffect} from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import { icons } from '../../utils/dictionaryIcons';
import Heatmap from '../../components/Heatmap/Heatmap'
import './Tanzu.css';



function Tanzu({ selectMainDashboard, tanzu}) {

    return (
        <section className="tanzu-view">
            <Toolbar title={`Tanzu`} />
            <div className='dynatrace-section'>
                <div id="tooltip" className="tooltip"></div>
                {<Heatmap tanzu={tanzu} />}
            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default Tanzu;