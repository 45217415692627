import React from 'react';
import Logo from '../../img/logo.svg';
import './Toolbar.css';

function Toolbar({ title }) {
  return (
    <section className="toolbar">
      <img src={Logo} />
      <div className="title">{title}</div>
    </section>
  );
}

export default Toolbar;
