import React from 'react';
import './K8sPod.css';
import  {icons} from "../../utils/dictionaryIcons"
import  {color_status} from "../../utils/dictionaryStates"

function K8Pod({status, icon, title, font_color, style = undefined, content= undefined}) {
  return (
    <>
        <div className="pod" style={style}>
            <img src={icons["Pod"]} style={{marginRight: "10px"}}/>
            <img src={icons[icon]}/>
            <div className='pod-title' style={{color: font_color}}>{title}</div>
            <div className='pod-status' style={{background: color_status[status]}}></div>
            {content}
        </div>
    </>
  );
}

export default K8Pod;
